import { useCallback } from 'react';

import { FCX, ID } from '@core/models';
import Chip from '@components/utility/Chips/Chip';
import { GradientColorsEnum } from '@core/enums/gradientEnum';
import { CriterionEnum, CriterionParam } from '@models/Criterion';

const SearchCriterionChipList: FCX<{
    criterionParams: CriterionParam[];
    criterionEnum: CriterionEnum;
    criterionColor: GradientColorsEnum;
    onDelete: (criterionKey: CriterionEnum, criterionParamId: ID) => void;
}> = ({
    criterionParams,
    criterionEnum,
    criterionColor,
    onDelete
}) => {
    const onDeleteCriterionChip = useCallback(
        (criterionParam: CriterionParam, criterionEnum: CriterionEnum) => {
            if (criterionParam.isPermanent) return undefined;

            return () => onDelete(criterionEnum, criterionParam.value);
        },
        [onDelete],
    );

    return criterionParams.map((criterionParam) => {
        const chipLabel = (criterionParam?.additionalData?.condensedLabel as string) ?? criterionParam.name;
        return (
            <Chip
                key={criterionParam.value}
                name={chipLabel}
                id={criterionParam.value as string}
                description={criterionParam.description}
                baseColor={criterionColor}
                onChipDelete={onDeleteCriterionChip(criterionParam, criterionEnum)}
            />
        );
    });
};

export default SearchCriterionChipList;
