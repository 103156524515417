import { useCallback, useEffect, useState } from 'react';

import { Country } from '@core/enums/flagsEnum';
import { useAdvancedSearch } from '@core/contexts';

export function useSelectCountryModal() {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [countriesList, setCountriesList] = useState<Country[]>([]);
    const [selectedCountry, setSelectedCountry] = useState<Country>();

    const {
        countriesList: countries,
        allowedCountries,
        defaultOrFirstAllowedCountry
    } = useAdvancedSearch();

    useEffect(() => {
        if (countries) {
            const mappedCountries: Country[] = countries
                .filter((country) => allowedCountries.includes(country.abbreviation ?? ''))
                .map((country) => {
                    return {
                        name: country.name,
                        code: country.abbreviation,
                    } as Country;
                });

            const defaultCountry = mappedCountries.find(
                (country) => country.code === defaultOrFirstAllowedCountry?.abbreviation,
            );

            setCountriesList(mappedCountries);
            setSelectedCountry(defaultCountry);
        }
    }, [allowedCountries, countries, defaultOrFirstAllowedCountry]);

    const handleCountryChange = useCallback(
        (country: Country) => {
            setSelectedCountry(country);
            setIsOpen(false);
        },
        [setIsOpen],
    );

    return {
        isModalOpen: isOpen,
        setIsModalOpen: setIsOpen,
        countriesList,
        selectedCountry,
        setSelectedCountry,
        handleCountryChange,
    };
}
