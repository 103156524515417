import { FC, Fragment, useEffect } from 'react';

import { CuisinesResponse } from '@core/api/useCuisines';
import { CustomerMetadataResponse } from '@core/api/useCustomerMetadata';
import { FeatureFlags, useFeatureFlag } from '@core/hooks/useFeatureFlag';
import useListenToActiveMenuItem from '@pages/OperatorTargetingCriteria/hooks/useListenToActiveMenuItem';

import { RangesData } from './GeneralCriteria/General.helpers';
import ClientFiltersCriteria from './ClientFiltersCriteria/ClientFiltersCriteria';
import { GeneralCriteria, RestaurantsAndBarsCriteria } from '../../helpers/Criteria.helpers';
import LodgingAndRecreationCriteria from './LodgingAndRecreation/LodgingAndRecreationCriteria';

const SegmentsCriteriaContent: FC<{
    cuisines: CuisinesResponse | null;
    customerMetadata: CustomerMetadataResponse | null;
    onUpdateActiveNavigationSubItem: (id: string) => void;
    ranges: RangesData | null;
}> = ({
    cuisines,
    customerMetadata,
    onUpdateActiveNavigationSubItem,
    ranges
}) => {
    const isCustomerStatusSearchEnabled = useFeatureFlag(FeatureFlags.CustomerStatusSearch);

    const { observe } = useListenToActiveMenuItem(
        onUpdateActiveNavigationSubItem,
        '[data-observable]',
        '#observer-root',
    );

    useEffect(() => {
        if (ranges !== null) {
            setTimeout(() => {
                observe();
            }, 1500);
        }
    }, [observe, ranges]);

    if (!ranges) {
        return null;
    }

    return (
        <Fragment>
            {isCustomerStatusSearchEnabled && !!customerMetadata?.values?.length && (
                <ClientFiltersCriteria customerMetadata={customerMetadata} />
            )}
            <GeneralCriteria ranges={ranges} />
            <RestaurantsAndBarsCriteria ranges={ranges} cuisines={cuisines} />
            <LodgingAndRecreationCriteria ranges={ranges} />
        </Fragment>
    );
};
export default SegmentsCriteriaContent;
