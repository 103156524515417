import { useMemo } from 'react';

import { paths } from '@core/api/api';
import useFetch from '@hooks/useFetch';
import { usePermissions } from '@core/hooks/usePermissions';
import { MethodsEnum, OperationsEnum, Content, ContentType } from '@core/enums/api';

import { useAppContext } from '../../App.context';

const GET_COUNTRIES_URL = '/countries';

type GetCountriesEndpoint = paths[typeof GET_COUNTRIES_URL][MethodsEnum.GET];
type CountriesParams = GetCountriesEndpoint[OperationsEnum.PARAMETERS]['query'];
export type AllCountriesResponse = GetCountriesEndpoint[OperationsEnum.RESPONSES]['200'][Content][ContentType];

export default function useCountries(locale?: string) {
    const {
        getAllowedItemsFromArray,
        hasPermissionToValue
    } = usePermissions();

    const {
        userInfo: { defaultCountry },
    } = useAppContext();

    const {
        data,
        loading,
        error,
        doFetch
    } = useFetch<AllCountriesResponse, CountriesParams>({
        url: GET_COUNTRIES_URL,
        method: 'GET',
        params: { locale },
    });

    const countryInfo = useMemo(() => {
        const sortedCountries = sortCountries(data?.countries);
        const countryAbbreviations =
            data?.countries?.map(({ abbreviation }) => abbreviation ?? '').filter(Boolean) ?? [];

        const allowedCountries = getAllowedItemsFromArray('countries', countryAbbreviations);

        if (defaultCountry && hasPermissionToValue('countries', defaultCountry)) {
            return {
                allowedCountries,
                sortedCountries,
                defaultOrFirstAllowedCountry: sortedCountries?.find(
                    ({ abbreviation }) => abbreviation === defaultCountry,
                ),
            };
        }

        const defaultOrFirstAllowedCountry = sortedCountries?.find(({ abbreviation }) =>
            allowedCountries.includes(abbreviation ?? ''),
        );

        return {
            allowedCountries,
            sortedCountries,
            defaultOrFirstAllowedCountry,
        };
    }, [data?.countries, defaultCountry, getAllowedItemsFromArray, hasPermissionToValue]);

    return {
        data: countryInfo.sortedCountries,
        allowedCountries: countryInfo.allowedCountries,
        defaultOrFirstAllowedCountry: countryInfo.defaultOrFirstAllowedCountry,
        loading,
        error,
        doFetch,
    };
}

function sortCountries(countries: AllCountriesResponse['countries']): AllCountriesResponse['countries'] {
    if (!countries) return undefined;

    const newCountries = [...countries];

    return newCountries.sort((a, b) => {
        if (a?.name && b?.name) {
            return a.name > b.name ? 1 : -1;
        }
        return 0;
    });
}
