import { useCallback, useState } from 'react';

import { toggleObjectInArrayByKey } from '@core/helpers/helpers';
import { SelectOption } from '@components/utility/Inputs/Inputs.helpers';
import { DropdownOption } from '@components/utility/Inputs/Dropdowns/Dropdowns.helpers';

type OnSelectCallback<T = unknown> = (cb: T) => void;

function useInput() {
    const [value, setValue] = useState<string | undefined>(undefined);

    return {
        value,
        onChange: setValue,
    };
}

function useSearchInput() {
    const [value, setValue] = useState<SelectOption | null>(null);

    const onChange: (newValue: SelectOption | null) => void = (newValue) => {
        setValue(newValue);
    };

    return {
        value,
        onChange,
    };
}

function useDropdownMultiSelect(handleOnCallback?: OnSelectCallback<DropdownOption>) {
    const [selectedOptions, setSelectedOptions] = useState<DropdownOption[] | undefined>(undefined);

    const onOptionSelected = useCallback(
        (newSelectedOption: DropdownOption) => {
            setSelectedOptions((prev) => {
                return toggleObjectInArrayByKey<DropdownOption>(prev, newSelectedOption, 'id');
            });

            if (handleOnCallback) {
                handleOnCallback(newSelectedOption);
            }
        },
        [handleOnCallback],
    );

    const onMultipleSelectedOptions = useCallback((newSelectedOptions: DropdownOption[]) => {
        setSelectedOptions(newSelectedOptions);
    }, []);

    return {
        selectedOptions,
        onOptionSelected,
        onMultipleSelectedOptions,
    };
}

function useDropdownInput(handleOnCallback?: OnSelectCallback<DropdownOption>) {
    const [selectedOption, setSelectedOption] = useState<DropdownOption | undefined>(undefined);

    const onOptionSelected = useCallback(
        (newSelectedOption: DropdownOption) => {
            if (selectedOption?.id === newSelectedOption.id) {
                return;
            }

            setSelectedOption(newSelectedOption);

            if (handleOnCallback) {
                handleOnCallback(newSelectedOption);
            }
        },
        [handleOnCallback, selectedOption?.id],
    );

    return { selectedOption, onOptionSelected };
}

function useSelectInput() {
    const [value, setValue] = useState<string | null>(null);

    const onChange = useCallback((newValue: string | null) => {
        setValue(newValue);
    }, []);

    return {
        value,
        onChange,
    };
}

function useTagsInput() {
    const [tags, setTags] = useState<string[]>([]);

    const onAddTag = useCallback((newTag: string, cb?: OnSelectCallback<string>) => {
        setTags((prev) => {
            if (prev.includes(newTag)) return prev;

            return [...prev, newTag];
        });
        cb?.(newTag);
    }, []);

    const onDeleteTag = useCallback((tag: string, cb?: OnSelectCallback<string>) => {
        setTags((prev) => prev.filter((t) => t !== tag));
        cb?.(tag);
    }, []);

    return {
        tags,
        onAddTag,
        onDeleteTag,
    };
}

export { useInput, useSearchInput, useSelectInput, useDropdownInput, useDropdownMultiSelect, useTagsInput };
