import React, { createContext, useCallback, useEffect, useState } from 'react';

import { Dictionary, PhrasesEnum, LocalesEnum } from '@enums/localeEnum';

import { useAppContext } from '../../App.context';

type LocaleProviderProps = { children: React.ReactNode };

export type TranslateFunc = (key: PhrasesEnum) => string;

export const LocaleContext = createContext<{
    t: TranslateFunc;
    locale: LocalesEnum;
}>({
    t: (key) => `${key}`,
    locale: LocalesEnum.En,
});

function LocaleProvider({ children }: LocaleProviderProps) {
    const {
        userInfo: { locale: userLocale },
    } = useAppContext();

    const [locale, setLocale] = useState<LocalesEnum>(LocalesEnum.En);

    const t: TranslateFunc = useCallback((key) => Dictionary[key][locale], [locale]);

    /*
     DEV Testing: To switch the language press: alt + cmd/windows + click
    */
    useEffect(() => {
        const handleDocumentClick = (event: MouseEvent) => {
            if (event.altKey && event.metaKey) {
                setLocale(locale === LocalesEnum.En ? LocalesEnum.Fr : LocalesEnum.En);
            }
        };
        document.addEventListener('click', handleDocumentClick);

        return () => {
            document.removeEventListener('click', handleDocumentClick);
        };
    }, [locale]);

    useEffect(() => {
        if (userLocale) {
            setLocale(userLocale);
        }
    }, [userLocale]);

    const value = { t, locale };

    return <LocaleContext.Provider value={value}>{children}</LocaleContext.Provider>;
}

function useLocale() {
    return React.useContext(LocaleContext);
}

export { LocaleProvider, useLocale };
