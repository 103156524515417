import { GradientColorsEnum } from '@core/enums/gradientEnum';
import {
    FilterTypeEnum,
    OperatorProfilePhrasesEnum,
    OperatorTargetingPhrasesEnum,
    PhrasesEnum,
    TerritoriesPhrasesEnum,
} from '@core/enums/localeEnum';
import { CriteriaData, CriterionEnum, CriterionParamFilter } from '@core/models/Criterion';

export const LIST_ONLY_FILTERS: string[] = [
    CriterionParamFilter.AdministrativeArea1,
    CriterionParamFilter.AdministrativeArea2,
    CriterionParamFilter.Segment,
    CriterionParamFilter.Cuisine,
    CriterionParamFilter.GeoLocation,
];

export const MAP_FILTER_KEY_TO_TRANSLATION: Record<string, PhrasesEnum> = {
    [CriterionParamFilter.PostalCode]: OperatorTargetingPhrasesEnum.PostalCodes,
    [CriterionParamFilter.ChainSizeRange]: FilterTypeEnum.ChainIndependent,
    [CriterionParamFilter.YearsInBusinessRange]: OperatorProfilePhrasesEnum.YearsinBusiness,
    [CriterionParamFilter.EstMealsPerDayRange]: OperatorProfilePhrasesEnum.EstMealsPerDay,
    [CriterionParamFilter.AnnualSalesRange]: OperatorProfilePhrasesEnum.EstimatedAnnualSales,
    [CriterionParamFilter.ConfidenceLevel]: OperatorProfilePhrasesEnum.ConfidenceLevel,
    [CriterionParamFilter.PotentialPurchaseTotal]: FilterTypeEnum.TotalPurchasePotential,
    [CriterionParamFilter.NumberOfRoomsRange]: FilterTypeEnum.NumberofRooms,
    [CriterionParamFilter.HotelStarLevel]: OperatorProfilePhrasesEnum.HotelClass,
    [CriterionParamFilter.FoodTags]: OperatorProfilePhrasesEnum.FoodTags,
    [CriterionParamFilter.PlaceTags]: OperatorProfilePhrasesEnum.PlaceTags,
    [CriterionParamFilter.NumberOfEmployeesRangeNonCommercial]: OperatorTargetingPhrasesEnum.NonCommercialEmployees,
    [CriterionParamFilter.NumberOfEmployeesRangeCommercial]: OperatorTargetingPhrasesEnum.CommercialEmployees,
    [CriterionParamFilter.AverageCheckRange]: OperatorTargetingPhrasesEnum.AverageCheck,
    [CriterionParamFilter.CustomerStatus]: FilterTypeEnum.ClientStatus,
    [CriterionParamFilter.Territories]: TerritoriesPhrasesEnum.Territories,
    [CriterionParamFilter.MenuIncludes]: FilterTypeEnum.MenuIncludes,
    [CriterionParamFilter.MenuExcludes]: FilterTypeEnum.MenuExcludes,
};

export const MOCKED_CRITERIA: CriteriaData = {
    [CriterionEnum.Location]: {
        id: 1,
        name: FilterTypeEnum.Location,
        baseColor: GradientColorsEnum.GREEN,
        CriterionParams: [
            {
                name: 'United States',
                value: 'US',
                filterKey: CriterionParamFilter.Country,
            },
        ],
    },
    [CriterionEnum.Segments]: {
        id: 2,
        name: FilterTypeEnum.Segments,
        baseColor: GradientColorsEnum.BLUE,
        CriterionParams: [],
    },
    [CriterionEnum.CuisineTypes]: {
        id: 3,
        name: FilterTypeEnum.SegmentsCriteria,
        baseColor: GradientColorsEnum.PURPLE,
        CriterionParams: [],
    },
    [CriterionEnum.Tags]: {
        id: 3,
        name: FilterTypeEnum.Tags,
        baseColor: GradientColorsEnum.RED,
        CriterionParams: [],
    },
    [CriterionEnum.General]: {
        id: 4,
        name: FilterTypeEnum.General,
        baseColor: GradientColorsEnum.ORANGE,
        CriterionParams: [],
    },
    [CriterionEnum.LodgingRecreation]: {
        id: 4,
        name: FilterTypeEnum.LodgingAndRecreation,
        baseColor: GradientColorsEnum.ORANGE,
        CriterionParams: [],
    },
    [CriterionEnum.ClientFilters]: {
        id: 4,
        name: FilterTypeEnum.ClientFilters,
        baseColor: GradientColorsEnum.ORANGE,
        CriterionParams: [],
    },
};
