import { ID } from '@core/models';
import useFetch from '@hooks/useFetch';
import { LocalesEnum } from '@core/enums/localeEnum';

const GET_TAGS_URL = '/operators/tags';

export enum TagsCategoryEnum {
    FOOD = 'FOOD',
    PLACES = 'PLACES',
}

export type TagsEndpointParams = {
    country: ID;
    category?: TagsCategoryEnum;
    locale?: LocalesEnum;
};

export type TagData = {
    code: string;
    description: string;
};

export type TagsResponseContent = {
    category: string | TagsCategoryEnum;
    code: string;
    children: TagData[];
    description: string;
};

export type TagsResponse = {
    locale: string;
    country: string;
    tags: TagsResponseContent[];
};

export default function useTags(params?: TagsEndpointParams) {
    const {
        data,
        loading,
        error,
        doFetch
    } = useFetch<TagsResponse, TagsEndpointParams>({
        url: GET_TAGS_URL,
        lazy: true,
        params,
    });

    return { data: data, loading, error, doFetch } as const;
}
