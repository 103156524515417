import { useCallback, useEffect, useState } from 'react';

import { FCX } from '@core/models';
import { useLocale } from '@core/utils/locale';
import TagsInput from '@components/utility/Inputs/TagsInput/TagsInput';
import { useAdvancedSearch } from '@core/contexts/AdvancedSearch.context';
import { OperatorTargetingPhrasesEnum, PhrasesEnum } from '@core/enums/localeEnum';
import { CriterionEnum, CriterionParam, CriterionParamFilter } from '@core/models/Criterion';
import { INCLUDE_WITHOUT_MENU_ID } from './OnTheMenuIncludesCheckbox';

const OnTheMenuCriteria: FCX<{
    criterionFilterKey: CriterionParamFilter;
    title: PhrasesEnum;
}> = ({
    criterionFilterKey,
    title
}) => {
    const [tags, setTags] = useState<string[]>([]);

    const { t } = useLocale();

    const {
        onAddCriterionParam,
        getCriterionByFilterKey,
        onDeleteCriterionParam
    } = useAdvancedSearch();

    useEffect(() => {
        const menuParams = getCriterionByFilterKey(CriterionEnum.CuisineTypes, criterionFilterKey);
        const activeKeywords = (menuParams?.flatMap((keyword) => keyword?.value?.toString()) ?? []).filter(
            (keyword) => keyword !== INCLUDE_WITHOUT_MENU_ID,
        );

        setTags(activeKeywords);
    }, [criterionFilterKey, getCriterionByFilterKey, setTags]);

    const onDeleteTagCriterionParam = useCallback(
        (tagId: string) => onDeleteCriterionParam(CriterionEnum.CuisineTypes, tagId),
        [onDeleteCriterionParam],
    );

    const onAddTagCriterionParam = useCallback(
        (tag: string) => {
            const tagParam: CriterionParam = {
                filterKey: criterionFilterKey,
                name: tag,
                value: tag,
            };

            onAddCriterionParam(CriterionEnum.CuisineTypes, tagParam);
        },
        [criterionFilterKey, onAddCriterionParam],
    );

    return (
        <TagsInput
            tags={tags}
            onAddTag={onAddTagCriterionParam}
            onDeleteTag={onDeleteTagCriterionParam}
            inputLabel={t(title)}
            placeholder={t(OperatorTargetingPhrasesEnum.MenuSearchboxPlaceholder)}
            testId={title}
        />
    );
};

export default OnTheMenuCriteria;
