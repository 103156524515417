import { useCallback, useEffect, useState } from 'react';

import { ID } from '@core/models';
import { FilterTypeEnum } from '@enums/localeEnum';
import { GradientColorsEnum } from '@core/enums/gradientEnum';
import { SaveSearchResponse } from '@core/api/SaveSearch/useSaveSearch';
import { CriterionEnum, parseCriteriaData } from '@core/models/Criterion';
import useGetAllSaveSearch from '@core/api/SaveSearch/useGetAllSaveSearch';
import { CriteriaData, CriterionParam, CriterionParamFilter, CriterionParamsManager } from '@models/Criterion';

export const CRITERIA: CriteriaData = {
    [CriterionEnum.Location]: {
        id: 1,
        name: FilterTypeEnum.Location,
        baseColor: GradientColorsEnum.GREEN,
        CriterionParams: [],
    },
    [CriterionEnum.Segments]: {
        id: 2,
        name: FilterTypeEnum.Segments,
        baseColor: GradientColorsEnum.BLUE,
        CriterionParams: [],
    },
    [CriterionEnum.Tags]: {
        id: 3,
        name: FilterTypeEnum.Tags,
        baseColor: GradientColorsEnum.ORANGE,
        CriterionParams: [],
    },
    [CriterionEnum.General]: {
        id: 4,
        name: FilterTypeEnum.General,
        baseColor: GradientColorsEnum.PURPLE,
        CriterionParams: [],
    },
    [CriterionEnum.CuisineTypes]: {
        id: 5,
        name: FilterTypeEnum.CuisineTypes,
        baseColor: GradientColorsEnum.PURPLE,
        CriterionParams: [],
    },
    [CriterionEnum.LodgingRecreation]: {
        id: 6,
        name: FilterTypeEnum.LodgingAndRecreation,
        baseColor: GradientColorsEnum.PURPLE,
        CriterionParams: [],
    },
    [CriterionEnum.ClientFilters]: {
        id: 7,
        name: FilterTypeEnum.ClientFilters,
        baseColor: GradientColorsEnum.PURPLE,
        CriterionParams: [],
    },
};

function useAdvancedSearchCriteria() {
    const [criteria, setCriteria] = useState<CriteriaData>(CRITERIA);
    const [activeCriteria, setActiveCriteria] = useState<CriteriaData>();
    const [isDirty, setIsDirty] = useState<boolean>(false);
    const [activeSaveSearch, setActiveSaveSearch] = useState<SaveSearchResponse | undefined>();

    useEffect(() => {
        const filteredCriteriaData = parseCriteriaData(criteria);

        setActiveCriteria(filteredCriteriaData);
    }, [criteria]);

    const {
        loading: savedSearchLoading,
        data: savedSearchData,
        doFetch: savedSearchDoFetch
    } = useGetAllSaveSearch();

    const updateActiveSavedSearch = useCallback((savedSearchObj?: SaveSearchResponse) => {
        setActiveSaveSearch(savedSearchObj);
    }, []);

    const updateIsDirty = useCallback((isDirty: boolean) => {
        setIsDirty(isDirty);
    }, []);

    const onResetCriteria = useCallback(() => {
        setActiveSaveSearch(undefined);
        setIsDirty(false);
        setCriteria(() => CRITERIA);
    }, []);

    const onMergeCriteria = useCallback((criteriaToMerge: Partial<CriteriaData>) => {
        setIsDirty(false);
        setCriteria(() => ({ ...CRITERIA, ...criteriaToMerge }));
    }, []);

    const onResetCriterionParamsExceptByFilterKey = useCallback(
        (criterionKey: CriterionEnum, filterKey: CriterionParamFilter) => {
            setCriteria((prevCriteria) => {
                const currentCriterion = prevCriteria[criterionKey];

                return {
                    ...CRITERIA,
                    [criterionKey]: {
                        ...currentCriterion,
                        CriterionParams: currentCriterion.CriterionParams.filter((criterionParam) => {
                            return criterionParam.filterKey === filterKey;
                        }),
                    },
                };
            });
        },
        [],
    );

    const onResetCriterionParamsByKey = useCallback((criterionKey: CriterionEnum) => {
        setCriteria((prevCriteria) => {
            const currentCriterion = prevCriteria[criterionKey];

            return {
                ...prevCriteria,
                [criterionKey]: {
                    ...currentCriterion,
                    CriterionParams: [],
                },
            };
        });
    }, []);

    const onResetCriteriaByFilterKey = useCallback((criterionKey: CriterionEnum, filterKey: CriterionParamFilter) => {
        setCriteria((prevCriteria) => {
            const currentCriterion = prevCriteria[criterionKey];

            return {
                ...prevCriteria,
                [criterionKey]: {
                    ...currentCriterion,
                    CriterionParams: currentCriterion.CriterionParams.filter((criterionParam) => {
                        return criterionParam.filterKey !== filterKey;
                    }),
                },
            };
        });
    }, []);

    const onDeleteCriterionParam = useCallback((criterionKey: CriterionEnum, criterionParamId: ID) => {
        function filterCriterionParams(criterionParams: CriterionParam[]) {
            return criterionParams.filter((criterionParam) => {
                return criterionParam.value !== criterionParamId;
            });
        }
        setIsDirty(true);
        setCriteria((prevCriteria) => {
            const currentCriterion = prevCriteria[criterionKey];

            return {
                ...prevCriteria,
                [criterionKey]: {
                    ...currentCriterion,
                    CriterionParams: filterCriterionParams(currentCriterion.CriterionParams),
                },
            };
        });
    }, []);

    const onAddCriterionParam = useCallback((criterionKey: CriterionEnum, criterionParam: CriterionParam) => {
        setIsDirty(true);
        setCriteria((prevCriteria) => {
            const criterion = prevCriteria[criterionKey];
            return {
                ...prevCriteria,
                [criterionKey]: {
                    ...criterion,
                    CriterionParams: CriterionParamsManager.updateCriterionParams(
                        criterion.CriterionParams,
                        criterionParam,
                    ),
                },
            };
        });
    }, []);

    const getCriterionByFilterKey = useCallback(
        (criterionKey: CriterionEnum, filterKey: CriterionParamFilter) => {
            const criterionParams = criteria[criterionKey].CriterionParams.filter((criterionParam) => {
                return criterionParam.filterKey === filterKey;
            });

            return criterionParams.length > 0 ? criterionParams : undefined;
        },
        [criteria],
    );

    return {
        criteria: activeCriteria,
        onDeleteCriterionParam,
        onAddCriterionParam,
        getCriterionByFilterKey,
        onResetCriteria,
        onMergeCriteria,
        // TODO: Refactor this to a saved search context since it's not related to criteria.
        savedSearch: {
            updateActiveSavedSearch,
            isDirty,
            activeSaveSearch,
            savedSearchData,
            savedSearchDoFetch,
            savedSearchLoading,
            updateIsDirty,
        },
        onResetCriterionParamsByKey,
        onResetCriteriaByFilterKey,
        onResetCriterionParamsExceptByFilterKey,
    };
}

export default useAdvancedSearchCriteria;
