import { useCallback } from 'react';

import { useLocale } from '@core/utils/locale';
import { generateAnchorId } from '@core/utils/string';
import { CountryCodesEnum } from '@core/enums/flagsEnum';
import { TabsContent } from '@components/utility/Tabs/Tabs';
import { OperatorProfilePhrasesEnum } from '@core/enums/localeEnum';
import { MenuResponse, MenuItem, MenuHeaderTypes, MenuHeader } from '@core/api/useGlobalMenu';

import MenuSection from './MenuSection';

const useGetMenuTabs = (menuData: MenuResponse, countryCode: CountryCodesEnum): TabsContent[] => {
    const { t } = useLocale();

    const onTabClick = useCallback((anchorId: string) => {
        return () => {
            const element = document.getElementById(anchorId);
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        };
    }, []);

    if (!menuData?.[0]) return [];

    const menuSections = menuData.flatMap(
        (data) => data.headers?.filter((master) => master.type === MenuHeaderTypes.MASTER && master.value) || [],
    );

    const otherSectionItems = extractMenuHeaderItems(
        menuData.flatMap(
            (data) => data.headers?.filter((header) => header.type === MenuHeaderTypes.MASTER && !header.value) || [],
        ),
    );

    const tabsContent = [
        ...menuSections,
        ...(otherSectionItems.length > 0
            ? [
                {
                    value: t(OperatorProfilePhrasesEnum.Others),
                    headers: [{ type: MenuHeaderTypes.MASTER, value: '', items: otherSectionItems }],
                },
            ]
            : []),
    ].map((masterHeader) => {
        const anchorId = generateAnchorId(masterHeader.value, 'menu-tab-');
        const items = extractMenuHeaderItems(masterHeader.headers || []);

        return {
            label: <span data-testid={`heading${masterHeader.value}`}>{masterHeader.value}</span>,
            title: masterHeader.value,
            content: (
                <MenuSection
                    countryCode={countryCode}
                    title={masterHeader.value}
                    items={items}
                    sectionAnchorId={anchorId}
                />
            ),
            onClick: onTabClick(anchorId),
        };
    });

    return tabsContent;
};

export function extractMenuHeaderItems(headers: MenuHeader[]): MenuItem[] {
    let items: MenuItem[] = [];
    headers.forEach((header) => {
        if (header.items) {
            items = items.concat(header.items);
        }
        if (header.headers) {
            items = items.concat(extractMenuHeaderItems(header.headers));
        }
    });
    return items;
}

export default useGetMenuTabs;
