import { paths } from '@core/api/api';
import useFetch from '@core/hooks/useFetch';
import { MethodsEnum, OperationsEnum, Content, ContentType } from '@core/enums/api';

const GET_TERRITORIES = '/territories';

type GetTerritoryEndpoint = paths[typeof GET_TERRITORIES][MethodsEnum.GET];
type TerritoryParams = GetTerritoryEndpoint[OperationsEnum.PARAMETERS]['query'];
type TerritoryResponse = GetTerritoryEndpoint[OperationsEnum.RESPONSES]['200'][Content][ContentType];
export type TerritoryDTO = NonNullable<TerritoryResponse['territories']>[number];
export type TerritoryDetails = TerritoryDTO['details'];

type UseTerritoriesConfig = {
    lazy?: boolean;
    params?: TerritoryParams;
};

export default function useTerritories(config?: UseTerritoriesConfig) {
    const {
        data,
        loading,
        error,
        doFetch
    } = useFetch<TerritoryResponse, TerritoryParams>({
        url: GET_TERRITORIES,
        method: 'GET',
        params: config?.params,
        lazy: config?.lazy ?? true,
    });

    return { data, loading, error, doFetch } as const;
}
