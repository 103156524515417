import React from 'react';

import { MenuItem } from '@core/api/useGlobalMenu';
import { formatPrice } from '@core/utils/currency';
import { CountryCodesEnum } from '@core/enums/flagsEnum';
import Text, { TextVariant } from '@components/ui/Typography/Text';
import Heading, { HeadingVariant } from '@components/ui/Typography/Heading';

import './MenuSection.styles.scss';

const MenuSection: React.FC<{
    countryCode: CountryCodesEnum;
    title?: string;
    items: MenuItem[];
    sectionAnchorId?: string;
}> = ({
    countryCode,
    title,
    items,
    sectionAnchorId
}) => {
    return (
        <div className="MenuSection" id={sectionAnchorId}>
            <div className="MenuSection__header">{title}</div>
            <div className="MenuSection__items-container">
                {items.map((item: MenuItem, itemIndex: number) => (
                    <div className="MenuSection__item" key={`${item.name}-${itemIndex}`}>
                        <div className="MenuSection__item-text">
                            <Heading variant={HeadingVariant.H5}>{item.name}</Heading>
                            <Text variant={TextVariant.B2}>{item.description}</Text>
                        </div>
                        <div className="MenuSection__item-price">
                            {formatPrice(countryCode, item.price as number) ?? ''}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default MenuSection;
