import { createContext, ReactNode, useCallback, useContext, useEffect, useMemo } from 'react';

import { useLocale } from '@core/utils/locale';
import useCountries from '@core/api/useCountries';
import usePagination from '@core/hooks/usePagination';
import useTableSort, { useTableSortReturn } from '@hooks/useTableSort';
import useAdvancedSearchFilters from '@core/hooks/useAdvancedSearchFilters';
import useAdvancedSearchCriteria from '@core/hooks/useAdvancedSearchCriteria';
import { DropdownOption } from '@components/utility/Inputs/Dropdowns/Dropdowns.helpers';
import { CriterionEnum, CriterionParam, CriterionParamFilter } from '@core/models/Criterion';
import { OP_TARGETING_DEFAULT_SORT_FIELD } from '@pages/OperatorTargetingResults/hooks/useOperatorResultsTable';
import { OperatorFilter } from '@graphql/generated/graphql';

type CountryTypes = {
    activeCountry?: CriterionParam;
    countriesList: ReturnType<typeof useCountries>['data'];
    allowedCountries: ReturnType<typeof useCountries>['allowedCountries'];
    defaultOrFirstAllowedCountry: ReturnType<typeof useCountries>['defaultOrFirstAllowedCountry'];
};

type AddCriterionOnOptionSelected = (cr: CriterionEnum, filter: CriterionParamFilter) => (opt: DropdownOption) => void;

type AdvancedSearchContextType = {
    addCriterionOnOptionSelected: AddCriterionOnOptionSelected;
    searchFilters?: OperatorFilter;
    advancedSearchSort: useTableSortReturn;
    pagination: ReturnType<typeof usePagination>;
} & ReturnType<typeof useAdvancedSearchCriteria> &
    CountryTypes;

export const AdvancedSearchContext = createContext<AdvancedSearchContextType | undefined>(undefined);

export function AdvancedSearchProvider({ children }: { children: ReactNode }) {
    const { locale } = useLocale();
    const advancedSearchSort = useTableSort(OP_TARGETING_DEFAULT_SORT_FIELD);

    const {
        criteria,
        onAddCriterionParam,
        getCriterionByFilterKey,
        ...advSearchRest
    } = useAdvancedSearchCriteria();

    const { searchFilters } = useAdvancedSearchFilters(criteria);

    const {
        data: countriesList,
        allowedCountries,
        defaultOrFirstAllowedCountry,
        loading: allCountriesLoading,
    } = useCountries(locale);

    const activeCountry = useMemo(
        () => getCriterionByFilterKey(CriterionEnum.Location, CriterionParamFilter.Country)?.[0],
        [getCriterionByFilterKey],
    );

    const setDefaultCountryParam = useCallback(() => {
        if (defaultOrFirstAllowedCountry?.abbreviation && defaultOrFirstAllowedCountry?.name) {
            const criterionParam: CriterionParam = {
                value: defaultOrFirstAllowedCountry.abbreviation,
                name: defaultOrFirstAllowedCountry.name,
                filterKey: CriterionParamFilter.Country,
            };

            onAddCriterionParam(CriterionEnum.Location, criterionParam);
        }
    }, [defaultOrFirstAllowedCountry?.abbreviation, defaultOrFirstAllowedCountry?.name, onAddCriterionParam]);

    useEffect(() => {
        if (!activeCountry && allCountriesLoading === false) {
            setDefaultCountryParam();
        }
    }, [activeCountry, allCountriesLoading, setDefaultCountryParam]);

    const addCriterionOnOptionSelected: AddCriterionOnOptionSelected = useCallback(
        (criterionKey, filterKey) => (option) => {
            const criterionParam = {
                value: option.value,
                name: option.label,
                filterKey,
                additionalData: option?.additionalData,
            };

            onAddCriterionParam(criterionKey, criterionParam);
        },
        [onAddCriterionParam],
    );

    return (
        <AdvancedSearchContext.Provider
            value={{
                criteria,
                onAddCriterionParam,
                getCriterionByFilterKey,
                ...advSearchRest,
                advancedSearchSort,
                addCriterionOnOptionSelected,
                pagination: usePagination(),
                searchFilters,
                activeCountry,
                allowedCountries,
                countriesList,
                defaultOrFirstAllowedCountry,
            }}
        >
            {children}
        </AdvancedSearchContext.Provider>
    );
}

export function useAdvancedSearch() {
    const context = useContext(AdvancedSearchContext);
    if (context === undefined) {
        throw new Error('useAdvancedSearch must be used within a AdvancedSearchProvider');
    }
    return context;
}
