import { createContext, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import useUser from '@core/api/useUser';
import { PermissionsStructure } from '@core/hooks/usePermissions';
import { getLocaleFromString, LocalesEnum } from '@enums/localeEnum';
import useTerritories from '@core/api/useTerritories';

export interface iUserInfo {
    id: string;
    locale?: LocalesEnum;
    fullName: string;
    permissions: PermissionsStructure | null;
    features?: {
        leadscore?: boolean;
        premium_field?: string[];
    };
    defaultCountry: string;
    exportAllowed: boolean;
    clientExternalIdType: string;
}

const DEFAULT_CONTEXT_VALUE: iUserInfo = {
    id: '',
    fullName: '',
    permissions: null,
    defaultCountry: '',
    exportAllowed: false,
    features: undefined,
    clientExternalIdType: '',
};

export const AppContext = createContext<{
    userInfo: iUserInfo;
    isLoading: boolean;
    isUserId: (id?: string) => boolean;
    hasTerritoryAccess: boolean;
        }>({
            userInfo: DEFAULT_CONTEXT_VALUE,
            isLoading: true,
            isUserId: () => false,
            hasTerritoryAccess: false,
        });

export function AppProvider({ children }: { children: ReactNode }) {
    const [userInfo, setUserInfo] = useState<iUserInfo>(DEFAULT_CONTEXT_VALUE);
    const {
        data,
        loading: isUserLoading
    } = useUser();

    const { data: territoriesData } = useTerritories({ lazy: false });
    const hasTerritoryAccess = useMemo(() => territoriesData?.territories?.length !== 0, [territoriesData]);

    const isLoading = useMemo(() => {
        return !!(isUserLoading || !userInfo.fullName || territoriesData === null);
    }, [isUserLoading, territoriesData, userInfo.fullName]);

    const isUserId = useCallback((id?: string) => id === userInfo.id, [userInfo.id]);

    useEffect(() => {
        if (data) {
            setUserInfo({
                id: data.external_id,
                locale: getLocaleFromString(data?.locale ?? data?.company?.default_locale ?? ''),
                fullName: `${data.first_name} ${data.last_name}`,
                permissions: data.permissions,
                defaultCountry: data?.country ?? data?.company?.default_country ?? '',
                exportAllowed: data?.company?.export_allowed ?? false,
                features: data?.features,
                clientExternalIdType: data?.company?.client_external_id_type,
            });
        }
    }, [data]);

    const contextValue = useMemo(
        () => ({ userInfo, isLoading, isUserId, hasTerritoryAccess }),
        [userInfo, isLoading, isUserId, hasTerritoryAccess],
    );

    return <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>;
}

export function useAppContext() {
    return useContext(AppContext);
}
