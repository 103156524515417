export enum FeatureFlags {
    RadiusSection = 'RADIUS_SECTION',
    NearMe = 'NEAR_ME',
    PurchasePotentialFilter = 'FF_PURCHASE_POTENTIAL',
    EstMealsPerDay = 'FF_EST_MEALS_PER_DAY',
    TagsExport = 'FF_TAGS_EXPORT',
    ResultsMap = 'FF_RESULTS_MAP',
    ProfileTags = 'FF_PROFILE_TAGS',
    LodgingNav = 'FF_LODGING_NAV',
    NumberOfRooms = 'FF_NUMBER_OF_ROOMS',
    HotelClass = 'FF_HOTEL_CLASS',
    TagsFilter = 'FF_TAGS_FILTER',
    GlobalMenu = 'FF_GLOBAL_MENU',
    ExportExcel = 'FF_EXCEL_EXPORT',
    RestaurantsSummary = 'FF_RESTAURANTS_SUMMARY',
    CuisinesSummary = 'FF_CUISINES_CHART_SUMMARY',
    AverageCheck = 'FF_AVERAGE_CHECK',
    RestaurantsChainsVsIndependents = 'FF_RESTAURANTS_CHAINS_VS_INDEPENDENTS',
    CustomerStatusProfile = 'FF_CUSTOMER_STATUS_PROFILE',
    CustomerStatusExport = 'FF_CUSTOMER_STATUS_EXPORT',
    CustomerStatusSearch = 'FF_CUSTOMER_STATUS_SEARCH',
    CustomerStatusTable = 'FF_CUSTOMER_STATUS_TABLE',
    LodginChart = 'FF_LODGING_CHART',
    RecreationChart = 'FF_RECREATION_CHART',
    TerritoriesPage = 'FF_TERRITORIES_PAGE',
    MenuSearch = 'FF_MENU_SEARCH',
}

export function useFeatureFlag(flag: string): boolean {
    return process.env[`REACT_APP_${flag}`] === 'true';
}
