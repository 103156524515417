import { useCallback, useEffect, useState } from 'react';

import { ID } from '@core/models';
import { IconNearMe } from '@assets/icons';
import Map from '@components/utility/Map/Map';
import { useLocale } from '@core/utils/locale';
import { CountryCoords } from '@core/models/Map';
import { useAdvancedSearch } from '@core/contexts';
import { useSelectInput } from '@core/hooks/useInput';
import { generateAnchorId } from '@core/utils/string';
import { CountryCodesEnum } from '@core/enums/flagsEnum';
import Select from '@components/utility/Inputs/Select/Select';
import Widget, { WidgetType } from '@components/utility/Widget/Widget';
import { SelectOption } from '@components/utility/Inputs/Inputs.helpers';
import { FeatureFlags, useFeatureFlag } from '@core/hooks/useFeatureFlag';
import Button, { ButtonModifiers } from '@components/utility/Button/Button';
import Heading, { HeadingVariant } from '@components/ui/Typography/Heading';
import SearchInput from '@components/utility/Inputs/SearchInput/SearchInput';
import { CriterionEnum, CriterionParam, CriterionParamFilter } from '@core/models/Criterion';
import useSearchRadiusAddress from '@pages/OperatorTargetingCriteria/hooks/useSearchRadiusAddress';
import { CommonPhrasesEnum, FilterTypeEnum, OperatorTargetingPhrasesEnum } from '@enums/localeEnum';
import useGetRadiusMapPolygons from '@pages/OperatorTargetingCriteria/hooks/useGetRadiusMapPolygons';

import './RadiusCriteria.styles.scss';

const RADIUS_OPTIONS: SelectOption[] = [
    { label: '1 km', id: '1' },
    { label: '3 km', id: '3' },
    { label: '5 km', id: '5' },
    { label: '10 km', id: '10' },
    { label: '20 km', id: '20' },
    { label: '30 km', id: '30' },
    { label: '50 km', id: '50' },
    { label: '100 km', id: '100' },
];

const MAP_OPTIONS = { zoomControl: true };

const RadiusCriteria = () => {
    const [map, setMap] = useState<google.maps.Map | null>(null);
    const google = window.google;

    const { t } = useLocale();
    const isNearMeEnabled = useFeatureFlag(FeatureFlags.NearMe);

    const {
        activeCountry,
        onAddCriterionParam
    } = useAdvancedSearch();

    const {
        value: selectedRadius,
        onChange: onChangeRadius
    } = useSelectInput();

    const {
        predictions,
        isLoading,
        onSearchInputTextChange,
        onChangeSearchCallback,
        searchSelectedOption,
        nearMeError,
        onClickNearMe,
        selectedAddressCoords,
        onResetSelectedAddress,
    } = useSearchRadiusAddress(activeCountry?.value as CountryCodesEnum);

    useGetRadiusMapPolygons(map, selectedRadius ? Number(selectedRadius) : 0, selectedAddressCoords);

    const getMapCallback = useCallback((map: google.maps.Map) => {
        setMap(map);
    }, []);

    const fitCountryBounds = useCallback(
        (countryId: ID) => {
            if (!map || !google?.maps) {
                return;
            }

            fetch('countries-coords.json')
                .then((response) => response.json())
                .then((countries) => {
                    const country = countries.find((c: CountryCoords) => c.short_name === countryId);
                    const bounds = new google.maps.LatLngBounds(
                        new google.maps.LatLng(country.sw_lat, country.sw_lng),
                        new google.maps.LatLng(country.ne_lat, country.ne_lng),
                    );

                    map.fitBounds(bounds);
                });
        },
        [google?.maps, map],
    );

    const onApplyRadiusSearch = useCallback(() => {
        if (!selectedAddressCoords) {
            return;
        }

        const geoDistanceValue = {
            distance: `${selectedRadius}km`,
            lat: selectedAddressCoords.lat,
            lon: selectedAddressCoords.lng,
        };
        const criterionParam: CriterionParam = {
            value: JSON.stringify(geoDistanceValue),
            name: `${t(OperatorTargetingPhrasesEnum.RadiusCriteriaWithin)} ${selectedRadius} km ${t(OperatorTargetingPhrasesEnum.RadiusCriteriaOf)} ${selectedAddressCoords?.name}`,
            filterKey: CriterionParamFilter.GeoLocation,
            additionalData: geoDistanceValue,
        };

        onAddCriterionParam(CriterionEnum.Location, criterionParam);
    }, [onAddCriterionParam, selectedAddressCoords, selectedRadius, t]);

    useEffect(() => {
        if (activeCountry) {
            onResetSelectedAddress();
            fitCountryBounds(activeCountry.value);
        }
    }, [activeCountry, fitCountryBounds, onResetSelectedAddress]);

    useEffect(() => {
        onChangeRadius(RADIUS_OPTIONS[0].id as string);
    }, [onChangeRadius]);

    return (
        <div className="CriteriaContent__section RadiusCriteria">
            <Widget
                type={WidgetType.Section}
                className="criteria"
                id={generateAnchorId(t(FilterTypeEnum.Radius))}
                dataObservable
            >
                <Heading variant={HeadingVariant.H4}>{t(FilterTypeEnum.Radius)}</Heading>
                <div className="CriteriaContent__columns col-2">
                    <div className="LocationContent__input-container">
                        <SearchInput
                            options={predictions}
                            onChangeCallback={onChangeSearchCallback}
                            onInputChangeCallback={onSearchInputTextChange}
                            placeholder={t(OperatorTargetingPhrasesEnum.SearchByRadiusPlaceholder)}
                            value={searchSelectedOption}
                            testId="RadiusTypeSearch"
                            customWidth="100%"
                            isLoading={isLoading}
                            autocompleteProps={{
                                filterOptions: (x) => x,
                            }}
                        />

                        <div className="RadiusCriteria__actions-container">
                            <div className="RadiusCriteria__container-select-radius">
                                <Select
                                    value={selectedRadius ?? ''}
                                    placeholder={t(OperatorTargetingPhrasesEnum.SelectRadius)}
                                    onChange={onChangeRadius}
                                    options={RADIUS_OPTIONS}
                                    testId="dropdownSelectRadius"
                                />
                            </div>
                            {isNearMeEnabled && (
                                <Button
                                    modifiers={
                                        [
                                            'tertiary',
                                            'auto',
                                            ...(nearMeError ? ['error-border'] : []),
                                        ] as ButtonModifiers[]
                                    }
                                    style={{ columnGap: '10px' }}
                                    testId="buttonLocationNearMe"
                                    onClick={onClickNearMe}
                                >
                                    <IconNearMe />
                                    {t(OperatorTargetingPhrasesEnum.NearMe)}
                                </Button>
                            )}
                        </div>
                        <div className="RadiusCriteria__actions-container">
                            <Button
                                modifiers={['primary', 'auto']}
                                testId="buttonApplyRadiusSearch"
                                onClick={onApplyRadiusSearch}
                                isDisabled={!selectedAddressCoords}
                            >
                                {t(CommonPhrasesEnum.Apply)}
                            </Button>
                        </div>
                    </div>
                    <div className="RadiusCriteria__map-container">
                        <Map options={MAP_OPTIONS} getMapCallback={getMapCallback} />
                        {/* Hidden until map highlight dragging gets sorted out */}
                        {/* <div className="RadiusCriteria__drag-banner">{t(OperatorTargetingPhrasesEnum.DragMap)}</div> */}
                        {/* </Map> */}
                    </div>
                </div>
            </Widget>
        </div>
    );
};

export default RadiusCriteria;
