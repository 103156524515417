import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null | undefined;
export type InputMaybe<T> = T | null | undefined;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
    ID: { input: string; output: string };
    String: { input: string; output: string };
    Boolean: { input: boolean; output: boolean };
    Int: { input: number; output: number };
    Float: { input: number; output: number };
    BigDecimal: { input: any; output: any };
    BigInteger: { input: any; output: any };
    Char: { input: any; output: any };
    CountryCode: { input: any; output: any };
    Currency: { input: any; output: any };
    Date: { input: any; output: any };
    DateTime: { input: any; output: any };
    JSON: { input: any; output: any };
    LocalTime: { input: any; output: any };
    Locale: { input: any; output: any };
    Long: { input: any; output: any };
    Object: { input: any; output: any };
    Short: { input: any; output: any };
    Time: { input: any; output: any };
    UUID: { input: any; output: any };
    Url: { input: any; output: any };
};

export type AggregationCount = {
    __typename?: 'AggregationCount';
    children?: Maybe<Array<Maybe<AggregationCount>>>;
    count?: Maybe<Scalars['Long']['output']>;
    description?: Maybe<Scalars['String']['output']>;
    key?: Maybe<Scalars['String']['output']>;
};

export type CompanyData = {
    __typename?: 'CompanyData';
    status?: Maybe<Scalars['String']['output']>;
};

export enum ConfidenceLevel {
    High = 'HIGH',
    Low = 'LOW',
    Medium = 'MEDIUM',
}

export enum DataSource {
    Table = 'TABLE',
    View = 'VIEW',
}

export type DateExpression = {
    between?: InputMaybe<Array<Scalars['Date']['input']>>;
    eq?: InputMaybe<Scalars['Date']['input']>;
    gt?: InputMaybe<Scalars['Date']['input']>;
    gte?: InputMaybe<Scalars['Date']['input']>;
    lt?: InputMaybe<Scalars['Date']['input']>;
    lte?: InputMaybe<Scalars['Date']['input']>;
};

export type DateTimeExpression = {
    between?: InputMaybe<Array<Scalars['DateTime']['input']>>;
    eq?: InputMaybe<Scalars['DateTime']['input']>;
    gt?: InputMaybe<Scalars['DateTime']['input']>;
    gte?: InputMaybe<Scalars['DateTime']['input']>;
    lt?: InputMaybe<Scalars['DateTime']['input']>;
    lte?: InputMaybe<Scalars['DateTime']['input']>;
};

export type GeoDistance = {
    distance: Scalars['String']['input'];
    lat: Scalars['Float']['input'];
    lon: Scalars['Float']['input'];
};

export type GeoDistanceMatchExpression = {
    match_geo_distance: GeoDistance;
};

export type IntExpression = {
    between?: InputMaybe<Array<Scalars['Int']['input']>>;
    eq?: InputMaybe<Scalars['Int']['input']>;
    gt?: InputMaybe<Scalars['Int']['input']>;
    gte?: InputMaybe<Scalars['Int']['input']>;
    in?: InputMaybe<Array<Scalars['Int']['input']>>;
    lt?: InputMaybe<Scalars['Int']['input']>;
    lte?: InputMaybe<Scalars['Int']['input']>;
};

export type LongExpression = {
    between?: InputMaybe<Array<Scalars['Long']['input']>>;
    eq?: InputMaybe<Scalars['Long']['input']>;
    gt?: InputMaybe<Scalars['Long']['input']>;
    gte?: InputMaybe<Scalars['Long']['input']>;
    in?: InputMaybe<Array<Scalars['Long']['input']>>;
    lt?: InputMaybe<Scalars['Long']['input']>;
    lte?: InputMaybe<Scalars['Long']['input']>;
};

/** # Elasticsearch Expressions */
export type MatchExpression = {
    match?: InputMaybe<Scalars['String']['input']>;
    match_phrase?: InputMaybe<Scalars['String']['input']>;
    match_phrase_prefix?: InputMaybe<Scalars['String']['input']>;
    match_similarity?: InputMaybe<Scalars['String']['input']>;
    multi_match?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataRange = {
    __typename?: 'MetadataRange';
    description?: Maybe<Scalars['String']['output']>;
    display_max?: Maybe<Scalars['BigDecimal']['output']>;
    display_min?: Maybe<Scalars['BigDecimal']['output']>;
    type?: Maybe<Scalars['String']['output']>;
    unified_code?: Maybe<Scalars['String']['output']>;
};

export type OperatorChainParent = OperatorParent & {
    __typename?: 'OperatorChainParent';
    business_name?: Maybe<Scalars['String']['output']>;
    id?: Maybe<Scalars['ID']['output']>;
    number_of_units?: Maybe<Scalars['Int']['output']>;
    operator_identifier?: Maybe<Scalars['String']['output']>;
};

export type OperatorDelivery = {
    __typename?: 'OperatorDelivery';
    type?: Maybe<Scalars['String']['output']>;
    url?: Maybe<Scalars['String']['output']>;
};

export type OperatorDetails = {
    __typename?: 'OperatorDetails';
    /**  hateoas */
    _links?: Maybe<Array<Maybe<OperatorHateoasLink>>>;
    active_base_population?: Maybe<Scalars['Int']['output']>;
    alcohol_service_type?: Maybe<Scalars['String']['output']>;
    annual_sales?: Maybe<Scalars['Long']['output']>;
    annual_sales_type?: Maybe<Scalars['String']['output']>;
    business_detail_id?: Maybe<Scalars['String']['output']>;
    business_general_id?: Maybe<Scalars['String']['output']>;
    business_name?: Maybe<Scalars['String']['output']>;
    channel?: Maybe<Scalars['String']['output']>;
    chd_id?: Maybe<Scalars['String']['output']>;
    closed_temporarily?: Maybe<Scalars['Boolean']['output']>;
    /**  company data */
    company_data?: Maybe<CompanyData>;
    confidence_level?: Maybe<Scalars['String']['output']>;
    credit_limit?: Maybe<Scalars['Int']['output']>;
    credit_score_rating?: Maybe<Scalars['String']['output']>;
    cuisine?: Maybe<Scalars['String']['output']>;
    cuisine_code?: Maybe<Scalars['String']['output']>;
    date_closed?: Maybe<Scalars['String']['output']>;
    date_founded?: Maybe<Scalars['Date']['output']>;
    date_last_collected?: Maybe<Scalars['String']['output']>;
    date_last_review?: Maybe<Scalars['String']['output']>;
    date_of_health_inspection?: Maybe<Scalars['String']['output']>;
    delivery?: Maybe<Array<Maybe<OperatorDelivery>>>;
    dispo?: Maybe<Scalars['String']['output']>;
    ds_url?: Maybe<Scalars['String']['output']>;
    education_enrollment?: Maybe<Scalars['Int']['output']>;
    education_free_and_reduced_meals?: Maybe<Scalars['Int']['output']>;
    education_free_meals?: Maybe<Scalars['Int']['output']>;
    education_grade_levels?: Maybe<Scalars['String']['output']>;
    education_reduced_meals?: Maybe<Scalars['Int']['output']>;
    entity_type?: Maybe<Scalars['String']['output']>;
    est_meals_per_day?: Maybe<Scalars['Int']['output']>;
    /**  related entities */
    external_ids?: Maybe<Array<Maybe<OperatorExternalId>>>;
    firefly_id?: Maybe<Scalars['String']['output']>;
    general_email?: Maybe<Scalars['String']['output']>;
    health_inspection_score?: Maybe<Scalars['String']['output']>;
    healthcare_daily_patient_count?: Maybe<Scalars['Int']['output']>;
    healthcare_number_of_beds?: Maybe<Scalars['Int']['output']>;
    higher_education_students_on_mealplans?: Maybe<Scalars['Int']['output']>;
    hospital_type?: Maybe<Scalars['String']['output']>;
    host?: Maybe<OperatorHost>;
    host_number_of_units_location_within?: Maybe<Scalars['Int']['output']>;
    hours_friday?: Maybe<Scalars['String']['output']>;
    hours_monday?: Maybe<Scalars['String']['output']>;
    hours_saturday?: Maybe<Scalars['String']['output']>;
    hours_sunday?: Maybe<Scalars['String']['output']>;
    hours_thursday?: Maybe<Scalars['String']['output']>;
    hours_tuesday?: Maybe<Scalars['String']['output']>;
    hours_wednesday?: Maybe<Scalars['String']['output']>;
    id?: Maybe<Scalars['ID']['output']>;
    id_inspection?: Maybe<Scalars['String']['output']>;
    is_host_operator?: Maybe<Scalars['Boolean']['output']>;
    legacy_cuisine_id?: Maybe<Scalars['String']['output']>;
    legacy_segment_id?: Maybe<Scalars['String']['output']>;
    legal_name?: Maybe<Scalars['String']['output']>;
    location?: Maybe<OperatorLocationDetails>;
    lodging_room_count?: Maybe<Scalars['String']['output']>;
    lodging_star_level?: Maybe<Scalars['Int']['output']>;
    non_commercial_sector?: Maybe<Scalars['String']['output']>;
    number_of_employees?: Maybe<Scalars['String']['output']>;
    /**  metadata */
    number_of_units?: Maybe<Scalars['Int']['output']>;
    offers_24_hours_service?: Maybe<Scalars['Boolean']['output']>;
    offers_alcohol?: Maybe<Scalars['Boolean']['output']>;
    offers_banquet_facility?: Maybe<Scalars['Boolean']['output']>;
    offers_breakfast?: Maybe<Scalars['Boolean']['output']>;
    offers_cafeteria?: Maybe<Scalars['Boolean']['output']>;
    offers_catering?: Maybe<Scalars['Boolean']['output']>;
    offers_complimentary_breakfast?: Maybe<Scalars['Boolean']['output']>;
    offers_delivery?: Maybe<Scalars['Boolean']['output']>;
    offers_dine_in?: Maybe<Scalars['Boolean']['output']>;
    offers_dinner?: Maybe<Scalars['Boolean']['output']>;
    offers_drive_thru?: Maybe<Scalars['Boolean']['output']>;
    offers_eatertainment?: Maybe<Scalars['Boolean']['output']>;
    offers_fitness_center?: Maybe<Scalars['Boolean']['output']>;
    offers_fuel?: Maybe<Scalars['Boolean']['output']>;
    offers_higher_education_housing?: Maybe<Scalars['Boolean']['output']>;
    offers_in_store_bakery?: Maybe<Scalars['Boolean']['output']>;
    offers_in_store_deli?: Maybe<Scalars['Boolean']['output']>;
    offers_in_store_salad_bar?: Maybe<Scalars['Boolean']['output']>;
    offers_kids_menu?: Maybe<Scalars['Boolean']['output']>;
    offers_lunch?: Maybe<Scalars['Boolean']['output']>;
    offers_meeting_rooms?: Maybe<Scalars['Boolean']['output']>;
    offers_organic?: Maybe<Scalars['Boolean']['output']>;
    offers_outdoor_seating?: Maybe<Scalars['Boolean']['output']>;
    offers_reservations?: Maybe<Scalars['Boolean']['output']>;
    offers_room_service?: Maybe<Scalars['Boolean']['output']>;
    offers_takeout?: Maybe<Scalars['Boolean']['output']>;
    offers_tv?: Maybe<Scalars['Boolean']['output']>;
    offers_wifi?: Maybe<Scalars['Boolean']['output']>;
    open_seasonally?: Maybe<Scalars['Boolean']['output']>;
    operating_hours_raw?: Maybe<Scalars['String']['output']>;
    operator_identifier?: Maybe<Scalars['ID']['output']>;
    operator_status?: Maybe<Scalars['String']['output']>;
    parent_chain?: Maybe<OperatorChainParent>;
    /**  additional fields */
    parent_mco?: Maybe<OperatorMcoParent>;
    phone?: Maybe<Scalars['String']['output']>;
    popularity_score?: Maybe<Scalars['Int']['output']>;
    process_status?: Maybe<Scalars['String']['output']>;
    purchase_potential?: Maybe<Array<Maybe<OperatorPurchasePotential>>>;
    purchase_potential_sum_total?: Maybe<Scalars['Int']['output']>;
    ranges?: Maybe<Array<Maybe<MetadataRange>>>;
    reservation_links?: Maybe<Scalars['String']['output']>;
    restaurant_price_level?: Maybe<Scalars['Int']['output']>;
    segment?: Maybe<Scalars['String']['output']>;
    segment_code?: Maybe<Scalars['String']['output']>;
    services_gluten_free?: Maybe<Scalars['Boolean']['output']>;
    services_vegan?: Maybe<Scalars['Boolean']['output']>;
    services_vegetarian?: Maybe<Scalars['Boolean']['output']>;
    social_media?: Maybe<Array<Maybe<OperatorSocialMedia>>>;
    sub_channel?: Maybe<Scalars['String']['output']>;
    sub_cuisine?: Maybe<Scalars['String']['output']>;
    /**  tags */
    tags?: Maybe<Array<Maybe<OperatorTags>>>;
    total_average_review_rating?: Maybe<Scalars['BigDecimal']['output']>;
    total_review_count?: Maybe<Scalars['Int']['output']>;
    venue_capacity?: Maybe<Scalars['Int']['output']>;
    website?: Maybe<Scalars['String']['output']>;
};

export type OperatorDetailsSearch = {
    __typename?: 'OperatorDetailsSearch';
    _links?: Maybe<Array<Maybe<OperatorHateoasLink>>>;
    _metadata?: Maybe<SearchMetadata>;
    operators?: Maybe<Array<Maybe<OperatorDetails>>>;
};

export type OperatorExternalId = {
    __typename?: 'OperatorExternalId';
    type?: Maybe<Scalars['String']['output']>;
    value?: Maybe<Scalars['String']['output']>;
};

export type OperatorFilter = {
    address?: InputMaybe<StringExpression>;
    administrative_area_1?: InputMaybe<StringExpression>;
    administrative_area_2?: InputMaybe<StringExpression>;
    and?: InputMaybe<Array<OperatorFilter>>;
    annual_sales?: InputMaybe<IntExpression>;
    annual_sales_range?: InputMaybe<StringExpression>;
    average_check_range?: InputMaybe<StringExpression>;
    business_detail_id?: InputMaybe<StringExpression>;
    business_general_id?: InputMaybe<StringExpression>;
    chain_size_range?: InputMaybe<StringExpression>;
    city?: InputMaybe<StringExpression>;
    confidence_level?: InputMaybe<Array<ConfidenceLevel>>;
    country?: InputMaybe<StringExpression>;
    cuisine?: InputMaybe<StringExpression>;
    customer_status?: InputMaybe<StringExpression>;
    est_meals_per_day_range?: InputMaybe<StringExpression>;
    founded_date?: InputMaybe<DateExpression>;
    geo_location?: InputMaybe<GeoDistanceMatchExpression>;
    lodging_star_level?: InputMaybe<IntExpression>;
    menu_text?: InputMaybe<StringExpression>;
    modified_timestamp?: InputMaybe<DateExpression>;
    name?: InputMaybe<StringMatchExpression>;
    not?: InputMaybe<OperatorFilter>;
    number_of_employees_range?: InputMaybe<StringExpression>;
    number_of_rooms_range?: InputMaybe<StringExpression>;
    operator_identifier?: InputMaybe<StringExpression>;
    operator_status?: InputMaybe<Array<InputMaybe<OperatorStatus>>>;
    or?: InputMaybe<Array<OperatorFilter>>;
    phone?: InputMaybe<StringExpression>;
    postal_code?: InputMaybe<StringExpression>;
    process_status?: InputMaybe<Array<InputMaybe<ProcessStatus>>>;
    purchase_potential_total?: InputMaybe<IntExpression>;
    segmentation?: InputMaybe<StringExpression>;
    tags?: InputMaybe<StringExpression>;
    type?: InputMaybe<Array<InputMaybe<OperatorType>>>;
};

export type OperatorHateoasLink = {
    __typename?: 'OperatorHateoasLink';
    href?: Maybe<Scalars['Url']['output']>;
    rel?: Maybe<Scalars['String']['output']>;
};

export type OperatorHost = {
    __typename?: 'OperatorHost';
    business_name?: Maybe<Scalars['String']['output']>;
    id?: Maybe<Scalars['ID']['output']>;
    operator_identifier?: Maybe<Scalars['String']['output']>;
};

export type OperatorLocationDetails = {
    __typename?: 'OperatorLocationDetails';
    address_1?: Maybe<Scalars['String']['output']>;
    address_2?: Maybe<Scalars['String']['output']>;
    address_3?: Maybe<Scalars['String']['output']>;
    administrative_area_1?: Maybe<Scalars['String']['output']>;
    administrative_area_2?: Maybe<Scalars['String']['output']>;
    administrative_area_3?: Maybe<Scalars['String']['output']>;
    administrative_area_4?: Maybe<Scalars['String']['output']>;
    alternative_area_1?: Maybe<Scalars['String']['output']>;
    alternative_area_2?: Maybe<Scalars['String']['output']>;
    alternative_area_3?: Maybe<Scalars['String']['output']>;
    alternative_area_4?: Maybe<Scalars['String']['output']>;
    area_type?: Maybe<Scalars['String']['output']>;
    country?: Maybe<Scalars['String']['output']>;
    geo_precision_level?: Maybe<Scalars['String']['output']>;
    latitude?: Maybe<Scalars['Float']['output']>;
    locality_1?: Maybe<Scalars['String']['output']>;
    longitude?: Maybe<Scalars['Float']['output']>;
    metro?: Maybe<OperatorMetro>;
    postal_code_1?: Maybe<Scalars['String']['output']>;
    /**  additional fields */
    postal_code_2?: Maybe<Scalars['String']['output']>;
};

export type OperatorLocationSummary = {
    __typename?: 'OperatorLocationSummary';
    address_1?: Maybe<Scalars['String']['output']>;
    address_2?: Maybe<Scalars['String']['output']>;
    administrative_area_1?: Maybe<Scalars['String']['output']>;
    area_type?: Maybe<Scalars['String']['output']>;
    country?: Maybe<Scalars['String']['output']>;
    locality_1?: Maybe<Scalars['String']['output']>;
    postal_code_1?: Maybe<Scalars['String']['output']>;
};

export type OperatorMcoParent = OperatorParent & {
    __typename?: 'OperatorMCOParent';
    business_name?: Maybe<Scalars['String']['output']>;
    id?: Maybe<Scalars['ID']['output']>;
    operator_identifier?: Maybe<Scalars['String']['output']>;
};

export type OperatorMetro = {
    __typename?: 'OperatorMetro';
    name?: Maybe<Scalars['String']['output']>;
    population?: Maybe<Scalars['Int']['output']>;
};

export type OperatorParent = {
    business_name?: Maybe<Scalars['String']['output']>;
    id?: Maybe<Scalars['ID']['output']>;
    operator_identifier?: Maybe<Scalars['String']['output']>;
};

export type OperatorPurchasePotential = {
    __typename?: 'OperatorPurchasePotential';
    description?: Maybe<Scalars['String']['output']>;
    message_code?: Maybe<Scalars['String']['output']>;
    name?: Maybe<Scalars['String']['output']>;
    value?: Maybe<Scalars['Int']['output']>;
};

export type OperatorQuickFilter = {
    and?: InputMaybe<Array<OperatorQuickFilter>>;
    country?: InputMaybe<StringExpression>;
    name?: InputMaybe<MatchExpression>;
    not?: InputMaybe<OperatorQuickFilter>;
    or?: InputMaybe<Array<OperatorQuickFilter>>;
    type?: InputMaybe<Array<InputMaybe<OperatorType>>>;
};

export type OperatorQuickSearch = {
    __typename?: 'OperatorQuickSearch';
    _links?: Maybe<Array<Maybe<OperatorHateoasLink>>>;
    _metadata?: Maybe<SearchMetadata>;
    operators?: Maybe<Array<Maybe<OperatorSummary>>>;
};

export type OperatorSearch = {
    __typename?: 'OperatorSearch';
    _links?: Maybe<Array<Maybe<OperatorHateoasLink>>>;
    _metadata?: Maybe<SearchMetadata>;
    operators?: Maybe<Array<Maybe<OperatorSummary>>>;
};

export type OperatorSearchAggregation = {
    __typename?: 'OperatorSearchAggregation';
    confidence_level?: Maybe<Array<Maybe<AggregationCount>>>;
    cuisine?: Maybe<Array<Maybe<AggregationCount>>>;
    entity_type?: Maybe<Array<Maybe<AggregationCount>>>;
    independent_vs_chain?: Maybe<Array<Maybe<AggregationCount>>>;
    ranges?: Maybe<Array<Maybe<RangeAggregationCount>>>;
    segments?: Maybe<Array<Maybe<AggregationCount>>>;
};

export type OperatorSearchSlim = {
    __typename?: 'OperatorSearchSlim';
    operators?: Maybe<Array<Maybe<OperatorSummarySlim>>>;
    previous_search_last_id?: Maybe<Scalars['Int']['output']>;
    previous_search_operator_identifier?: Maybe<Scalars['String']['output']>;
    size?: Maybe<Scalars['Int']['output']>;
    total_count?: Maybe<Scalars['Long']['output']>;
};

export type OperatorSlimClassification = {
    __typename?: 'OperatorSlimClassification';
    ca_localization?: Maybe<Scalars['String']['output']>;
    fr_localization?: Maybe<Scalars['String']['output']>;
    localization_code?: Maybe<Scalars['String']['output']>;
    unified_code?: Maybe<Scalars['String']['output']>;
    us_localization?: Maybe<Scalars['String']['output']>;
};

export type OperatorSlimCompanyData = {
    __typename?: 'OperatorSlimCompanyData';
    external_id_type?: Maybe<Scalars['String']['output']>;
    status?: Maybe<Scalars['String']['output']>;
};

export type OperatorSlimLocation = {
    __typename?: 'OperatorSlimLocation';
    address?: Maybe<Scalars['String']['output']>;
    administrative_area_1?: Maybe<Scalars['String']['output']>;
    administrative_area_2?: Maybe<Scalars['String']['output']>;
    city?: Maybe<Scalars['String']['output']>;
    country?: Maybe<Scalars['String']['output']>;
    postal_code?: Maybe<Scalars['String']['output']>;
};

export type OperatorSocialMedia = {
    __typename?: 'OperatorSocialMedia';
    average_rating?: Maybe<Scalars['Float']['output']>;
    total_reviews?: Maybe<Scalars['Int']['output']>;
    type?: Maybe<Scalars['String']['output']>;
    url?: Maybe<Scalars['String']['output']>;
};

export enum OperatorStatus {
    Closed = 'CLOSED',
    Open = 'OPEN',
    PreOpen = 'PRE_OPEN',
    TempClosed = 'TEMP_CLOSED',
    Unknown = 'UNKNOWN',
}

export type OperatorSummary = {
    __typename?: 'OperatorSummary';
    /**  hateoas */
    _links?: Maybe<Array<Maybe<OperatorHateoasLink>>>;
    annual_sales?: Maybe<Scalars['Long']['output']>;
    annual_sales_type?: Maybe<Scalars['String']['output']>;
    business_name?: Maybe<Scalars['String']['output']>;
    /**  company data */
    company_data?: Maybe<CompanyData>;
    cuisine?: Maybe<Scalars['String']['output']>;
    date_founded?: Maybe<Scalars['Date']['output']>;
    ds_url?: Maybe<Scalars['String']['output']>;
    entity_type?: Maybe<Scalars['String']['output']>;
    est_meals_per_day?: Maybe<Scalars['Int']['output']>;
    /**  related entities */
    external_ids?: Maybe<Array<Maybe<OperatorExternalId>>>;
    id?: Maybe<Scalars['ID']['output']>;
    location?: Maybe<OperatorLocationSummary>;
    /**  metadata */
    number_of_units?: Maybe<Scalars['Int']['output']>;
    operator_identifier?: Maybe<Scalars['ID']['output']>;
    operator_status?: Maybe<Scalars['String']['output']>;
    parent_chain?: Maybe<OperatorChainParent>;
    phone?: Maybe<Scalars['String']['output']>;
    segment?: Maybe<Scalars['String']['output']>;
    sub_channel?: Maybe<Scalars['String']['output']>;
    sub_cuisine?: Maybe<Scalars['String']['output']>;
    website?: Maybe<Scalars['String']['output']>;
};

export type OperatorSummarySlim = {
    __typename?: 'OperatorSummarySlim';
    annual_sales?: Maybe<Scalars['Long']['output']>;
    annual_sales_range?: Maybe<Scalars['String']['output']>;
    average_check_range?: Maybe<Scalars['String']['output']>;
    business_detail_id?: Maybe<Scalars['String']['output']>;
    business_general_id?: Maybe<Scalars['String']['output']>;
    chain_size_range?: Maybe<Scalars['String']['output']>;
    company_data?: Maybe<Array<Maybe<OperatorSlimCompanyData>>>;
    confidence_level?: Maybe<Scalars['String']['output']>;
    cuisine?: Maybe<OperatorSlimClassification>;
    cuisine_id?: Maybe<Scalars['Long']['output']>;
    est_meals_per_day_range?: Maybe<Scalars['String']['output']>;
    founded_date?: Maybe<Scalars['Date']['output']>;
    id?: Maybe<Scalars['Int']['output']>;
    latitude?: Maybe<Scalars['Float']['output']>;
    location?: Maybe<OperatorSlimLocation>;
    lodging_star_level?: Maybe<Scalars['Int']['output']>;
    longitude?: Maybe<Scalars['Float']['output']>;
    modified_timestamp?: Maybe<Scalars['Date']['output']>;
    name?: Maybe<Scalars['String']['output']>;
    number_of_employees_range?: Maybe<Scalars['String']['output']>;
    number_of_hosted_units?: Maybe<Scalars['Long']['output']>;
    number_of_rooms_range?: Maybe<Scalars['String']['output']>;
    number_of_units_in_chain?: Maybe<Scalars['Int']['output']>;
    operator_identifier?: Maybe<Scalars['String']['output']>;
    operator_status?: Maybe<Scalars['String']['output']>;
    phone?: Maybe<Scalars['String']['output']>;
    process_status?: Maybe<Scalars['String']['output']>;
    purchase_potential_total?: Maybe<Scalars['Int']['output']>;
    segment?: Maybe<OperatorSlimClassification>;
    segment_id?: Maybe<Scalars['Long']['output']>;
    tags?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
    type?: Maybe<Scalars['String']['output']>;
    ui_visibility?: Maybe<Scalars['Boolean']['output']>;
};

export type OperatorTags = {
    __typename?: 'OperatorTags';
    code?: Maybe<Scalars['String']['output']>;
    description?: Maybe<Scalars['String']['output']>;
    key?: Maybe<Scalars['String']['output']>;
    type?: Maybe<Scalars['String']['output']>;
};

export enum OperatorType {
    Chain = 'CHAIN',
    Gpo = 'GPO',
    Mco = 'MCO',
    Unit = 'UNIT',
}

export enum ProcessStatus {
    MachineVerified = 'MACHINE_VERIFIED',
    Merged = 'MERGED',
    NeedsReview = 'NEEDS_REVIEW',
    Parked = 'PARKED',
    ReviewedConfirmed = 'REVIEWED_CONFIRMED',
    ReviewedUpdated = 'REVIEWED_UPDATED',
}

export type Query = {
    __typename?: 'Query';
    aggregation?: Maybe<OperatorSearchAggregation>;
    count?: Maybe<Scalars['Int']['output']>;
    get?: Maybe<OperatorDetails>;
    get_user_field_permissions?: Maybe<UserFieldPermissions>;
    quick_search?: Maybe<OperatorQuickSearch>;
    search?: Maybe<OperatorSearch>;
    search_details?: Maybe<OperatorDetailsSearch>;
    search_es?: Maybe<OperatorSearchSlim>;
};

export type QueryAggregationArgs = {
    filter?: InputMaybe<OperatorFilter>;
    uiVisible?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryCountArgs = {
    filter?: InputMaybe<OperatorFilter>;
    uiVisible?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryGetArgs = {
    locale?: InputMaybe<Scalars['String']['input']>;
    operatorIdentifier?: InputMaybe<Scalars['ID']['input']>;
    uiVisible?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryGet_User_Field_PermissionsArgs = {
    filter?: InputMaybe<OperatorFilter>;
    uiVisible?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryQuick_SearchArgs = {
    filter?: InputMaybe<OperatorQuickFilter>;
    locale?: InputMaybe<Scalars['String']['input']>;
    pageNumber?: InputMaybe<Scalars['Int']['input']>;
    pageSize?: InputMaybe<Scalars['Int']['input']>;
    sort?: InputMaybe<Array<InputMaybe<Sort>>>;
    uiVisible?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QuerySearchArgs = {
    filter?: InputMaybe<OperatorFilter>;
    locale?: InputMaybe<Scalars['String']['input']>;
    pageNumber?: InputMaybe<Scalars['Int']['input']>;
    pageSize?: InputMaybe<Scalars['Int']['input']>;
    sort?: InputMaybe<Array<InputMaybe<Sort>>>;
    uiVisible?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QuerySearch_DetailsArgs = {
    filter?: InputMaybe<OperatorFilter>;
    locale?: InputMaybe<Scalars['String']['input']>;
    pageNumber?: InputMaybe<Scalars['Int']['input']>;
    pageSize?: InputMaybe<Scalars['Int']['input']>;
    sort?: InputMaybe<Array<InputMaybe<Sort>>>;
    source?: InputMaybe<DataSource>;
    uiVisible?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QuerySearch_EsArgs = {
    filter?: InputMaybe<OperatorFilter>;
    locale?: InputMaybe<Scalars['String']['input']>;
    pageSize?: InputMaybe<Scalars['Int']['input']>;
    previousSearchLastId?: InputMaybe<Scalars['Int']['input']>;
    previousSearchOperatorIdentifier?: InputMaybe<Scalars['String']['input']>;
    uiVisible?: InputMaybe<Scalars['Boolean']['input']>;
};

export type RangeAggregationCount = {
    __typename?: 'RangeAggregationCount';
    description?: Maybe<Scalars['String']['output']>;
    elements?: Maybe<Array<Maybe<AggregationCount>>>;
};

export type SearchMetadata = {
    __typename?: 'SearchMetadata';
    page?: Maybe<Scalars['Int']['output']>;
    page_count?: Maybe<Scalars['Int']['output']>;
    size?: Maybe<Scalars['Int']['output']>;
    total_count?: Maybe<Scalars['Int']['output']>;
};

export type Sort = {
    field: Scalars['String']['input'];
    order?: SortOrder;
    parameter1?: InputMaybe<Scalars['Float']['input']>;
    parameter2?: InputMaybe<Scalars['Float']['input']>;
};

export enum SortOrder {
    Asc = 'ASC',
    Desc = 'DESC',
}

/** # Expressions */
export type StringExpression = {
    contains?: InputMaybe<Scalars['String']['input']>;
    ends?: InputMaybe<Scalars['String']['input']>;
    equals?: InputMaybe<Scalars['String']['input']>;
    in?: InputMaybe<Array<Scalars['String']['input']>>;
    starts?: InputMaybe<Scalars['String']['input']>;
};

export type StringMatchExpression = {
    contains?: InputMaybe<Scalars['String']['input']>;
    ends?: InputMaybe<Scalars['String']['input']>;
    equals?: InputMaybe<Scalars['String']['input']>;
    in?: InputMaybe<Array<Scalars['String']['input']>>;
    match?: InputMaybe<Scalars['String']['input']>;
    match_phrase?: InputMaybe<Scalars['String']['input']>;
    match_phrase_prefix?: InputMaybe<Scalars['String']['input']>;
    match_similarity?: InputMaybe<Scalars['String']['input']>;
    multi_match?: InputMaybe<Scalars['String']['input']>;
    starts?: InputMaybe<Scalars['String']['input']>;
};

export type UserFieldPermissions = {
    __typename?: 'UserFieldPermissions';
    has_data?: Maybe<Scalars['Boolean']['output']>;
    has_permission?: Maybe<Scalars['Boolean']['output']>;
};

export type BasicOperatorFieldsFragment = {
    __typename?: 'OperatorSummary';
    id?: string | null | undefined;
    operator_identifier?: string | null | undefined;
    entity_type?: string | null | undefined;
    business_name?: string | null | undefined;
    operator_status?: string | null | undefined;
    phone?: string | null | undefined;
    website?: string | null | undefined;
    segment?: string | null | undefined;
    cuisine?: string | null | undefined;
    sub_cuisine?: string | null | undefined;
    sub_channel?: string | null | undefined;
    date_founded?: any | null | undefined;
    number_of_units?: number | null | undefined;
    est_meals_per_day?: number | null | undefined;
    annual_sales?: any | null | undefined;
};

export type OperatorChainParentFieldsFragment = {
    __typename?: 'OperatorChainParent';
    id?: string | null | undefined;
    business_name?: string | null | undefined;
    number_of_units?: number | null | undefined;
    operator_identifier?: string | null | undefined;
};

export type OperatorExternalIdFieldsFragment = {
    __typename?: 'OperatorExternalId';
    type?: string | null | undefined;
    value?: string | null | undefined;
};

export type OperatorHostFieldsFragment = {
    __typename?: 'OperatorHost';
    id?: string | null | undefined;
    operator_identifier?: string | null | undefined;
    business_name?: string | null | undefined;
};

export type OperatorLocationFieldsFragment = {
    __typename?: 'OperatorLocationSummary';
    country?: string | null | undefined;
    area_type?: string | null | undefined;
    address_1?: string | null | undefined;
    address_2?: string | null | undefined;
    locality_1?: string | null | undefined;
    administrative_area_1?: string | null | undefined;
    postal_code_1?: string | null | undefined;
};

export type MetadataRangeFieldsFragment = {
    __typename?: 'MetadataRange';
    display_max?: any | null | undefined;
    display_min?: any | null | undefined;
    type?: string | null | undefined;
    unified_code?: string | null | undefined;
};

export type OperatorSocialMediaFieldsFragment = {
    __typename?: 'OperatorSocialMedia';
    type?: string | null | undefined;
    url?: string | null | undefined;
    average_rating?: number | null | undefined;
    total_reviews?: number | null | undefined;
};

export type OperatorDeliveryFieldsFragment = {
    __typename?: 'OperatorDelivery';
    type?: string | null | undefined;
    url?: string | null | undefined;
};

export type OperatorPurchasePotentialFieldsFragment = {
    __typename?: 'OperatorPurchasePotential';
    description?: string | null | undefined;
    message_code?: string | null | undefined;
    name?: string | null | undefined;
    value?: number | null | undefined;
};

export type BasicOperatorDetailsFieldsFragment = {
    __typename?: 'OperatorDetails';
    id?: string | null | undefined;
    operator_identifier?: string | null | undefined;
    entity_type?: string | null | undefined;
    business_name?: string | null | undefined;
    operator_status?: string | null | undefined;
    phone?: string | null | undefined;
    website?: string | null | undefined;
    segment?: string | null | undefined;
    segment_code?: string | null | undefined;
    cuisine?: string | null | undefined;
    sub_channel?: string | null | undefined;
    date_founded?: any | null | undefined;
    number_of_units?: number | null | undefined;
    est_meals_per_day?: number | null | undefined;
    annual_sales?: any | null | undefined;
    purchase_potential_sum_total?: number | null | undefined;
    sub_cuisine?: string | null | undefined;
    channel?: string | null | undefined;
    offers_breakfast?: boolean | null | undefined;
    offers_lunch?: boolean | null | undefined;
    offers_dinner?: boolean | null | undefined;
    offers_takeout?: boolean | null | undefined;
    offers_delivery?: boolean | null | undefined;
    offers_drive_thru?: boolean | null | undefined;
    offers_catering?: boolean | null | undefined;
    offers_reservations?: boolean | null | undefined;
    offers_kids_menu?: boolean | null | undefined;
    offers_eatertainment?: boolean | null | undefined;
    offers_banquet_facility?: boolean | null | undefined;
    offers_complimentary_breakfast?: boolean | null | undefined;
    offers_meeting_rooms?: boolean | null | undefined;
    offers_room_service?: boolean | null | undefined;
    offers_cafeteria?: boolean | null | undefined;
    offers_higher_education_housing?: boolean | null | undefined;
    offers_fuel?: boolean | null | undefined;
    offers_alcohol?: boolean | null | undefined;
    alcohol_service_type?: string | null | undefined;
    venue_capacity?: number | null | undefined;
    lodging_star_level?: number | null | undefined;
    lodging_room_count?: string | null | undefined;
    non_commercial_sector?: string | null | undefined;
    education_enrollment?: number | null | undefined;
    education_grade_levels?: string | null | undefined;
    education_free_meals?: number | null | undefined;
    education_reduced_meals?: number | null | undefined;
    education_free_and_reduced_meals?: number | null | undefined;
    higher_education_students_on_mealplans?: number | null | undefined;
    hospital_type?: string | null | undefined;
    healthcare_number_of_beds?: number | null | undefined;
    healthcare_daily_patient_count?: number | null | undefined;
    date_last_review?: string | null | undefined;
    confidence_level?: string | null | undefined;
    business_detail_id?: string | null | undefined;
    business_general_id?: string | null | undefined;
    general_email?: string | null | undefined;
    offers_dine_in?: boolean | null | undefined;
    active_base_population?: number | null | undefined;
    host_number_of_units_location_within?: number | null | undefined;
    total_review_count?: number | null | undefined;
    total_average_review_rating?: any | null | undefined;
    tags?:
        | Array<
              | {
                    __typename?: 'OperatorTags';
                    code?: string | null | undefined;
                    key?: string | null | undefined;
                    type?: string | null | undefined;
                    description?: string | null | undefined;
                }
              | null
              | undefined
          >
        | null
        | undefined;
};

export type OperatorLocationDetailsFieldsFragment = {
    __typename?: 'OperatorLocationDetails';
    address_1?: string | null | undefined;
    address_2?: string | null | undefined;
    address_3?: string | null | undefined;
    administrative_area_1?: string | null | undefined;
    administrative_area_2?: string | null | undefined;
    administrative_area_3?: string | null | undefined;
    administrative_area_4?: string | null | undefined;
    alternative_area_1?: string | null | undefined;
    alternative_area_2?: string | null | undefined;
    area_type?: string | null | undefined;
    country?: string | null | undefined;
    locality_1?: string | null | undefined;
    geo_precision_level?: string | null | undefined;
    latitude?: number | null | undefined;
    longitude?: number | null | undefined;
    postal_code_1?: string | null | undefined;
    postal_code_2?: string | null | undefined;
    metro?:
        | { __typename?: 'OperatorMetro'; name?: string | null | undefined; population?: number | null | undefined }
        | null
        | undefined;
};

export type LinkFieldsFragment = {
    __typename?: 'OperatorHateoasLink';
    rel?: string | null | undefined;
    href?: any | null | undefined;
};

export type MetadataFieldsFragment = {
    __typename?: 'SearchMetadata';
    page?: number | null | undefined;
    size?: number | null | undefined;
    page_count?: number | null | undefined;
    total_count?: number | null | undefined;
};

export type SearchOperatorsQueryVariables = Exact<{
    filter?: InputMaybe<OperatorFilter>;
    pageNumber?: InputMaybe<Scalars['Int']['input']>;
    pageSize?: InputMaybe<Scalars['Int']['input']>;
    sort?: InputMaybe<Array<InputMaybe<Sort>> | InputMaybe<Sort>>;
    locale?: InputMaybe<Scalars['String']['input']>;
}>;

export type SearchOperatorsQuery = {
    __typename?: 'Query';
    search?:
        | {
              __typename?: 'OperatorSearch';
              operators?:
                  | Array<
                        | {
                              __typename?: 'OperatorSummary';
                              id?: string | null | undefined;
                              operator_identifier?: string | null | undefined;
                              entity_type?: string | null | undefined;
                              business_name?: string | null | undefined;
                              operator_status?: string | null | undefined;
                              phone?: string | null | undefined;
                              website?: string | null | undefined;
                              segment?: string | null | undefined;
                              cuisine?: string | null | undefined;
                              sub_cuisine?: string | null | undefined;
                              sub_channel?: string | null | undefined;
                              date_founded?: any | null | undefined;
                              number_of_units?: number | null | undefined;
                              est_meals_per_day?: number | null | undefined;
                              annual_sales?: any | null | undefined;
                              parent_chain?:
                                  | {
                                        __typename?: 'OperatorChainParent';
                                        id?: string | null | undefined;
                                        business_name?: string | null | undefined;
                                        number_of_units?: number | null | undefined;
                                        operator_identifier?: string | null | undefined;
                                    }
                                  | null
                                  | undefined;
                              location?:
                                  | {
                                        __typename?: 'OperatorLocationSummary';
                                        country?: string | null | undefined;
                                        area_type?: string | null | undefined;
                                        address_1?: string | null | undefined;
                                        address_2?: string | null | undefined;
                                        locality_1?: string | null | undefined;
                                        administrative_area_1?: string | null | undefined;
                                        postal_code_1?: string | null | undefined;
                                    }
                                  | null
                                  | undefined;
                              company_data?:
                                  | { __typename?: 'CompanyData'; status?: string | null | undefined }
                                  | null
                                  | undefined;
                          }
                        | null
                        | undefined
                    >
                  | null
                  | undefined;
              _metadata?:
                  | {
                        __typename?: 'SearchMetadata';
                        page?: number | null | undefined;
                        size?: number | null | undefined;
                        page_count?: number | null | undefined;
                        total_count?: number | null | undefined;
                    }
                  | null
                  | undefined;
          }
        | null
        | undefined;
};

export type CountOperatorsQueryVariables = Exact<{
    filter: OperatorFilter;
}>;

export type CountOperatorsQuery = { __typename?: 'Query'; count?: number | null | undefined };

export type GetOperatorQueryVariables = Exact<{
    operatorIdentifier: Scalars['ID']['input'];
    locale?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetOperatorQuery = {
    __typename?: 'Query';
    get?:
        | {
              __typename?: 'OperatorDetails';
              operating_hours_raw?: string | null | undefined;
              popularity_score?: number | null | undefined;
              id?: string | null | undefined;
              operator_identifier?: string | null | undefined;
              entity_type?: string | null | undefined;
              business_name?: string | null | undefined;
              operator_status?: string | null | undefined;
              phone?: string | null | undefined;
              website?: string | null | undefined;
              segment?: string | null | undefined;
              segment_code?: string | null | undefined;
              cuisine?: string | null | undefined;
              sub_channel?: string | null | undefined;
              date_founded?: any | null | undefined;
              number_of_units?: number | null | undefined;
              est_meals_per_day?: number | null | undefined;
              annual_sales?: any | null | undefined;
              purchase_potential_sum_total?: number | null | undefined;
              sub_cuisine?: string | null | undefined;
              channel?: string | null | undefined;
              offers_breakfast?: boolean | null | undefined;
              offers_lunch?: boolean | null | undefined;
              offers_dinner?: boolean | null | undefined;
              offers_takeout?: boolean | null | undefined;
              offers_delivery?: boolean | null | undefined;
              offers_drive_thru?: boolean | null | undefined;
              offers_catering?: boolean | null | undefined;
              offers_reservations?: boolean | null | undefined;
              offers_kids_menu?: boolean | null | undefined;
              offers_eatertainment?: boolean | null | undefined;
              offers_banquet_facility?: boolean | null | undefined;
              offers_complimentary_breakfast?: boolean | null | undefined;
              offers_meeting_rooms?: boolean | null | undefined;
              offers_room_service?: boolean | null | undefined;
              offers_cafeteria?: boolean | null | undefined;
              offers_higher_education_housing?: boolean | null | undefined;
              offers_fuel?: boolean | null | undefined;
              offers_alcohol?: boolean | null | undefined;
              alcohol_service_type?: string | null | undefined;
              venue_capacity?: number | null | undefined;
              lodging_star_level?: number | null | undefined;
              lodging_room_count?: string | null | undefined;
              non_commercial_sector?: string | null | undefined;
              education_enrollment?: number | null | undefined;
              education_grade_levels?: string | null | undefined;
              education_free_meals?: number | null | undefined;
              education_reduced_meals?: number | null | undefined;
              education_free_and_reduced_meals?: number | null | undefined;
              higher_education_students_on_mealplans?: number | null | undefined;
              hospital_type?: string | null | undefined;
              healthcare_number_of_beds?: number | null | undefined;
              healthcare_daily_patient_count?: number | null | undefined;
              date_last_review?: string | null | undefined;
              confidence_level?: string | null | undefined;
              business_detail_id?: string | null | undefined;
              business_general_id?: string | null | undefined;
              general_email?: string | null | undefined;
              offers_dine_in?: boolean | null | undefined;
              active_base_population?: number | null | undefined;
              host_number_of_units_location_within?: number | null | undefined;
              total_review_count?: number | null | undefined;
              total_average_review_rating?: any | null | undefined;
              parent_chain?:
                  | {
                        __typename?: 'OperatorChainParent';
                        id?: string | null | undefined;
                        business_name?: string | null | undefined;
                        number_of_units?: number | null | undefined;
                        operator_identifier?: string | null | undefined;
                    }
                  | null
                  | undefined;
              host?:
                  | {
                        __typename?: 'OperatorHost';
                        id?: string | null | undefined;
                        operator_identifier?: string | null | undefined;
                        business_name?: string | null | undefined;
                    }
                  | null
                  | undefined;
              location?:
                  | {
                        __typename?: 'OperatorLocationDetails';
                        address_1?: string | null | undefined;
                        address_2?: string | null | undefined;
                        address_3?: string | null | undefined;
                        administrative_area_1?: string | null | undefined;
                        administrative_area_2?: string | null | undefined;
                        administrative_area_3?: string | null | undefined;
                        administrative_area_4?: string | null | undefined;
                        alternative_area_1?: string | null | undefined;
                        alternative_area_2?: string | null | undefined;
                        area_type?: string | null | undefined;
                        country?: string | null | undefined;
                        locality_1?: string | null | undefined;
                        geo_precision_level?: string | null | undefined;
                        latitude?: number | null | undefined;
                        longitude?: number | null | undefined;
                        postal_code_1?: string | null | undefined;
                        postal_code_2?: string | null | undefined;
                        metro?:
                            | {
                                  __typename?: 'OperatorMetro';
                                  name?: string | null | undefined;
                                  population?: number | null | undefined;
                              }
                            | null
                            | undefined;
                    }
                  | null
                  | undefined;
              external_ids?:
                  | Array<
                        | {
                              __typename?: 'OperatorExternalId';
                              type?: string | null | undefined;
                              value?: string | null | undefined;
                          }
                        | null
                        | undefined
                    >
                  | null
                  | undefined;
              ranges?:
                  | Array<
                        | {
                              __typename?: 'MetadataRange';
                              display_max?: any | null | undefined;
                              display_min?: any | null | undefined;
                              type?: string | null | undefined;
                              unified_code?: string | null | undefined;
                          }
                        | null
                        | undefined
                    >
                  | null
                  | undefined;
              social_media?:
                  | Array<
                        | {
                              __typename?: 'OperatorSocialMedia';
                              type?: string | null | undefined;
                              url?: string | null | undefined;
                              average_rating?: number | null | undefined;
                              total_reviews?: number | null | undefined;
                          }
                        | null
                        | undefined
                    >
                  | null
                  | undefined;
              delivery?:
                  | Array<
                        | {
                              __typename?: 'OperatorDelivery';
                              type?: string | null | undefined;
                              url?: string | null | undefined;
                          }
                        | null
                        | undefined
                    >
                  | null
                  | undefined;
              purchase_potential?:
                  | Array<
                        | {
                              __typename?: 'OperatorPurchasePotential';
                              description?: string | null | undefined;
                              message_code?: string | null | undefined;
                              name?: string | null | undefined;
                              value?: number | null | undefined;
                          }
                        | null
                        | undefined
                    >
                  | null
                  | undefined;
              company_data?: { __typename?: 'CompanyData'; status?: string | null | undefined } | null | undefined;
              tags?:
                  | Array<
                        | {
                              __typename?: 'OperatorTags';
                              code?: string | null | undefined;
                              key?: string | null | undefined;
                              type?: string | null | undefined;
                              description?: string | null | undefined;
                          }
                        | null
                        | undefined
                    >
                  | null
                  | undefined;
          }
        | null
        | undefined;
};

export type GetOperatorTooltipDataQueryVariables = Exact<{
    operatorIdentifier: Scalars['ID']['input'];
    locale?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetOperatorTooltipDataQuery = {
    __typename?: 'Query';
    get?:
        | {
              __typename?: 'OperatorDetails';
              id?: string | null | undefined;
              operator_identifier?: string | null | undefined;
              entity_type?: string | null | undefined;
              business_name?: string | null | undefined;
              operator_status?: string | null | undefined;
              phone?: string | null | undefined;
              website?: string | null | undefined;
              segment?: string | null | undefined;
              segment_code?: string | null | undefined;
              cuisine?: string | null | undefined;
              sub_channel?: string | null | undefined;
              date_founded?: any | null | undefined;
              number_of_units?: number | null | undefined;
              est_meals_per_day?: number | null | undefined;
              annual_sales?: any | null | undefined;
              purchase_potential_sum_total?: number | null | undefined;
              sub_cuisine?: string | null | undefined;
              channel?: string | null | undefined;
              offers_breakfast?: boolean | null | undefined;
              offers_lunch?: boolean | null | undefined;
              offers_dinner?: boolean | null | undefined;
              offers_takeout?: boolean | null | undefined;
              offers_delivery?: boolean | null | undefined;
              offers_drive_thru?: boolean | null | undefined;
              offers_catering?: boolean | null | undefined;
              offers_reservations?: boolean | null | undefined;
              offers_kids_menu?: boolean | null | undefined;
              offers_eatertainment?: boolean | null | undefined;
              offers_banquet_facility?: boolean | null | undefined;
              offers_complimentary_breakfast?: boolean | null | undefined;
              offers_meeting_rooms?: boolean | null | undefined;
              offers_room_service?: boolean | null | undefined;
              offers_cafeteria?: boolean | null | undefined;
              offers_higher_education_housing?: boolean | null | undefined;
              offers_fuel?: boolean | null | undefined;
              offers_alcohol?: boolean | null | undefined;
              alcohol_service_type?: string | null | undefined;
              venue_capacity?: number | null | undefined;
              lodging_star_level?: number | null | undefined;
              lodging_room_count?: string | null | undefined;
              non_commercial_sector?: string | null | undefined;
              education_enrollment?: number | null | undefined;
              education_grade_levels?: string | null | undefined;
              education_free_meals?: number | null | undefined;
              education_reduced_meals?: number | null | undefined;
              education_free_and_reduced_meals?: number | null | undefined;
              higher_education_students_on_mealplans?: number | null | undefined;
              hospital_type?: string | null | undefined;
              healthcare_number_of_beds?: number | null | undefined;
              healthcare_daily_patient_count?: number | null | undefined;
              date_last_review?: string | null | undefined;
              confidence_level?: string | null | undefined;
              business_detail_id?: string | null | undefined;
              business_general_id?: string | null | undefined;
              general_email?: string | null | undefined;
              offers_dine_in?: boolean | null | undefined;
              active_base_population?: number | null | undefined;
              host_number_of_units_location_within?: number | null | undefined;
              total_review_count?: number | null | undefined;
              total_average_review_rating?: any | null | undefined;
              parent_chain?:
                  | {
                        __typename?: 'OperatorChainParent';
                        id?: string | null | undefined;
                        business_name?: string | null | undefined;
                        number_of_units?: number | null | undefined;
                        operator_identifier?: string | null | undefined;
                    }
                  | null
                  | undefined;
              location?:
                  | {
                        __typename?: 'OperatorLocationDetails';
                        address_1?: string | null | undefined;
                        address_2?: string | null | undefined;
                        address_3?: string | null | undefined;
                        administrative_area_1?: string | null | undefined;
                        administrative_area_2?: string | null | undefined;
                        administrative_area_3?: string | null | undefined;
                        administrative_area_4?: string | null | undefined;
                        alternative_area_1?: string | null | undefined;
                        alternative_area_2?: string | null | undefined;
                        area_type?: string | null | undefined;
                        country?: string | null | undefined;
                        locality_1?: string | null | undefined;
                        geo_precision_level?: string | null | undefined;
                        latitude?: number | null | undefined;
                        longitude?: number | null | undefined;
                        postal_code_1?: string | null | undefined;
                        postal_code_2?: string | null | undefined;
                        metro?:
                            | {
                                  __typename?: 'OperatorMetro';
                                  name?: string | null | undefined;
                                  population?: number | null | undefined;
                              }
                            | null
                            | undefined;
                    }
                  | null
                  | undefined;
              tags?:
                  | Array<
                        | {
                              __typename?: 'OperatorTags';
                              code?: string | null | undefined;
                              key?: string | null | undefined;
                              type?: string | null | undefined;
                              description?: string | null | undefined;
                          }
                        | null
                        | undefined
                    >
                  | null
                  | undefined;
          }
        | null
        | undefined;
};

export type QuickSearchQueryVariables = Exact<{
    pageNumber?: InputMaybe<Scalars['Int']['input']>;
    pageSize?: InputMaybe<Scalars['Int']['input']>;
    locale?: InputMaybe<Scalars['String']['input']>;
    searchString?: InputMaybe<Scalars['String']['input']>;
    country?: InputMaybe<Scalars['String']['input']>;
    type?: InputMaybe<Array<InputMaybe<OperatorType>> | InputMaybe<OperatorType>>;
}>;

export type QuickSearchQuery = {
    __typename?: 'Query';
    quick_search?:
        | {
              __typename?: 'OperatorQuickSearch';
              operators?:
                  | Array<
                        | {
                              __typename?: 'OperatorSummary';
                              id?: string | null | undefined;
                              operator_identifier?: string | null | undefined;
                              entity_type?: string | null | undefined;
                              business_name?: string | null | undefined;
                              operator_status?: string | null | undefined;
                              phone?: string | null | undefined;
                              website?: string | null | undefined;
                              segment?: string | null | undefined;
                              cuisine?: string | null | undefined;
                              sub_cuisine?: string | null | undefined;
                              sub_channel?: string | null | undefined;
                              date_founded?: any | null | undefined;
                              number_of_units?: number | null | undefined;
                              est_meals_per_day?: number | null | undefined;
                              annual_sales?: any | null | undefined;
                              location?:
                                  | {
                                        __typename?: 'OperatorLocationSummary';
                                        country?: string | null | undefined;
                                        area_type?: string | null | undefined;
                                        address_1?: string | null | undefined;
                                        address_2?: string | null | undefined;
                                        locality_1?: string | null | undefined;
                                        administrative_area_1?: string | null | undefined;
                                        postal_code_1?: string | null | undefined;
                                    }
                                  | null
                                  | undefined;
                          }
                        | null
                        | undefined
                    >
                  | null
                  | undefined;
              _metadata?:
                  | {
                        __typename?: 'SearchMetadata';
                        page?: number | null | undefined;
                        size?: number | null | undefined;
                        page_count?: number | null | undefined;
                        total_count?: number | null | undefined;
                    }
                  | null
                  | undefined;
              _links?:
                  | Array<
                        | {
                              __typename?: 'OperatorHateoasLink';
                              rel?: string | null | undefined;
                              href?: any | null | undefined;
                          }
                        | null
                        | undefined
                    >
                  | null
                  | undefined;
          }
        | null
        | undefined;
};

export type GetAggregationsQueryVariables = Exact<{
    filter?: InputMaybe<OperatorFilter>;
}>;

export type GetAggregationsQuery = {
    __typename?: 'Query';
    aggregations?:
        | {
              __typename?: 'OperatorSearchAggregation';
              independent_vs_chain?:
                  | Array<
                        | {
                              __typename?: 'AggregationCount';
                              key?: string | null | undefined;
                              count?: any | null | undefined;
                          }
                        | null
                        | undefined
                    >
                  | null
                  | undefined;
              cuisine?:
                  | Array<
                        | {
                              __typename?: 'AggregationCount';
                              key?: string | null | undefined;
                              count?: any | null | undefined;
                          }
                        | null
                        | undefined
                    >
                  | null
                  | undefined;
              entity_type?:
                  | Array<
                        | {
                              __typename?: 'AggregationCount';
                              key?: string | null | undefined;
                              count?: any | null | undefined;
                          }
                        | null
                        | undefined
                    >
                  | null
                  | undefined;
              confidence_level?:
                  | Array<
                        | {
                              __typename?: 'AggregationCount';
                              key?: string | null | undefined;
                              count?: any | null | undefined;
                          }
                        | null
                        | undefined
                    >
                  | null
                  | undefined;
              ranges?:
                  | Array<
                        | {
                              __typename?: 'RangeAggregationCount';
                              description?: string | null | undefined;
                              elements?:
                                  | Array<
                                        | {
                                              __typename?: 'AggregationCount';
                                              key?: string | null | undefined;
                                              count?: any | null | undefined;
                                          }
                                        | null
                                        | undefined
                                    >
                                  | null
                                  | undefined;
                          }
                        | null
                        | undefined
                    >
                  | null
                  | undefined;
              segments?:
                  | Array<
                        | {
                              __typename?: 'AggregationCount';
                              key?: string | null | undefined;
                              count?: any | null | undefined;
                              description?: string | null | undefined;
                              children?:
                                  | Array<
                                        | {
                                              __typename?: 'AggregationCount';
                                              key?: string | null | undefined;
                                              count?: any | null | undefined;
                                              description?: string | null | undefined;
                                              children?:
                                                  | Array<
                                                        | {
                                                              __typename?: 'AggregationCount';
                                                              key?: string | null | undefined;
                                                              count?: any | null | undefined;
                                                              description?: string | null | undefined;
                                                          }
                                                        | null
                                                        | undefined
                                                    >
                                                  | null
                                                  | undefined;
                                          }
                                        | null
                                        | undefined
                                    >
                                  | null
                                  | undefined;
                          }
                        | null
                        | undefined
                    >
                  | null
                  | undefined;
          }
        | null
        | undefined;
};

export type GetUserFieldPermissionsQueryVariables = Exact<{
    filter: OperatorFilter;
}>;

export type GetUserFieldPermissionsQuery = {
    __typename?: 'Query';
    get_user_field_permissions?:
        | {
              __typename?: 'UserFieldPermissions';
              has_data?: boolean | null | undefined;
              has_permission?: boolean | null | undefined;
          }
        | null
        | undefined;
};

export const BasicOperatorFieldsFragmentDoc = gql`
    fragment BasicOperatorFields on OperatorSummary {
        id
        operator_identifier
        entity_type
        business_name
        operator_status
        phone
        website
        segment
        cuisine
        sub_cuisine
        sub_channel
        date_founded
        number_of_units
        est_meals_per_day
        annual_sales
    }
`;
export const OperatorChainParentFieldsFragmentDoc = gql`
    fragment OperatorChainParentFields on OperatorChainParent {
        id
        business_name
        number_of_units
        operator_identifier
    }
`;
export const OperatorExternalIdFieldsFragmentDoc = gql`
    fragment OperatorExternalIdFields on OperatorExternalId {
        type
        value
    }
`;
export const OperatorHostFieldsFragmentDoc = gql`
    fragment OperatorHostFields on OperatorHost {
        id
        operator_identifier
        business_name
    }
`;
export const OperatorLocationFieldsFragmentDoc = gql`
    fragment OperatorLocationFields on OperatorLocationSummary {
        country
        area_type
        address_1
        address_2
        locality_1
        administrative_area_1
        postal_code_1
    }
`;
export const MetadataRangeFieldsFragmentDoc = gql`
    fragment MetadataRangeFields on MetadataRange {
        display_max
        display_min
        type
        unified_code
    }
`;
export const OperatorSocialMediaFieldsFragmentDoc = gql`
    fragment OperatorSocialMediaFields on OperatorSocialMedia {
        type
        url
        average_rating
        total_reviews
    }
`;
export const OperatorDeliveryFieldsFragmentDoc = gql`
    fragment OperatorDeliveryFields on OperatorDelivery {
        type
        url
    }
`;
export const OperatorPurchasePotentialFieldsFragmentDoc = gql`
    fragment OperatorPurchasePotentialFields on OperatorPurchasePotential {
        description
        message_code
        name
        value
    }
`;
export const BasicOperatorDetailsFieldsFragmentDoc = gql`
    fragment BasicOperatorDetailsFields on OperatorDetails {
        id
        operator_identifier
        entity_type
        business_name
        operator_status
        phone
        website
        segment
        segment_code
        cuisine
        sub_channel
        date_founded
        number_of_units
        est_meals_per_day
        annual_sales
        purchase_potential_sum_total
        sub_cuisine
        channel
        offers_breakfast
        offers_lunch
        offers_dinner
        offers_takeout
        offers_delivery
        offers_drive_thru
        offers_catering
        offers_reservations
        offers_kids_menu
        offers_eatertainment
        offers_banquet_facility
        offers_complimentary_breakfast
        offers_meeting_rooms
        offers_room_service
        offers_cafeteria
        offers_higher_education_housing
        offers_fuel
        offers_alcohol
        alcohol_service_type
        venue_capacity
        lodging_star_level
        lodging_room_count
        non_commercial_sector
        education_enrollment
        education_grade_levels
        education_free_meals
        education_reduced_meals
        education_free_and_reduced_meals
        higher_education_students_on_mealplans
        hospital_type
        healthcare_number_of_beds
        healthcare_daily_patient_count
        date_last_review
        confidence_level
        business_detail_id
        business_general_id
        general_email
        offers_dine_in
        active_base_population
        host_number_of_units_location_within
        total_review_count
        total_average_review_rating
        tags {
            code
            key
            type
            description
        }
    }
`;
export const OperatorLocationDetailsFieldsFragmentDoc = gql`
    fragment OperatorLocationDetailsFields on OperatorLocationDetails {
        address_1
        address_2
        address_3
        administrative_area_1
        administrative_area_2
        administrative_area_3
        administrative_area_4
        alternative_area_1
        alternative_area_2
        area_type
        country
        locality_1
        geo_precision_level
        latitude
        longitude
        postal_code_1
        postal_code_2
        metro {
            name
            population
        }
    }
`;
export const LinkFieldsFragmentDoc = gql`
    fragment LinkFields on OperatorHateoasLink {
        rel
        href
    }
`;
export const MetadataFieldsFragmentDoc = gql`
    fragment MetadataFields on SearchMetadata {
        page
        size
        page_count
        total_count
    }
`;
export const SearchOperatorsDocument = gql`
    query searchOperators(
        $filter: OperatorFilter = {}
        $pageNumber: Int = 1
        $pageSize: Int = 50
        $sort: [Sort] = []
        $locale: String = "en_US"
    ) {
        search(filter: $filter, pageNumber: $pageNumber, pageSize: $pageSize, sort: $sort, locale: $locale) {
            operators {
                ...BasicOperatorFields
                parent_chain {
                    ...OperatorChainParentFields
                }
                location {
                    ...OperatorLocationFields
                }
                company_data {
                    status
                }
            }
            _metadata {
                ...MetadataFields
            }
        }
    }
    ${BasicOperatorFieldsFragmentDoc}
    ${OperatorChainParentFieldsFragmentDoc}
    ${OperatorLocationFieldsFragmentDoc}
    ${MetadataFieldsFragmentDoc}
`;

/**
 * __useSearchOperatorsQuery__
 *
 * To run a query within a React component, call `useSearchOperatorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchOperatorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchOperatorsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      pageNumber: // value for 'pageNumber'
 *      pageSize: // value for 'pageSize'
 *      sort: // value for 'sort'
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useSearchOperatorsQuery(
    baseOptions?: Apollo.QueryHookOptions<SearchOperatorsQuery, SearchOperatorsQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<SearchOperatorsQuery, SearchOperatorsQueryVariables>(SearchOperatorsDocument, options);
}
export function useSearchOperatorsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<SearchOperatorsQuery, SearchOperatorsQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<SearchOperatorsQuery, SearchOperatorsQueryVariables>(SearchOperatorsDocument, options);
}
export type SearchOperatorsQueryHookResult = ReturnType<typeof useSearchOperatorsQuery>;
export type SearchOperatorsLazyQueryHookResult = ReturnType<typeof useSearchOperatorsLazyQuery>;
export type SearchOperatorsQueryResult = Apollo.QueryResult<SearchOperatorsQuery, SearchOperatorsQueryVariables>;
export const CountOperatorsDocument = gql`
    query countOperators($filter: OperatorFilter!) {
        count(filter: $filter)
    }
`;

/**
 * __useCountOperatorsQuery__
 *
 * To run a query within a React component, call `useCountOperatorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountOperatorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountOperatorsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useCountOperatorsQuery(
    baseOptions: Apollo.QueryHookOptions<CountOperatorsQuery, CountOperatorsQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<CountOperatorsQuery, CountOperatorsQueryVariables>(CountOperatorsDocument, options);
}
export function useCountOperatorsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<CountOperatorsQuery, CountOperatorsQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<CountOperatorsQuery, CountOperatorsQueryVariables>(CountOperatorsDocument, options);
}
export type CountOperatorsQueryHookResult = ReturnType<typeof useCountOperatorsQuery>;
export type CountOperatorsLazyQueryHookResult = ReturnType<typeof useCountOperatorsLazyQuery>;
export type CountOperatorsQueryResult = Apollo.QueryResult<CountOperatorsQuery, CountOperatorsQueryVariables>;
export const GetOperatorDocument = gql`
    query getOperator($operatorIdentifier: ID!, $locale: String = "en_US") {
        get(operatorIdentifier: $operatorIdentifier, locale: $locale) {
            ...BasicOperatorDetailsFields
            operating_hours_raw
            popularity_score
            parent_chain {
                ...OperatorChainParentFields
            }
            host {
                ...OperatorHostFields
            }
            location {
                ...OperatorLocationDetailsFields
            }
            external_ids {
                ...OperatorExternalIdFields
            }
            ranges {
                ...MetadataRangeFields
            }
            social_media {
                ...OperatorSocialMediaFields
            }
            delivery {
                ...OperatorDeliveryFields
            }
            purchase_potential {
                ...OperatorPurchasePotentialFields
            }
            company_data {
                status
            }
        }
    }
    ${BasicOperatorDetailsFieldsFragmentDoc}
    ${OperatorChainParentFieldsFragmentDoc}
    ${OperatorHostFieldsFragmentDoc}
    ${OperatorLocationDetailsFieldsFragmentDoc}
    ${OperatorExternalIdFieldsFragmentDoc}
    ${MetadataRangeFieldsFragmentDoc}
    ${OperatorSocialMediaFieldsFragmentDoc}
    ${OperatorDeliveryFieldsFragmentDoc}
    ${OperatorPurchasePotentialFieldsFragmentDoc}
`;

/**
 * __useGetOperatorQuery__
 *
 * To run a query within a React component, call `useGetOperatorQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOperatorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOperatorQuery({
 *   variables: {
 *      operatorIdentifier: // value for 'operatorIdentifier'
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useGetOperatorQuery(baseOptions: Apollo.QueryHookOptions<GetOperatorQuery, GetOperatorQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GetOperatorQuery, GetOperatorQueryVariables>(GetOperatorDocument, options);
}
export function useGetOperatorLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<GetOperatorQuery, GetOperatorQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GetOperatorQuery, GetOperatorQueryVariables>(GetOperatorDocument, options);
}
export type GetOperatorQueryHookResult = ReturnType<typeof useGetOperatorQuery>;
export type GetOperatorLazyQueryHookResult = ReturnType<typeof useGetOperatorLazyQuery>;
export type GetOperatorQueryResult = Apollo.QueryResult<GetOperatorQuery, GetOperatorQueryVariables>;
export const GetOperatorTooltipDataDocument = gql`
    query getOperatorTooltipData($operatorIdentifier: ID!, $locale: String = "en_US") {
        get(operatorIdentifier: $operatorIdentifier, locale: $locale) {
            ...BasicOperatorDetailsFields
            parent_chain {
                ...OperatorChainParentFields
            }
            location {
                ...OperatorLocationDetailsFields
            }
        }
    }
    ${BasicOperatorDetailsFieldsFragmentDoc}
    ${OperatorChainParentFieldsFragmentDoc}
    ${OperatorLocationDetailsFieldsFragmentDoc}
`;

/**
 * __useGetOperatorTooltipDataQuery__
 *
 * To run a query within a React component, call `useGetOperatorTooltipDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOperatorTooltipDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOperatorTooltipDataQuery({
 *   variables: {
 *      operatorIdentifier: // value for 'operatorIdentifier'
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useGetOperatorTooltipDataQuery(
    baseOptions: Apollo.QueryHookOptions<GetOperatorTooltipDataQuery, GetOperatorTooltipDataQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GetOperatorTooltipDataQuery, GetOperatorTooltipDataQueryVariables>(
        GetOperatorTooltipDataDocument,
        options,
    );
}
export function useGetOperatorTooltipDataLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<GetOperatorTooltipDataQuery, GetOperatorTooltipDataQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GetOperatorTooltipDataQuery, GetOperatorTooltipDataQueryVariables>(
        GetOperatorTooltipDataDocument,
        options,
    );
}
export type GetOperatorTooltipDataQueryHookResult = ReturnType<typeof useGetOperatorTooltipDataQuery>;
export type GetOperatorTooltipDataLazyQueryHookResult = ReturnType<typeof useGetOperatorTooltipDataLazyQuery>;
export type GetOperatorTooltipDataQueryResult = Apollo.QueryResult<
    GetOperatorTooltipDataQuery,
    GetOperatorTooltipDataQueryVariables
>;
export const QuickSearchDocument = gql`
    query quickSearch(
        $pageNumber: Int = 1
        $pageSize: Int = 50
        $locale: String = "en_US"
        $searchString: String
        $country: String
        $type: [OperatorType]
    ) {
        quick_search(
            pageNumber: $pageNumber
            pageSize: $pageSize
            locale: $locale
            filter: {
                and: [{ name: { match_similarity: $searchString } }, { country: { equals: $country } }, { type: $type }]
            }
        ) {
            operators {
                ...BasicOperatorFields
                location {
                    ...OperatorLocationFields
                }
            }
            _metadata {
                ...MetadataFields
            }
            _links {
                ...LinkFields
            }
        }
    }
    ${BasicOperatorFieldsFragmentDoc}
    ${OperatorLocationFieldsFragmentDoc}
    ${MetadataFieldsFragmentDoc}
    ${LinkFieldsFragmentDoc}
`;

/**
 * __useQuickSearchQuery__
 *
 * To run a query within a React component, call `useQuickSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuickSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuickSearchQuery({
 *   variables: {
 *      pageNumber: // value for 'pageNumber'
 *      pageSize: // value for 'pageSize'
 *      locale: // value for 'locale'
 *      searchString: // value for 'searchString'
 *      country: // value for 'country'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useQuickSearchQuery(
    baseOptions?: Apollo.QueryHookOptions<QuickSearchQuery, QuickSearchQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<QuickSearchQuery, QuickSearchQueryVariables>(QuickSearchDocument, options);
}
export function useQuickSearchLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<QuickSearchQuery, QuickSearchQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<QuickSearchQuery, QuickSearchQueryVariables>(QuickSearchDocument, options);
}
export type QuickSearchQueryHookResult = ReturnType<typeof useQuickSearchQuery>;
export type QuickSearchLazyQueryHookResult = ReturnType<typeof useQuickSearchLazyQuery>;
export type QuickSearchQueryResult = Apollo.QueryResult<QuickSearchQuery, QuickSearchQueryVariables>;
export const GetAggregationsDocument = gql`
    query getAggregations($filter: OperatorFilter) {
        aggregations: aggregation(filter: $filter) {
            independent_vs_chain {
                key
                count
            }
            cuisine {
                key
                count
            }
            entity_type {
                key
                count
            }
            confidence_level {
                key
                count
            }
            ranges {
                description
                elements {
                    key
                    count
                }
            }
            segments {
                key
                count
                description
                children {
                    key
                    count
                    description
                    children {
                        key
                        count
                        description
                    }
                }
            }
        }
    }
`;

/**
 * __useGetAggregationsQuery__
 *
 * To run a query within a React component, call `useGetAggregationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAggregationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAggregationsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetAggregationsQuery(
    baseOptions?: Apollo.QueryHookOptions<GetAggregationsQuery, GetAggregationsQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GetAggregationsQuery, GetAggregationsQueryVariables>(GetAggregationsDocument, options);
}
export function useGetAggregationsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<GetAggregationsQuery, GetAggregationsQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GetAggregationsQuery, GetAggregationsQueryVariables>(GetAggregationsDocument, options);
}
export type GetAggregationsQueryHookResult = ReturnType<typeof useGetAggregationsQuery>;
export type GetAggregationsLazyQueryHookResult = ReturnType<typeof useGetAggregationsLazyQuery>;
export type GetAggregationsQueryResult = Apollo.QueryResult<GetAggregationsQuery, GetAggregationsQueryVariables>;
export const GetUserFieldPermissionsDocument = gql`
    query getUserFieldPermissions($filter: OperatorFilter!) {
        get_user_field_permissions(filter: $filter) {
            has_data
            has_permission
        }
    }
`;

/**
 * __useGetUserFieldPermissionsQuery__
 *
 * To run a query within a React component, call `useGetUserFieldPermissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserFieldPermissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserFieldPermissionsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetUserFieldPermissionsQuery(
    baseOptions: Apollo.QueryHookOptions<GetUserFieldPermissionsQuery, GetUserFieldPermissionsQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GetUserFieldPermissionsQuery, GetUserFieldPermissionsQueryVariables>(
        GetUserFieldPermissionsDocument,
        options,
    );
}
export function useGetUserFieldPermissionsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<GetUserFieldPermissionsQuery, GetUserFieldPermissionsQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GetUserFieldPermissionsQuery, GetUserFieldPermissionsQueryVariables>(
        GetUserFieldPermissionsDocument,
        options,
    );
}
export type GetUserFieldPermissionsQueryHookResult = ReturnType<typeof useGetUserFieldPermissionsQuery>;
export type GetUserFieldPermissionsLazyQueryHookResult = ReturnType<typeof useGetUserFieldPermissionsLazyQuery>;
export type GetUserFieldPermissionsQueryResult = Apollo.QueryResult<
    GetUserFieldPermissionsQuery,
    GetUserFieldPermissionsQueryVariables
>;
