import { ReactNode } from 'react';

import {
    IconFilterClient,
    IconFilterGeneral,
    IconFilterLocation,
    IconFilterLodging,
    IconFilterRestaurants,
    IconFilterSegments,
    IconFilterTags,
} from '@assets/icons';
import { ID } from '@models/index';
import { FilterTypeEnum } from '@core/enums/localeEnum';
import { GradientColorsEnum } from '@enums/gradientEnum';
import { toggleObjectInArrayByKey } from '@core/helpers/helpers';

export type Criterion = {
    id: ID;
    name: FilterTypeEnum;
    baseColor: GradientColorsEnum;
    CriterionParams: CriterionParam[];
};

export type CriterionParam = {
    value: ID;
    name: string;
    filterKey: CriterionParamFilter;
    description?: string;
    isPermanent?: boolean;
    additionalData?: Record<string, unknown>;
};

export enum CriterionParamFilter {
    Country = 'country',
    AdministrativeArea1 = 'administrative_area_1',
    AdministrativeArea2 = 'administrative_area_2',
    Segment = 'segmentation',
    Cuisine = 'cuisine',
    PostalCode = 'postal_code',
    ChainSizeRange = 'chain_size_range',
    AnnualSalesRange = 'annual_sales_range',
    EstMealsPerDayRange = 'est_meals_per_day_range',
    YearsInBusinessRange = 'founded_date',
    NumberOfEmployeesRangeCommercial = 'number_of_employees_range_comercial',
    NumberOfEmployeesRangeNonCommercial = 'number_of_employees_range_noncomercial',
    ConfidenceLevel = 'confidence_level',
    GeoLocation = 'geo_location',
    PotentialPurchaseTotal = 'purchase_potential_total',
    NumberOfRoomsRange = 'number_of_rooms_range',
    HotelStarLevel = 'lodging_star_level',
    FoodTags = 'food_tags',
    PlaceTags = 'place_tags',
    AverageCheckRange = 'average_check_range',
    CustomerStatus = 'customer_status',
    Territories = 'territories',
    MenuIncludes = 'menu_includes',
    MenuExcludes = 'menu_excludes',
}

export enum CriterionEnum {
    Location = 'Location',
    Segments = 'Segments',
    CuisineTypes = 'CuisineTypes',
    Tags = 'Tags',
    General = 'General',
    LodgingRecreation = 'LodgingRecreation',
    ClientFilters = 'ClientFilters',
}

export enum NoEmployeesCountDataEnum {
    Commercial = 'NoDataCommercial',
    NonCommercial = 'NoDataNonCommercial',
}

export type CriteriaData = {
    [key in CriterionEnum]: Criterion;
};

export function getCriterionEnumIcon(criterionEnum: CriterionEnum): ReactNode {
    switch (criterionEnum) {
        case CriterionEnum.Location:
            return <IconFilterLocation />;
        case CriterionEnum.Segments:
            return <IconFilterSegments />;
        case CriterionEnum.CuisineTypes:
            return <IconFilterRestaurants />;
        case CriterionEnum.Tags:
            return <IconFilterTags />;
        case CriterionEnum.General:
            return <IconFilterGeneral />;
        case CriterionEnum.LodgingRecreation:
            return <IconFilterLodging />;
        case CriterionEnum.ClientFilters:
            return <IconFilterClient />;
    }
}

export class CriterionParamsManager {
    static updateCriterionParams(
        criterionParams: CriterionParam[],
        newCriterionParam: CriterionParam,
    ): CriterionParam[] {
        const handleNewCriterionParam =
            this.criterionParamHandlers[newCriterionParam.filterKey] || this.toggleCriterionParam;

        return handleNewCriterionParam(criterionParams, newCriterionParam);
    }

    static criterionParamHandlers: Partial<
        Record<CriterionParamFilter, (paramsList: CriterionParam[], newParam: CriterionParam) => CriterionParam[]>
    > = {
            [CriterionParamFilter.Country]: this.countryMapper,
            [CriterionParamFilter.GeoLocation]: this.replaceOrAddCriterionParam,
            [CriterionParamFilter.MenuIncludes]: this.addUniqueCriterionParam,
            [CriterionParamFilter.MenuExcludes]: this.addUniqueCriterionParam,
        };

    static toggleCriterionParam(paramsList: CriterionParam[], newParam: CriterionParam) {
        const updatedParamsList = toggleObjectInArrayByKey<CriterionParam>(paramsList, newParam, 'value');

        return updatedParamsList;
    }

    static addUniqueCriterionParam(paramsList: CriterionParam[], newParam: CriterionParam) {
        if (paramsList.some((param) => param.value === newParam.value)) return paramsList;

        return [...paramsList, newParam];
    }

    static countryMapper(paramsList: CriterionParam[], newParam: CriterionParam) {
        const countryParam = { ...newParam, isPermanent: true };

        return CriterionParamsManager.replaceOrAddCriterionParam(paramsList, countryParam);
    }

    static replaceOrAddCriterionParam(paramsList: CriterionParam[], newParam: CriterionParam) {
        let replaced = false;

        const updatedParamsList = paramsList.map((item) => {
            if (item.filterKey === newParam.filterKey) {
                replaced = true;
                return newParam;
            }
            return item;
        });

        if (!replaced) {
            return [...paramsList, newParam];
        }

        return updatedParamsList;
    }
}

/**
 * Parses the given criteria data and filters out entries where the `CriterionParams` array is empty.
 *
 * @param criteria - A partial object of `CriteriaData` containing the criteria to be parsed.
 * @returns A `CriteriaData` object containing only the criteria with non-empty `CriterionParams` arrays.
 */
export function parseCriteriaData(criteria: Partial<CriteriaData>) {
    return Object.entries(criteria).reduce((acc, [key, value]) => {
        if (value.CriterionParams.length > 0) {
            acc[key as CriterionEnum] = value;
        }

        return acc;
    }, {} as CriteriaData);
}
