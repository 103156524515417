import { FC, useMemo, useState } from 'react';

import { useLocale } from '@core/utils/locale';
import { IconMenuSettings } from '@assets/icons';
import useAnchorElement from '@core/hooks/useAnchorElement';
import { SaveSearchResponse } from '@core/api/SaveSearch/useSaveSearch';
import DropdownMenu from '@components/utility/DropdownMenu/DropdownMenu';
import { CommonPhrasesEnum, SavedSearchEnum } from '@core/enums/localeEnum';

import EditSearchModal from '../../SavedSearches/SaveSearchModal/EditSearchModal';
import DeleteSearchModal from '../../SavedSearches/SaveSearchModal/DeleteSearchModal';

const TableActionsMenu: FC<{
    data: SaveSearchResponse;
    index: number;
}> = ({
    index,
    data
}) => {
    const [modalRow, setModalRow] = useState(0);
    const [modal, setModal] = useState({ type: '', index: 0 });

    const {
        anchorEl,
        open,
        onAnchorClick,
        onClose
    } = useAnchorElement();

    const { t } = useLocale();
    const menuOptions = useMemo(
        () => [
            {
                label: t(CommonPhrasesEnum.Edit),
                onClick: () => {
                    setModal({ type: 'edit', index });
                    setModalRow(index);
                    onClose();
                },
            },
            {
                label: t(CommonPhrasesEnum.Delete),
                onClick: () => {
                    setModal({ type: 'delete', index });
                    setModalRow(index);
                    onClose();
                },
            },
        ],
        [index, onClose, t],
    );

    return (
        <>
            <div onClick={onAnchorClick} className="KebabMenuIcon">
                <IconMenuSettings />
            </div>
            <DropdownMenu
                open={open}
                anchorEl={anchorEl}
                onClose={onClose}
                options={menuOptions}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            />

            {modal.type === 'edit' && modalRow === index && (
                <EditSearchModal
                    title={t(CommonPhrasesEnum.EditName)}
                    isOpen={modal.type === 'edit'}
                    handleClose={() => {
                        setModal({ type: '', index: 0 });
                    }}
                    data={data}
                />
            )}
            {modal.type === 'delete' && modalRow === index && (
                <DeleteSearchModal
                    title={t(SavedSearchEnum.DeleteSearch)}
                    isOpen={modal.type === 'delete'}
                    handleClose={() => {
                        setModal({ type: '', index: 0 });
                    }}
                    data={data}
                />
            )}
        </>
    );
};

export default TableActionsMenu;
