import { paths } from '@core/api/api';
import useFetch from '@hooks/useFetch';
import { MethodsEnum, OperationsEnum, Content, ContentType } from '@core/enums/api';

const GET_SEGMENTATIONS = '/segmentations';

type GetSegmentationsEndpoint = paths[typeof GET_SEGMENTATIONS][MethodsEnum.GET];
type SegmentationsParams = GetSegmentationsEndpoint[OperationsEnum.PARAMETERS]['query'];
export type SegmentationsResponse = GetSegmentationsEndpoint[OperationsEnum.RESPONSES]['200'][Content][ContentType];

export default function useSegmentations(params?: SegmentationsParams, lazy = true) {
    const {
        data,
        loading,
        error,
        doFetch
    } = useFetch<SegmentationsResponse, SegmentationsParams>({
        url: GET_SEGMENTATIONS,
        lazy,
        params,
    });

    return { data, loading, error, doFetch } as const;
}
