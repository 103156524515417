import { FC, useMemo } from 'react';
import { Box } from '@mui/system';

import Chip from '@components/utility/Chips/Chip';

import { ZipItem } from './useZip';
import { OperatorTargetingPhrasesEnum } from '@core/enums/localeEnum';
import { useLocale } from '@core/utils/locale';

const ZipChips: FC<{
    zips: ZipItem[];
    removeZip: (zip: ZipItem) => void;
    clearZips: () => void;
}> = ({
    zips,
    removeZip,
    clearZips
}) => {
    const { t } = useLocale();
    const renderChips = useMemo(() => {
        if (zips?.length > 1) {
            return (
                <Chip
                    onChipDelete={() => clearZips()}
                    id="AllZips"
                    testId="CombinedPostalCodes"
                    name={`${zips?.length} ${t(OperatorTargetingPhrasesEnum.PostalCodes)}`}
                />
            );
        }

        return zips?.map((data) => {
            return (
                <Chip
                    key={data.value}
                    onChipDelete={() => removeZip(data)}
                    id={data.value as string}
                    name={data.formattedValue}
                />
            );
        });
    }, [clearZips, removeZip, t, zips]);

    return <Box sx={{ display: 'flex' }}>{renderChips}</Box>;
};

export default ZipChips;
