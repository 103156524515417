import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import { useLocale } from '@core/utils/locale';
import { useAdvancedSearch } from '@core/contexts';
import { RoutesEnum } from '@core/enums/RoutesEnum';
import Button from '@components/utility/Button/Button';
import Collapse from '@components/utility/Collapse/Collapse';
import Criteria from '@components/features/searchCriteria/Criteria/Criteria';
import { CommonPhrasesEnum, OperatorTargetingPhrasesEnum } from '@core/enums/localeEnum';
import CollapsedCriteria from '@components/features/searchCriteria/CollapsedCriteria/CollapsedCriteria';

import './SearchCriteriaResults.styles.scss';

const SearchCriteriaResults: FC = () => {
    const { t } = useLocale();
    const navigate = useNavigate();

    const {
        criteria,
        onDeleteCriterionParam,
        onResetCriteriaByFilterKey
    } = useAdvancedSearch();

    if (!criteria || Object.keys(criteria).length === 0) {
        return null;
    }

    return (
        <Collapse
            title={t(OperatorTargetingPhrasesEnum.Criteria)}
            collapsedContentSlot={<CollapsedCriteria data={criteria} />}
            actionsSlot={
                <div className="SearchCriteriaResults__button">
                    <Button
                        modifiers={['naked']}
                        onClick={() => {
                            navigate(RoutesEnum.OperatorTargetingCriteria);
                        }}
                        testId="buttonEditCriteria"
                    >
                        {t(CommonPhrasesEnum.Edit).toLocaleLowerCase()}
                    </Button>
                </div>
            }
        >
            <div className="SearchCriteriaResults__criteria">
                <Criteria
                    data={criteria}
                    onDelete={onDeleteCriterionParam}
                    onResetCriteriaByFilterKey={onResetCriteriaByFilterKey}
                />
            </div>
        </Collapse>
    );
};

export default SearchCriteriaResults;
