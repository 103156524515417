import { useCallback, useEffect, useState } from 'react';

import { useLocale } from '@core/utils/locale';
import { OperatorTargetingPhrasesEnum } from '@enums/localeEnum';
import Toggle, { ToggleType } from '@components/utility/Toggle/Toggle';
import { useAdvancedSearch } from '@core/contexts/AdvancedSearch.context';
import { CriterionEnum, CriterionParam, CriterionParamFilter } from '@core/models/Criterion';

export const INCLUDE_WITHOUT_MENU_ID = OperatorTargetingPhrasesEnum.IncludeOperatorsWithoutMenu;

const OnTheMenuIncludesCheckbox = () => {
    const [isToggleChecked, setIsToggleChecked] = useState<boolean>(false);
    const { t } = useLocale();

    const {
        onAddCriterionParam,
        getCriterionByFilterKey,
        onDeleteCriterionParam
    } = useAdvancedSearch();

    useEffect(() => {
        const menuParams = getCriterionByFilterKey(CriterionEnum.CuisineTypes, CriterionParamFilter.MenuIncludes);
        const checkboxParam = menuParams?.find((keyword) => keyword?.value?.toString() === INCLUDE_WITHOUT_MENU_ID);

        setIsToggleChecked(!!checkboxParam);
    }, [getCriterionByFilterKey]);

    const onToggleCheckbox = useCallback(() => {
        const tagParam: CriterionParam = {
            filterKey: CriterionParamFilter.MenuIncludes,
            name: t(OperatorTargetingPhrasesEnum.IncludeOperatorsWithoutMenu),
            value: INCLUDE_WITHOUT_MENU_ID,
        };

        onAddCriterionParam(CriterionEnum.CuisineTypes, tagParam);
    }, [t, onAddCriterionParam]);

    return (
        <Toggle
            type={ToggleType.Checkbox}
            isChecked={isToggleChecked}
            setIsChecked={(val) => {
                if (val) {
                    return onToggleCheckbox();
                }

                return onDeleteCriterionParam(CriterionEnum.CuisineTypes, INCLUDE_WITHOUT_MENU_ID);
            }}
            testId="checkboxOnTheMenuIncludes"
        >
            {t(OperatorTargetingPhrasesEnum.IncludeOperatorsWithoutMenu)}
        </Toggle>
    );
};

export default OnTheMenuIncludesCheckbox;
