import { type FC, useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { IconCheckmark } from '@assets/icons';
import { useLocale } from '@core/utils/locale';
import { RoutesEnum } from '@core/enums/RoutesEnum';
import Button from '@components/utility/Button/Button';
import { CommonPhrasesEnum } from '@core/enums/localeEnum';
import { SaveSearchResponse } from '@core/api/SaveSearch/useSaveSearch';
import { useAdvancedSearch } from '@core/contexts/AdvancedSearch.context';
import SaveSearchModal from '@pages/SavedSearches/SaveSearchModal/SaveSearchModal';
import EditSearchModal from '@pages/SavedSearches/SaveSearchModal/EditSearchModal';
import UpdateSaveSearchModal from '@pages/SavedSearches/SaveSearchModal/UpdateSaveSearchModal';

import { useAppContext } from '../../../../App.context';

const HeaderContent: FC = () => {
    const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false);
    const [isSaveModalOpen, setIsSaveModalOpen] = useState<boolean>(false);
    const [isUpdateModalOpen, setIsUpdateModalOpen] = useState<boolean>(false);

    const { t } = useLocale();

    const {
        criteria,
        onResetCriteria,
        savedSearch: {
            isDirty,
            activeSaveSearch
        },
    } = useAdvancedSearch();

    const navigate = useNavigate();

    const { isUserId } = useAppContext();

    const onNewSearch = useCallback(() => {
        onResetCriteria();
        navigate(RoutesEnum.OperatorTargetingCriteria);
    }, [navigate, onResetCriteria]);

    const isUserSavedSearch = activeSaveSearch ? isUserId(activeSaveSearch.user_external_id) : false;

    const onSaveSearch = useCallback(() => {
        if (isDirty && isUserSavedSearch) {
            return setIsUpdateModalOpen(true);
        }

        return setIsSaveModalOpen(true);
    }, [isDirty, setIsSaveModalOpen, setIsUpdateModalOpen, isUserSavedSearch]);

    const onEditName = useCallback(() => {
        setIsEditModalOpen(true);
    }, [setIsEditModalOpen]);

    return (
        <>
            {isUserSavedSearch && (
                <div className="OperatorTargetingResults__naked-button">
                    <Button testId="buttonEditSavedSearches" modifiers={['naked', 'auto']} onClick={onEditName}>
                        {t(CommonPhrasesEnum.EditName).toLocaleLowerCase()}
                    </Button>
                </div>
            )}
            <div className="OperatorTargetingResults__buttons">
                <Button
                    testId="buttonNewSearch"
                    modifiers={['tertiary', 'auto']}
                    style={{ backgroundColor: 'white' }}
                    onClick={onNewSearch}
                >
                    {t(CommonPhrasesEnum.NewSearch)}
                </Button>

                <Button
                    testId="buttonSaveSearch"
                    modifiers={['primary', 'auto']}
                    onClick={onSaveSearch}
                    isDisabled={!isDirty && isUserSavedSearch}
                >
                    {!isDirty && isUserSavedSearch ? (
                        <>
                            <IconCheckmark width={14} height={14} style={{ marginRight: '6px' }} />
                            {t(CommonPhrasesEnum.SearchSaved)}
                        </>
                    ) : (
                        t(CommonPhrasesEnum.SaveSearch)
                    )}
                </Button>
            </div>

            {isUpdateModalOpen && (
                <UpdateSaveSearchModal
                    title={t(CommonPhrasesEnum.SaveSearch)}
                    isOpen={isUpdateModalOpen}
                    handleClose={() => setIsUpdateModalOpen(false)}
                    criteria={criteria}
                    handleOpenSaveModal={() => {
                        setIsSaveModalOpen(true);
                    }}
                />
            )}

            {isEditModalOpen && (
                <EditSearchModal
                    title={t(CommonPhrasesEnum.EditName)}
                    isOpen={isEditModalOpen}
                    handleClose={() => setIsEditModalOpen(false)}
                    data={activeSaveSearch as SaveSearchResponse}
                    shouldUpdateActiveSavedSearch
                />
            )}

            {isSaveModalOpen && (
                <SaveSearchModal
                    title={t(CommonPhrasesEnum.SaveSearch)}
                    isOpen={isSaveModalOpen}
                    handleClose={() => setIsSaveModalOpen(false)}
                    criteria={criteria}
                />
            )}
        </>
    );
};

export default HeaderContent;
