import cn from 'classnames';

import { FCX } from '@core/models';
import MuiTableCell from '@mui/material/TableCell';

import { TableConfigItem } from './Table.helpers';

const TableCell: FCX<{
    align?: TableConfigItem['align'];
    onClick?: () => void;
}> = ({
    children,
    align = 'left',
    className,
    onClick,
    testId,
    style
}) => {
    return (
        <MuiTableCell
            onClick={onClick}
            classes={{ root: 'Table__cell' }}
            className={cn({ 'Table__cell--right': align === 'right' }, className)}
            data-testid={testId}
            style={style}
        >
            {children}
        </MuiTableCell>
    );
};

export default TableCell;
