import { useMemo } from 'react';

import { FCX } from '@core/models';
import { useLocale } from '@core/utils/locale';
import { SegmentCodesEnum } from '@core/models/Segments';
import { formatNumberByLocale } from '@core/utils/string';
import Collapse from '@components/utility/Collapse/Collapse';
import { WidgetType } from '@components/utility/Widget/Widget';
import { FeatureFlags, useFeatureFlag } from '@core/hooks/useFeatureFlag';
import Heading, { HeadingVariant } from '@components/ui/Typography/Heading';
import GroupedBarChart, { BarChartGroup } from '@components/utility/Charts/GroupedBarChart/GroupedBarChart';
import { FilterTypeEnum } from '@core/enums/localeEnum';
import { useOperatorTargetingResultsContext } from '@pages/OperatorTargetingResults/OperatorTargetingResults.context';
import { OperatorAggregationsModel } from '@core/models/OperatorAggregations';

export const itemMatchesPrefix = (item: BarChartGroup, prefixes: string[]) => {
    return prefixes.some((prefix) => item.id.toString().startsWith(prefix));
};

export const segmentMatchesPrefixes = (segment: BarChartGroup, prefixes: string[]) => {
    return segment.items?.some((item) => itemMatchesPrefix(item, prefixes));
};

export const findSegment = (segments: BarChartGroup[], itemPrefixes: string[]) => {
    return segments.find(
        (segment) =>
            segment.id === SegmentCodesEnum.LodgingAndRecreation && segmentMatchesPrefixes(segment, itemPrefixes),
    );
};

export const filterItems = (items: BarChartGroup[], itemPrefixes: string[]) => {
    return items.filter((item) => itemMatchesPrefix(item, itemPrefixes));
};

export const filterAggregations = (aggregations: OperatorAggregationsModel, itemPrefixes: string[]) => {
    const result = findSegment(aggregations?.segments ?? [], itemPrefixes);

    if (result?.items) {
        return {
            ...result,
            items: filterItems(result.items, itemPrefixes),
        };
    }

    return result ?? null;
};

const LodgingAndRecreation: FCX = () => {
    const isLodgingChartEnabled = useFeatureFlag(FeatureFlags.LodginChart);
    const isRecreationChartEnabled = useFeatureFlag(FeatureFlags.RecreationChart);

    const {
        t,
        locale
    } = useLocale();

    const {
        summaryData: {
            aggregations,
            drillDown: { onDrillDownSegments },
        },
    } = useOperatorTargetingResultsContext();

    const filteredLodgingRecreationAggregations = useMemo(() => {
        const result = aggregations?.segments?.find((segment) => segment.id === SegmentCodesEnum.LodgingAndRecreation);
        return result;
    }, [aggregations]);

    const filteredLodgingAggregations = useMemo(() => filterAggregations(aggregations, ['S02.04']), [aggregations]);

    const filteredRecreationAggregations = useMemo(
        () => filterAggregations(aggregations, ['S02.01', 'S02.02', 'S02.03']),
        [aggregations],
    );

    const lodgingCount = useMemo(() => {
        return filteredLodgingAggregations?.items?.reduce((acc, item) => acc + (item.count ?? 0), 0) ?? 0;
    }, [filteredLodgingAggregations]);

    const recreationCount = useMemo(() => {
        return filteredRecreationAggregations?.items?.reduce((acc, item) => acc + (item.count ?? 0), 0) ?? 0;
    }, [filteredRecreationAggregations]);

    const lodgingRecreationCount = useMemo(() => {
        return filteredLodgingRecreationAggregations?.items?.reduce((acc, item) => acc + (item.count ?? 0), 0) ?? 0;
    }, [filteredLodgingRecreationAggregations]);

    if (!(lodgingCount > 0 || recreationCount > 0)) {
        return null;
    }

    return (
        <Collapse
            title={
                <Heading variant={HeadingVariant.H3}>
                    {t(FilterTypeEnum.LodgingAndRecreation)} ({formatNumberByLocale(lodgingRecreationCount, locale)})
                </Heading>
            }
            startCollapsed={false}
            widgetProps={{
                type: WidgetType.Section,
                className: 'section-small',
            }}
        >
            {lodgingCount > 0 && isLodgingChartEnabled && (
                <>
                    <div className="OperatorResultsSummary__group-count">
                        {t(FilterTypeEnum.Lodging)} ({lodgingCount})
                    </div>
                    <div className="OperatorResultsSummary__charts">
                        <div className="OperatorResultsSummary__segments">
                            <Heading variant={HeadingVariant.H4}>{t(FilterTypeEnum.Segments)}</Heading>

                            <GroupedBarChart
                                groups={filteredLodgingAggregations?.items ?? []}
                                onBarClick={onDrillDownSegments}
                                showGroupTitle={false}
                            />
                        </div>
                    </div>
                </>
            )}

            {recreationCount > 0 && isRecreationChartEnabled && (
                <>
                    <div className="OperatorResultsSummary__group-count">
                        {t(FilterTypeEnum.Recreation)} ({recreationCount})
                    </div>

                    <div className="OperatorResultsSummary__charts">
                        <div className="OperatorResultsSummary__segments">
                            <Heading variant={HeadingVariant.H4}>{t(FilterTypeEnum.Segments)}</Heading>

                            <GroupedBarChart
                                groups={filteredRecreationAggregations?.items ?? []}
                                onBarClick={onDrillDownSegments}
                                showGroupTitle={false}
                            />
                        </div>
                    </div>
                </>
            )}
        </Collapse>
    );
};

export default LodgingAndRecreation;
