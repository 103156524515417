import { FC } from 'react';

import { useLocale } from '@core/utils/locale';
import Zip from '@pages/OperatorTargetingCriteria/components/LocationContent/RegionalCriteria/Zip/Zip';
import { CountryCodesEnum } from '@core/enums/flagsEnum';
import { LocalesEnum, OperatorTargetingPhrasesEnum } from '@core/enums/localeEnum';
import useZip from '@pages/OperatorTargetingCriteria/components/LocationContent/RegionalCriteria/Zip/useZip';

const PostalCode: FC<{
    inputLabel: string;
    country: CountryCodesEnum;
}> = ({
    inputLabel,
    country
}) => {
    const zipProps = useZip(country);

    const {
        t,
        locale
    } = useLocale();

    const zipPlaceholder = () => {
        if (locale === LocalesEnum.En) {
            return `${t(OperatorTargetingPhrasesEnum.Enter)} ${inputLabel}${t(OperatorTargetingPhrasesEnum.SelectPlural)}`;
        }

        return `${t(OperatorTargetingPhrasesEnum.EnterZip)}`;
    };

    return <Zip {...zipProps} inputLabel={inputLabel} placeholder={zipPlaceholder()} />;
};

export default PostalCode;
