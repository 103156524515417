import { useState, useRef, useEffect, useCallback, useMemo } from 'react';
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse, Method } from 'axios';

export type Config<B = unknown, P = unknown> = Omit<Partial<AxiosRequestConfig<B>>, 'params'> & {
    params?: P;
    cancelRunningRequest?: boolean;
};

type useFetchArgs<P, B> = {
    method?: Method;
    url: string;
    params?: P;
    lazy?: boolean;
    initialConfig?: Config<B, P>;
};

export type useFetchReturn<R, B, P> = {
    cancel: () => void;
    data: R | null;
    error: null | AxiosError<R, B>;
    loading: boolean;
    doFetch: (newConfig?: Config<B, P>) => Promise<void | AxiosResponse<R, B>>;
};

export const api = axios.create({
    baseURL: `${process.env.REACT_APP_API}`,
    withCredentials: true,
    headers: {
        Accept: 'application/json',
    },
});

/**
 * Custom hook to perform HTTP requests using Axios.
 *
 * @template R - The type of the response data.
 * @template P - The type of the request parameters.
 * @template B - The type of the request body.
 */
const useFetch = <R = unknown, P = unknown, B = unknown>({
    initialConfig,
    method = 'GET',
    url,
    params,
    lazy = false,
}: useFetchArgs<P, B>): useFetchReturn<R, B, P> => {
    const [data, setData] = useState<R | null>(null);
    const [error, setError] = useState<null | AxiosError<R, B>>(null);
    const [loading, setLoading] = useState(false);

    const abortControllerRef = useRef<AbortController | null>(null);
    const defaultConfig = useRef<Config<B, P> | undefined>({ ...initialConfig });

    const paramsString = useMemo(() => JSON.stringify(params), [params]);

    const cancelRunningRequest = () => {
        if (abortControllerRef.current) {
            abortControllerRef.current.abort();
        }
    };

    const doFetch = useCallback(
        async (newConfig: Config<B, P> = {}) => {
            if (newConfig.cancelRunningRequest !== false) {
                cancelRunningRequest();
            }

            abortControllerRef.current = new AbortController();

            setLoading(true);

            const mergedConfig = {
                ...defaultConfig?.current,
                ...newConfig,
            };

            try {
                const response = await api.request<R>({
                    signal: abortControllerRef.current.signal,
                    method,
                    params: paramsString ? JSON.parse(paramsString) : undefined,
                    url,
                    ...mergedConfig,
                });

                setData(response.data);
                setError(null);

                return response;
            } catch (error) {
                if (axios.isAxiosError(error)) {
                    if (axios.isCancel(error)) return;

                    return setError(error as AxiosError<R, B>);
                }

                return Promise.reject(error);
            } finally {
                abortControllerRef.current = null;
                setLoading(false);
            }
        },
        [method, url, paramsString],
    );

    useEffect(() => {
        if (lazy) {
            return;
        }

        doFetch();
    }, [doFetch, lazy]);

    useEffect(() => {
        return () => {
            cancelRunningRequest();
        };
    }, []);

    return { doFetch, data, error, loading, cancel: cancelRunningRequest };
};

export default useFetch;
