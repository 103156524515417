import { paths } from '@core/api/api';
import useFetch from '@hooks/useFetch';
import { MethodsEnum, OperationsEnum, Content, ContentType } from '@core/enums/api';

const GET_CUISINES = '/cuisines';

type GetCuisinesEndpoint = paths[typeof GET_CUISINES][MethodsEnum.GET];
type CuisinesParams = GetCuisinesEndpoint[OperationsEnum.PARAMETERS]['query'];
export type CuisinesResponse = GetCuisinesEndpoint[OperationsEnum.RESPONSES]['200'][Content][ContentType];

export default function useCuisines() {
    const {
        data,
        loading,
        error,
        doFetch
    } = useFetch<CuisinesResponse, CuisinesParams>({
        url: GET_CUISINES,
        lazy: true,
    });

    return { data, loading, error, doFetch } as const;
}
