import { paths } from '@core/api/api';
import useFetch from '@hooks/useFetch';
import { MethodsEnum, OperationsEnum, Content, ContentType } from '@core/enums/api';

const GET_FIELDS_URL = '/fields';

type GetFieldsEndpoint = paths[typeof GET_FIELDS_URL][MethodsEnum.GET];
type FieldsResponse = GetFieldsEndpoint[OperationsEnum.RESPONSES]['200'][Content][ContentType];
type FieldsParams = GetFieldsEndpoint[OperationsEnum.PARAMETERS]['query'];

export default function useFields(query?: FieldsParams) {
    const {
        data,
        loading,
        error,
        doFetch
    } = useFetch<FieldsResponse, FieldsParams>({
        url: GET_FIELDS_URL,
        lazy: true,
        params: query,
    });

    return { data: data?.fields, loading, error, doFetch } as const;
}
