import type { FC, ReactNode } from 'react';

import { ID } from '@core/models';
import { capitalize } from '@core/utils/string';
import { PhrasesEnum } from '@core/enums/localeEnum';
import { CountryCodesEnum } from '@core/enums/flagsEnum';
import { Maybe, OperatorSummary } from '@graphql/generated/graphql';
import { getFormattedPhoneNumber } from '@core/helpers/helpers';
import type { useTableSortReturn } from '@core/hooks/useTableSort';

export type TableConfig = {
    config: Array<TableConfigItem>;
    rowConfig?: TableConfigRow;
    sortConfig?: useTableSortReturn;
};

export type TableConfigRow = {
    anchorHref?: (index: ID) => string;
};

export type TableConfigItem = {
    onClick?: (index: ID) => void;
    title: PhrasesEnum | '';
    renderTitle?: ReactNode;
    field: string;
    align?: 'left' | 'right';
    cellStyles?: React.CSSProperties;
    Cell: FC<{ index: number }>;
    isSortable: boolean;
};

function parseNorthAmericanAddress(location: OperatorSummary['location'], phone?: string | null): string {
    return formatAddressFields([
        capitalizeIfPresent(location?.address_1),
        capitalizeIfPresent(location?.address_2),
        '\n',
        capitalizeIfPresent(location?.locality_1),
        capitalizeIfPresent(location?.administrative_area_1, true),
        capitalizeIfPresent(location?.postal_code_1, true),
        '\n',
        getFormattedPhoneNumber(phone ?? '', location?.country ?? ''),
    ]);
}

function parseEmeaAddress(location: OperatorSummary['location'], phone?: string | null): string {
    return formatAddressFields([
        capitalizeIfPresent(location?.address_1),
        capitalizeIfPresent(location?.address_2),
        '\n',
        capitalizeIfPresent(location?.postal_code_1, true),
        capitalizeIfPresent(location?.locality_1),
        '\n',
        getFormattedPhoneNumber(phone ?? '', location?.country ?? ''),
    ]);
}

function parseMalaysiaAddress(location: OperatorSummary['location'], phone?: string | null): string {
    return formatAddressFields([
        capitalizeIfPresent(location?.address_1),
        '\n',
        capitalizeIfPresent(location?.postal_code_1, true),
        capitalizeIfPresent(location?.locality_1),
        '\n',
        capitalizeIfPresent(location?.administrative_area_1),
        capitalizeIfPresent(location?.country),
        '\n',
        getFormattedPhoneNumber(phone ?? '', location?.country ?? ''),
    ]);
}

function parseSingaporeAddress(location: OperatorSummary['location'], phone?: string | null): string {
    return formatAddressFields([
        capitalizeIfPresent(location?.address_1),
        '\n',
        capitalizeIfPresent(location?.locality_1),
        capitalizeIfPresent(location?.postal_code_1),
        '\n',
        getFormattedPhoneNumber(phone ?? '', location?.country ?? ''),
    ]);
}

function parseUnitedKingdomAddress(location: OperatorSummary['location'], phone?: string | null): string {
    return formatAddressFields([
        capitalizeIfPresent(location?.address_1),
        capitalizeIfPresent(location?.address_2),
        '\n',
        capitalizeIfPresent(location?.locality_1),
        capitalizeIfPresent(location?.postal_code_1, true),
        '\n',
        getFormattedPhoneNumber(phone ?? '', location?.country ?? ''),
    ]);
}

function capitalizeIfPresent(value: string | null | undefined, capsAll?: boolean): string | null {
    return value ? capitalize(value, capsAll) : '';
}

function formatAddressFields(target: Maybe<string>[]): string {
    return target.filter(Boolean).join(' ');
}

export function buildOperatorAddress(location: OperatorSummary['location'], phone?: string | null): ReactNode {
    let parsedAddress = '';

    switch (location?.country) {
        case CountryCodesEnum.US:
        case CountryCodesEnum.CA:
        case CountryCodesEnum.AU:
        case CountryCodesEnum.NZ:
            parsedAddress = parseNorthAmericanAddress(location, phone);
            break;

        case CountryCodesEnum.UK:
            parsedAddress = parseUnitedKingdomAddress(location, phone);
            break;

        case CountryCodesEnum.MY:
            parsedAddress = parseMalaysiaAddress(location, phone);
            break;

        case CountryCodesEnum.SG:
            parsedAddress = parseSingaporeAddress(location, phone);
            break;

        default:
            parsedAddress = parseEmeaAddress(location, phone);
            break;
    }

    if (!parsedAddress) return null;

    return (
        <div
            style={{
                whiteSpace: 'pre-wrap',
            }}
            data-testid="tableCellAddress"
        >
            {parsedAddress.replace(/(\s*\n\s*)/g, '\n')}
        </div>
    );
}
