import { useMemo, type FC } from 'react';
import { useLocation } from 'react-router-dom';

import { useLocale } from '@core/utils/locale';
import { RoutesEnum } from '@core/enums/RoutesEnum';
import PageTitle from '@components/ui/PageTitle/PageTitle';
import Breadcrumbs from '@components/ui/Breadcrumbs/Breadcrumbs';
import { useAdvancedSearch } from '@core/contexts/AdvancedSearch.context';
import { CommonPhrasesEnum, SavedSearchEnum, SidebarPhrasesEnum } from '@core/enums/localeEnum';

import HeaderContent from './HeaderContent';
import SearchCriteriaResults from '../SearchCriteriaResults/SearchCriteriaResults';

const OperatorTargetingResultsHeader: FC = () => {
    const { t } = useLocale();
    const location = useLocation();

    const {
        savedSearch: { activeSaveSearch },
    } = useAdvancedSearch();

    const pageTitle = useMemo(() => {
        if (activeSaveSearch) {
            return t(SavedSearchEnum.ResultsFor) + ` "${activeSaveSearch.name}"`;
        }
        return t(CommonPhrasesEnum.Results);
    }, [activeSaveSearch, t]);

    const breadcrumbs = useMemo(() => {
        const previousPath = location.state?.from;

        if (previousPath === RoutesEnum.Territories) {
            return [{ label: t(SidebarPhrasesEnum.Territories), link: RoutesEnum.Territories }];
        }
        return [{ label: t(SidebarPhrasesEnum.OperatorTargeting), link: RoutesEnum.OperatorTargetingCriteria }];
    }, [location, t]);

    return (
        <div className="OperatorTargetingResults__header">
            <Breadcrumbs breadcrumbs={breadcrumbs} />
            <PageTitle testId="ResultsPageTitle" title={pageTitle} rowContent={<HeaderContent />} />

            <SearchCriteriaResults />
        </div>
    );
};

export default OperatorTargetingResultsHeader;
