import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import { IconPageDenied } from '@assets/icons';
import { useLocale } from '@core/utils/locale';
import { RoutesEnum } from '@core/enums/RoutesEnum';
import Button from '@components/utility/Button/Button';
import Heading, { HeadingVariant } from '@components/ui/Typography/Heading';

import './ErrorPage.styles.scss';
import { ErroPagePhrasesEnum } from '@core/enums/localeEnum';

const AccessDenied: FC = () => {
    const navigate = useNavigate();
    const { t } = useLocale();

    function goBackHome() {
        navigate(RoutesEnum.Home);
    }

    return (
        <div className="ErrorPage">
            <div className="ErrorPage__icon-container">
                <IconPageDenied />
            </div>
            <Heading variant={HeadingVariant.H1} className="ErrorPage__title">
                {t(ErroPagePhrasesEnum.AccessDeniedTitle)}
            </Heading>

            <div className="ErrorPage__description">{t(ErroPagePhrasesEnum.AccessDeniedDescription)}</div>

            <Button modifiers={['primary', 'auto']} onClick={goBackHome}>
                {t(ErroPagePhrasesEnum.GoBackHome)}
            </Button>
        </div>
    );
};

export default AccessDenied;
