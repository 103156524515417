import { useCallback, useMemo, useState } from 'react';
import cn from 'classnames';

import { FCX, ID } from '@core/models';
import { useLocale } from '@core/utils/locale';
import { removeWhitespaces } from '@core/utils/string';
import Popover from '@components/utility/Popover/Popover';
import { IconArrowCollapse, IconClose } from '@assets/icons';
import { GradientColorsEnum } from '@core/enums/gradientEnum';
import { CriteriaData, CriterionEnum, CriterionParam, CriterionParamFilter } from '@core/models/Criterion';

import './SearchCriterionDropdown.styles.scss';
import SearchCriterionChipList from '../Criteria/SearchCriterionChipList';
import { MAP_FILTER_KEY_TO_TRANSLATION } from '../Criteria/Criteria.constants';

const SearchCriterionDropdown: FCX<{
    criterionParams: CriterionParam[];
    criterionEnum: CriterionEnum;
    criterionColor: GradientColorsEnum;
    criterionParamKey: CriterionParamFilter;
    onDeleteCriteria: (criterionEnum: keyof CriteriaData, criterionParamId: ID) => void;
    onResetCriteriaByFilterKey: (criterionEnum: CriterionEnum, criterionParamKey: CriterionParamFilter) => void;
}> = ({
    criterionParams,
    criterionEnum,
    criterionColor,
    criterionParamKey,
    onDeleteCriteria,
    onResetCriteriaByFilterKey,
}) => {
    const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
    const [anchorEl, setAnchorEl] = useState<Element | null>(null);
    const { t } = useLocale();

    const onSelectClick = useCallback((e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        setAnchorEl((prevAnchor) => prevAnchor ?? e.currentTarget);
        setIsDropdownOpen((prevOpen) => !prevOpen);
    }, []);

    const groupName = useMemo(() => {
        const groupPhraseEnum = MAP_FILTER_KEY_TO_TRANSLATION[criterionParamKey];

        return `${t(groupPhraseEnum)} (${criterionParams.length}) `;
    }, [criterionParamKey, criterionParams.length, t]);

    return (
        <div className="SearchCriterionDropdown">
            <div
                className="SearchCriterionDropdown__name"
                onClick={onSelectClick}
                data-testid={`chip${removeWhitespaces(groupName ?? '')}`}
            >
                {!!criterionColor && <div className={cn('SearchCriterionDropdown__dot', `is-${criterionColor}`)} />}

                {groupName}
                <IconArrowCollapse
                    className={cn('SearchCriterionDropdown__dropdown-icon', {
                        'is-open': isDropdownOpen,
                    })}
                />
            </div>
            <div className="SearchCriterionDropdown__divider" />
            <div
                className="SearchCriterionDropdown__delete-button"
                data-testid={`chipDelete${removeWhitespaces(groupName ?? '')}`}
                onClick={() => onResetCriteriaByFilterKey(criterionEnum, criterionParamKey)}
            >
                <IconClose width={8} />
            </div>

            <Popover
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: -12,
                }}
                transformOrigin={{
                    vertical: -14,
                    horizontal: 'left',
                }}
                open={isDropdownOpen}
                onClose={() => setIsDropdownOpen(false)}
                anchorEl={anchorEl}
                unscrollable={true}
            >
                <div className="SearchCriterionDropdown__dropdown">
                    <SearchCriterionChipList
                        criterionParams={criterionParams}
                        criterionEnum={criterionEnum}
                        criterionColor={criterionColor}
                        onDelete={onDeleteCriteria}
                    />
                </div>
            </Popover>
        </div>
    );
};

export default SearchCriterionDropdown;
