import cn from 'classnames';

import { FCX, ID } from '@core/models';
import { IconClose } from '@assets/icons';
import { formatTestIds } from '@core/utils/string';
import { GradientColorsEnum } from '@core/enums/gradientEnum';

import './Chip.styles.scss';

const Chip: FCX<{
    baseColor?: GradientColorsEnum;
    description?: string;
    name: string;
    onChipDelete?: (id: ID) => void;
    id: ID;
}> = ({
    baseColor,
    description,
    name,
    onChipDelete,
    id,
    testId,
    style
}) => {
    const chipTestId = formatTestIds(testId);

    return (
        <div className="Chip" data-testid={`chip${chipTestId}`} style={style}>
            {!!baseColor && <div className={cn('Chip__dot', `is-${baseColor}`)} />}
            <div>
                <span className="Chip__description">{description && `${description}: `}</span>
                <span className="Chip__name">{name}</span>
            </div>

            {onChipDelete && (
                <div className="Chip__icon" data-testid={`chipDelete${chipTestId}`} onClick={() => onChipDelete(id)}>
                    <IconClose width={8} />
                </div>
            )}
        </div>
    );
};

export default Chip;
