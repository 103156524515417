import { FC, useCallback, useEffect, useState } from 'react';

import Map from '@components/utility/Map/Map';
import { useLocale } from '@core/utils/locale';
import { CountryCoords } from '@core/models/Map';
import { useAdvancedSearch } from '@core/contexts';
import { CommonPhrasesEnum } from '@core/enums/localeEnum';
import Widget, { WidgetType } from '@components/utility/Widget/Widget';
import AnimatedLoader from '@components/ui/AnimatedLoader/AnimatedLoader';
import { useDrawOperatorMarkers } from '@pages/OperatorTargetingResults/hooks';
import NumberAnimation from '@components/utility/NumberAnimation/NumberAnimation';
import { useOperatorTargetingResultsContext } from '@pages/OperatorTargetingResults/OperatorTargetingResults.context';

import './OperatorResultsMap.styles.scss';
import MarkerInfoBox from './MarkerInfoBox';

const OPERATOR_RESULTS_MAP_OPTIONS = {
    zoomControl: true,
    minZoom: 4,
    maxZoom: 17,
    styles: [
        {
            featureType: 'landscape',
            stylers: [
                {
                    visibility: 'off',
                },
            ],
        },
        {
            featureType: 'poi',
            stylers: [
                {
                    visibility: 'off',
                },
            ],
        },
        {
            featureType: 'poi.park',
            stylers: [
                {
                    visibility: 'on',
                },
            ],
        },
    ],
};

const OperatorResultsMap: FC = () => {
    const [map, setMap] = useState<google.maps.Map | null>(null);
    const google = window.google;

    const { t } = useLocale();
    const { searchFilters } = useAdvancedSearch();

    const {
        summaryData: {
            activeCountryCriterion,
            resultsCount
        },
    } = useOperatorTargetingResultsContext();

    const {
        activeMarker,
        clearActiveMarker,
        isLoading
    } = useDrawOperatorMarkers(map, searchFilters);

    useEffect(() => {
        if (!activeCountryCriterion || !map || !google?.maps) {
            return;
        }

        fetch('/countries-coords.json')
            .then((response) => response.json())
            .then((countries) => {
                const country = countries.find((c: CountryCoords) => c.short_name === activeCountryCriterion?.value);
                const bounds = new google.maps.LatLngBounds(
                    new google.maps.LatLng(country.sw_lat, country.sw_lng),
                    new google.maps.LatLng(country.ne_lat, country.ne_lng),
                );

                map.fitBounds(bounds);
            });
    }, [activeCountryCriterion, google?.maps, map]);

    const getMapCallback = useCallback((map: google.maps.Map) => {
        setMap(map);
    }, []);

    return (
        <div className="OperatorResultsMap">
            <div className="OperatorResultsTab">
                <div
                    className="OperatorResultsTab__results-count"
                    data-testid="tableResultsCount"
                    style={{ marginLeft: 'auto' }}
                >
                    <NumberAnimation className="OperatorResultsTab__results-count-numbers" count={resultsCount} />
                    {t(CommonPhrasesEnum.Results)}
                </div>
            </div>

            <Widget type={WidgetType.Section}>
                <div data-testid="OperatorResultsMap" className="OperatorResultsMap__map-container">
                    {isLoading && (
                        <div className="OperatorResultsMap__loading-container">
                            <AnimatedLoader className="ExportModal__loading" />
                        </div>
                    )}
                    <Map options={OPERATOR_RESULTS_MAP_OPTIONS} getMapCallback={getMapCallback}>
                        <MarkerInfoBox operatorMarker={activeMarker} clearActiveMarker={clearActiveMarker} />
                    </Map>
                </div>
            </Widget>
        </div>
    );
};

export default OperatorResultsMap;
