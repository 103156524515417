import { useEffect, useMemo, useState } from 'react';

import { useLocale } from '@core/utils/locale';
import PageTitle from '@components/ui/PageTitle/PageTitle';
import Widget, { WidgetType } from '@components/utility/Widget/Widget';
import Toggle, { ToggleType } from '@components/utility/Toggle/Toggle';
import { SaveSearchResponse } from '@core/api/SaveSearch/useSaveSearch';
import { useAdvancedSearch } from '@core/contexts/AdvancedSearch.context';
import Heading, { HeadingVariant } from '@components/ui/Typography/Heading';
import SavedSearchResultsTable from '@pages/SavedSearches/Table/SavedSearchResultsTable';
import { FilterTypeEnum, OperatorTargetingPhrasesEnum, SavedSearchEnum } from '@enums/localeEnum';

import './SavedSearch.styles.scss';
import NoSavedSearch from './NoSavedSearch';
import { useAppContext } from '../../App.context';

const SavedSearch = () => {
    const [showUserSearches, setShowUserSearches] = useState(false);
    const { t } = useLocale();
    const {
        userInfo: { fullName },
        isUserId,
    } = useAppContext();

    const {
        savedSearch: {
            savedSearchData,
            savedSearchDoFetch,
            savedSearchLoading
        },
    } = useAdvancedSearch();

    useEffect(() => {
        savedSearchDoFetch();
    }, [savedSearchDoFetch]);

    const visibleData: SaveSearchResponse[] = useMemo(() => {
        const dataWithNameReplaced =
            savedSearchData?.map((item) => ({
                ...item,
                created_by_name: item.created_by_name === fullName ? t(SavedSearchEnum.Me) : item.created_by_name,
            })) || [];

        return showUserSearches
            ? dataWithNameReplaced.filter((item) => isUserId(item.user_external_id))
            : dataWithNameReplaced;
    }, [savedSearchData, showUserSearches, fullName, t, isUserId]);

    return (
        <div className="SavedSearch">
            <PageTitle testId="SavedSearchesPageTitle" title={t(FilterTypeEnum.SavedSearches)} />

            {savedSearchData?.length ? (
                <div className="SavedSearch__section">
                    <Widget type={WidgetType.Table} className="SavedSearch__table-header">
                        <Heading testId="SavedSearchesTableHeader" variant={HeadingVariant.H4}>
                            {t(OperatorTargetingPhrasesEnum.OperatorTargetingSearches)}
                        </Heading>
                        <Toggle
                            type={ToggleType.Switch}
                            isChecked={showUserSearches}
                            testId="inputSavedSearchesToggle"
                            setIsChecked={setShowUserSearches}
                        >
                            {t(SavedSearchEnum.MySavedSearchOnly)}
                        </Toggle>
                    </Widget>

                    <SavedSearchResultsTable resultsData={visibleData} loading={savedSearchLoading} />
                </div>
            ) : (
                <NoSavedSearch />
            )}
        </div>
    );
};

export default SavedSearch;
