import cn from 'classnames';
import { useState } from 'react';
import { Property } from 'csstype';

import { FCX, ID } from '@core/models';
import Tooltip from '@components/utility/Tooltip/Tooltip';
import Heading, { HeadingVariant } from '@components/ui/Typography/Heading';

import './GroupedBarChart.styles.scss';

export type BarChartGroup = {
    id: ID;
    title: string;
    tooltipText?: string;
    count?: number;
    percentage?: string;
    items?: BarChartGroup[];
};

export type GroupedBarChartConfig = {
    barColor?: string;
    barContainerHeight?: number;
    barWidth?: number;
    barDistribution?: Property.JustifyContent;
};

const GroupedBarChart: FCX<{
    config?: GroupedBarChartConfig;
    groups: BarChartGroup[];
    onBarClick: (barId: ID, barName: string) => void;
    showGroupTitle?: boolean;
}> = ({
    config,
    groups,
    onBarClick,
    showGroupTitle = true,
    testId = 'GroupedBarChart'
}) => {
    const [activeBarId, setActiveBarId] = useState<ID | null>(null);

    return (
        <div className="GroupedBarChart" data-testid={testId}>
            <div className="GroupedBarChart__viewport">
                {groups.map((group, index) => (
                    <div key={index} className="GroupedBarChart__group-container">
                        <div className="GroupedBarChart__group">
                            {showGroupTitle && (
                                <Heading variant={HeadingVariant.H3} className="GroupedBarChart__group-title">
                                    {group.title}
                                </Heading>
                            )}
                            <div
                                className="GroupedBarChart__bars-container"
                                style={{
                                    ...(config?.barContainerHeight && { height: `${config.barContainerHeight}px` }),
                                    ...(config?.barDistribution && { justifyContent: config.barDistribution }),
                                }}
                            >
                                {group?.items?.map((bar) => (
                                    <Tooltip key={bar.id} content={bar?.tooltipText} placement="top">
                                        <div
                                            className={cn('GroupedBarChart__bar', {
                                                dimmed: activeBarId && activeBarId !== bar.id,
                                            })}
                                            onMouseEnter={() => setActiveBarId(bar.id)}
                                            onMouseLeave={() => setActiveBarId(null)}
                                            onClick={() => onBarClick(bar.id, bar.title)}
                                            style={{
                                                height: bar?.percentage ?? '0%',
                                                ...(config?.barWidth && { width: `${config.barWidth}px` }),
                                                ...(config?.barColor && { background: config.barColor }),
                                            }}
                                        />
                                    </Tooltip>
                                ))}

                                {Array.from({ length: 5 }).map((_, index) => {
                                    return (
                                        <div
                                            key={index}
                                            className="GroupedBarChart__grid-line"
                                            style={{
                                                bottom: `calc(${(index + 1) * 20}% - 1px)`,
                                            }}
                                        />
                                    );
                                })}
                            </div>
                        </div>
                        <div
                            key={index}
                            className="GroupedBarChart__group-labels"
                            style={{
                                ...(config?.barDistribution && { justifyContent: config.barDistribution }),
                            }}
                        >
                            {group?.items?.map((bar, index) => (
                                <div key={index} className="GroupedBarChart__group-label">
                                    {bar.title}
                                </div>
                            ))}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default GroupedBarChart;
