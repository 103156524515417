import useFetch from '@hooks/useFetch';

const GET_MENU_URL_TEMPLATE = '/global-menu/menus/';

export type MenuItem = {
    name: string;
    description: string;
    price?: number;
};

export type MenuHeader = {
    type?: string;
    value?: string;
    headers?: MenuHeader[];
    items?: MenuItem[];
};

export type MenuResponseContent = {
    source: string;
    epoch_timestamp?: string;
    headers?: MenuHeader[];
};

export type MenuResponse = MenuResponseContent[];

export enum MenuHeaderTypes {
    MASTER = 'MASTER',
    PRIMARY = 'PRIMARY',
    SUB = 'SUB',
}

export default function useMenu(profileId: string) {
    const {
        data,
        loading,
        error,
        doFetch
    } = useFetch<MenuResponse>({
        url: `${GET_MENU_URL_TEMPLATE}${profileId}`,
        lazy: true,
    });

    return { data, loading, error, doFetch } as const;
}
