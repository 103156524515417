import React, { useEffect, useState, useCallback } from 'react';
import cn from 'classnames';

import { useLocale } from '@core/utils/locale';
import { HomePhrasesEnum } from '@core/enums/localeEnum';

import QuickSearch, { QuickSearchTypes } from './QuickSearch/QuickSearch';

import './Home.styles.scss';

const CAROUSEL_IMAGE_COUNT = 56;
const images: string[] = Array.from(
    { length: CAROUSEL_IMAGE_COUNT },
    (_, index) => `${process.env.PUBLIC_URL}/carousel/${index + 1}.jpeg`,
);

const getRandomStartIndex = (length: number): number => {
    const array = new Uint32Array(1);
    window.crypto.getRandomValues(array);
    return array[0] % length;
};

const preloadImage = (index: number): Promise<void> => {
    return new Promise<void>((resolve) => {
        const img = new Image();
        img.src = images[index];
        img.onload = () => resolve();
    });
};

const Home: React.FC = () => {
    const [currentImageIndex, setCurrentImageIndex] = useState(getRandomStartIndex(images.length));
    const [hasAnimated, setHasAnimated] = useState<boolean>(false);
    const [loadedImages, setLoadedImages] = useState<number[]>([]);

    const onAnimate = () => {
        setHasAnimated(true);
    };

    const loadImage = useCallback(
        async (index: number) => {
            if (!loadedImages.includes(index)) {
                await preloadImage(index);
                setLoadedImages((prevLoadedImages) => [...prevLoadedImages, index]);
            }
        },
        [loadedImages],
    );

    useEffect(() => {
        const initialLoad = async () => {
            await loadImage(currentImageIndex);
            await loadImage((currentImageIndex + 1) % images.length);
            await loadImage((currentImageIndex + 2) % images.length);
        };

        initialLoad();
    }, [currentImageIndex, loadImage]);

    useEffect(() => {
        const interval = setInterval(async () => {
            const nextIndex = (currentImageIndex + 1) % images.length;
            await loadImage(nextIndex);
            setCurrentImageIndex(nextIndex);
            await loadImage((nextIndex + 2) % images.length);
        }, 9000);

        return () => clearInterval(interval);
    }, [currentImageIndex, loadImage]);

    const { t } = useLocale();

    return (
        <div className="Home">
            <div className="Home__overlay"></div>
            <div className="Home__carousel">
                {images.map(
                    (image, index) =>
                        loadedImages.includes(index) && (
                            <img
                                key={image}
                                src={image}
                                alt={`Slide ${index}`}
                                className={cn('Home__image', {
                                    'Home__image--visible': index === currentImageIndex,
                                })}
                            />
                        ),
                )}
            </div>
            <div className="Home__content">
                <div className={cn('Home__search', { 'Home__search--interacted': hasAnimated })}>
                    <QuickSearch
                        placeholder={t(HomePhrasesEnum.QuickSearchPlaceholder)}
                        type={QuickSearchTypes.LARGE}
                        hasAnimated={hasAnimated}
                        onAnimate={onAnimate}
                    />
                </div>
            </div>
        </div>
    );
};

export default Home;
