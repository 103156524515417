import { FC, useCallback, useMemo, useState } from 'react';

import { useLocale } from '@core/utils/locale';
import { IconDoubleArrow } from '@assets/icons';
import Button from '@components/utility/Button/Button';
import { CountryCodesEnum } from '@core/enums/flagsEnum';
import Tabs, { TabsVariant } from '@components/utility/Tabs/Tabs';
import { OperatorProfilePhrasesEnum } from '@core/enums/localeEnum';
import Widget, { WidgetType } from '@components/utility/Widget/Widget';
import { MenuResponse, MenuHeader, MenuHeaderTypes } from '@core/api/useGlobalMenu';

import './Menu.styles.scss';
import MenuSection from './MenuSection';
import useGetMenuTabs, { extractMenuHeaderItems } from './useGetMenuTabs';

const MENU_ANCHOR_ID = 'menu-container-anchor';

const Menu: FC<{
    menuData: MenuResponse;
    countryCode: CountryCodesEnum;
}> = ({
    menuData,
    countryCode
}) => {
    const [triggerActiveTab, setTriggerActiveTab] = useState<boolean>(true);

    const { t } = useLocale();

    const menuTabsContent = useGetMenuTabs(menuData, countryCode);

    const scrollToMenuTop = useCallback(() => {
        const menuContainer = document.getElementById(MENU_ANCHOR_ID);
        if (menuContainer) {
            setTriggerActiveTab((prev) => !prev);
            menuContainer.scrollIntoView({ behavior: 'smooth' });
        }
    }, []);

    const isAllMasterHeaderEmpty = useMemo((): boolean => {
        const checkHeaders = (headers: MenuHeader[]): boolean => {
            for (const header of headers) {
                if (header.type === MenuHeaderTypes.MASTER && header.value !== '') {
                    return false;
                }
                if (header.headers && !checkHeaders(header.headers)) {
                    return false;
                }
            }
            return true;
        };

        return menuData.every((data) => data.headers && checkHeaders(data.headers));
    }, [menuData]);

    const emptyHeaderItems = useMemo(() => {
        return menuData?.[0]?.headers ? extractMenuHeaderItems(menuData[0].headers) : [];
    }, [menuData]);

    return (
        <>
            <Widget
                type={WidgetType.Section}
                className="MenuTabs"
                id={MENU_ANCHOR_ID}
                style={{ scrollMarginTop: '80px' }}
            >
                {!isAllMasterHeaderEmpty ? (
                    menuTabsContent.length > 0 && (
                        <Tabs
                            tabsContent={menuTabsContent}
                            triggerResetActiveTab={triggerActiveTab}
                            variant={TabsVariant.HorizontalScroll}
                            hasSharedContent={true}
                        />
                    )
                ) : (
                    <MenuSection
                        countryCode={countryCode}
                        title={t(OperatorProfilePhrasesEnum.Menu)}
                        items={emptyHeaderItems}
                    />
                )}
            </Widget>
            {menuTabsContent.length > 0 && (
                <Button onClick={scrollToMenuTop} className="MenuTabs__back-to-top">
                    <IconDoubleArrow className="Collapse__double-arrow" />
                </Button>
            )}
        </>
    );
};

export default Menu;
