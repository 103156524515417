import { useEffect, useState } from 'react';

import { isEmpty } from '@core/helpers/helpers';
import { LocalesEnum } from '@core/enums/localeEnum';
import useTags, { TagsResponseContent } from '@core/api/useTags';

export type ParsedTagsData = {
    [key: string]: TagsResponseContent;
};

function useTagsCriteria(country: string | undefined, locale: LocalesEnum) {
    const [tags, setTags] = useState<ParsedTagsData | null>(null);

    const { doFetch: fetchTags } = useTags();

    useEffect(() => {
        if (country) {
            fetchTags({ params: { country, locale } }).then((res) => {
                if (res) {
                    const newData = res?.data?.tags?.reduce((acc, curr) => {
                        if (curr?.children?.length > 0) {
                            acc[curr.category] = {
                                ...curr,
                            };
                        }

                        return acc;
                    }, {} as ParsedTagsData);

                    setTags(isEmpty(newData) ? null : newData);
                }
            });
        }
    }, [fetchTags, country, locale]);

    return { tags };
}

export default useTagsCriteria;
