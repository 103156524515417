import { FC } from 'react';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import MuiTableRow from '@mui/material/TableRow';

import TableCell from './TableCell';
import { useTableContext } from './TableContext';

const TableRow: FC<{
    rowIndex: number;
}> = ({ rowIndex }) => {
    const {
        config,
        rowConfig
    } = useTableContext();

    return (
        <MuiTableRow className="Table__row" data-testid={`tableRow${rowIndex}`}>
            {config.map((columnConfig, colIndex) => {
                return (
                    <TableCell
                        key={colIndex}
                        align={columnConfig.align}
                        testId={`tableCell${rowIndex}_${colIndex}`}
                        className={cn({ 'Table__cell--clickable': !!columnConfig?.onClick })}
                        onClick={() => {
                            columnConfig?.onClick?.(rowIndex);
                        }}
                        style={columnConfig.cellStyles}
                    >
                        {rowConfig?.anchorHref && <Link to={rowConfig.anchorHref(rowIndex)} className="Table__link" />}
                        <columnConfig.Cell index={rowIndex} />
                    </TableCell>
                );
            })}
        </MuiTableRow>
    );
};

export default TableRow;
