import { groupBy } from '@core/helpers/helpers';
import { TerritoryDetails } from '@core/api/useTerritories';
import { GeoDistanceMatchExpression, OperatorFilter, StringExpression } from '@graphql/generated/graphql';
import { INCLUDE_WITHOUT_MENU_ID } from '@pages/OperatorTargetingCriteria/components/SegmentsCriteriaContent/RestaurantsAndBarsCriteria/OnTheMenuIncludesCheckbox';

import { CriteriaData, CriterionParam, CriterionParamFilter } from './Criterion';

export type CriterionParamsGroupedByKey = Record<CriterionParamFilter, CriterionParam[]>;

type FilterRule = {
    [key: string]: FilterRuleValue | FilterRuleValue[];
};

type FilterRuleValue = string | string[] | FilterRule;

/** Filters that use IntExpression conditions. */
const INT_EXPRESSION_FILTERS = [CriterionParamFilter.PotentialPurchaseTotal];

const INT_IN_EXPRESSION_FILTERS = [CriterionParamFilter.HotelStarLevel];

/** Filters that use IntExpression conditions for dates. */
const DATE_INT_EXPRESSION_FILTERS = [CriterionParamFilter.YearsInBusinessRange];

/** Filters that use Array Expression conditions. */
const ARRAY_EXPRESSION_FILTERS = [CriterionParamFilter.ConfidenceLevel];

/** Filters that use Geo Location conditions. */
const GEO_LOCATION_FILTERS = [CriterionParamFilter.GeoLocation];

const FILTERS_STRUCTURE: FilterRuleValue[] = [
    CriterionParamFilter.Country,
    {
        or: [
            CriterionParamFilter.AdministrativeArea1,
            CriterionParamFilter.AdministrativeArea2,
            CriterionParamFilter.PostalCode,
            CriterionParamFilter.GeoLocation,
            CriterionParamFilter.Territories,
        ],
    },
    {
        or: [CriterionParamFilter.YearsInBusinessRange],
    },
    {
        or: [CriterionParamFilter.PotentialPurchaseTotal],
    },
    CriterionParamFilter.Segment,
    CriterionParamFilter.Cuisine,
    CriterionParamFilter.ChainSizeRange,
    CriterionParamFilter.AnnualSalesRange,
    CriterionParamFilter.EstMealsPerDayRange,
    CriterionParamFilter.ConfidenceLevel,
    CriterionParamFilter.NumberOfEmployeesRangeCommercial,
    CriterionParamFilter.NumberOfEmployeesRangeNonCommercial,
    CriterionParamFilter.NumberOfRoomsRange,
    CriterionParamFilter.HotelStarLevel,
    CriterionParamFilter.FoodTags,
    CriterionParamFilter.PlaceTags,
    CriterionParamFilter.AverageCheckRange,
    CriterionParamFilter.CustomerStatus,
    CriterionParamFilter.MenuIncludes,
    CriterionParamFilter.MenuExcludes,
];

export class OperatorFilterFactory {
    /**
     * This function builds the filters and their expressions based on the selected criteria.
     * @param criteria CriteriaData object that contains all the criteria.
     * @returns
     */
    static buildFilters(criteria: CriteriaData): OperatorFilter[] {
        const operatorFilters = this.groupCriteriaByKeys(criteria).flatMap(this.mapCriterionGroupToOperatorFilter);
        const structuredOperatorFilters = this.mapStructureToOperatorFilters(operatorFilters, FILTERS_STRUCTURE);

        return structuredOperatorFilters;
    }

    /**
     * This function groups the criterion params by the filterKey.
     * @param criteria CriteriaData object that contains all the criteria.
     * @returns
     */
    private static groupCriteriaByKeys(criteria: CriteriaData): CriterionParamsGroupedByKey[] {
        return Object.values(criteria).reduce((acc: CriterionParamsGroupedByKey[], criterion) => {
            return [
                ...acc,
                groupBy<CriterionParam, CriterionParamFilter>(criterion.CriterionParams, (c) => c.filterKey),
            ];
        }, []);
    }

    /**
     * This function takes the grouped criterion params and maps it into an array OperatorFilter[].
     * OperatorFilter is the data structure that contains the logic to filter the operators in the backend.
     * Check https://github.com/intuit/graphql-filter-java/ for more information.
     *
     * The filterKey of the CriterionParam is used to determine the type of filter that will be built.
     *
     * @param groupedCriterionParams CriterionParamsGroupedByKey[] where each element is a group of filters that belong to a different CRITERION.
     * @returns An array of OperatorFilter[].
     */
    private static mapCriterionGroupToOperatorFilter = (group: CriterionParamsGroupedByKey): OperatorFilter[] => {
        return Object.entries(group).flatMap(([key, criterionParams]) => {
            const filterKey = key as CriterionParamFilter;

            if (INT_EXPRESSION_FILTERS.includes(filterKey)) {
                return this.buildIntExpression(filterKey, criterionParams);
            }

            if (INT_IN_EXPRESSION_FILTERS.includes(filterKey)) {
                return this.buildIntInExpression(filterKey, criterionParams);
            }

            if (DATE_INT_EXPRESSION_FILTERS.includes(filterKey)) {
                return this.buildDateIntExpression(filterKey, criterionParams);
            }

            if (GEO_LOCATION_FILTERS.includes(filterKey)) {
                return this.buildGeoDistanceMatchExpression(filterKey, criterionParams);
            }

            if (ARRAY_EXPRESSION_FILTERS.includes(filterKey)) {
                return { [filterKey]: this.getAllParamsValues(criterionParams) };
            }

            if (filterKey === CriterionParamFilter.Territories) {
                return this.buildTerritoriesExpressionGroup(criterionParams) ?? [];
            }

            if (filterKey === CriterionParamFilter.MenuIncludes || filterKey === CriterionParamFilter.MenuExcludes) {
                return this.buildMenuStringExpression(criterionParams, filterKey);
            }

            return {
                [filterKey]: this.buildStringExpression(this.getAllParamsValues(criterionParams)),
            };
        });
    };

    private static buildMenuStringExpression = (
        criterionParams: CriterionParam[],
        filterKey: CriterionParamFilter,
    ): OperatorFilter => {
        const menuCriterionParams = criterionParams.map((criterionParam) => {
            const value = criterionParam.value as string;
            const isExcludes = filterKey === CriterionParamFilter.MenuExcludes;
            const isExactMatch = value.startsWith('"') && value.endsWith('"');

            if (value === INCLUDE_WITHOUT_MENU_ID) {
                return this.wrapWithNot({ menu_text: { contains: '' } });
            }

            if (isExactMatch) {
                const val = value.replace(/"/g, '');
                return isExcludes ? this.wrapWithNot({ menu_text: { equals: val } }) : { menu_text: { equals: val } };
            }

            return isExcludes
                ? this.wrapWithNot({ menu_text: { contains: value } })
                : { menu_text: { contains: value } };
        });

        return { [filterKey]: this.combineFiltersWithOr(menuCriterionParams) } as OperatorFilter;
    };

    /**
     * This function builds a filter for the Territories criterion.
     * The Territories criterion is a special case because it contains nested filters.
     * The nested filters are extracted from the additionalData.details of the CriterionParam and mapped into an array of OperatorFilter[].
     * Then all the nested filters are grouped inside an 'or' logical operator.
     *
     * @param criterionParams
     * @returns
     */
    private static buildTerritoriesExpressionGroup = (criterionParams: CriterionParam[]): OperatorFilter | null => {
        const territoryFilters = criterionParams.flatMap((criterionParam) => {
            const nestedFilters = criterionParam?.additionalData?.details as TerritoryDetails;

            if (!nestedFilters) return [];

            return Object.entries(nestedFilters).map(([key, values]) => ({
                [key]: this.buildStringExpression(values),
            }));
        });

        if (territoryFilters.length === 0) {
            return null;
        }

        return {
            [CriterionParamFilter.Territories as string]: {
                or: territoryFilters,
            },
        };
    };

    /**
     * This function extracts all the values from the criterionParams.
     * The values can be a single value or multiple values separated by a comma (e.g. 'value1,value2') in the value field. hence the split(',') call.
     * @param criterionParams
     * @returns
     */
    private static getAllParamsValues(criterionParams: CriterionParam[]): string[] {
        return criterionParams.flatMap((criterionParam) => criterionParam.value.toString().split(','));
    }

    /**
     * This function builds a IntExpression filter for DATES based on the min and max values of the criterionParams.
     * If both min and max are present, the filter will be a between expression.
     * If only min is present, the filter will be a less than expression.
     * If only max is present, the filter will be a greater than expression.
     *
     * @param filterKey The key of the filter to be built.
     * @param criterionParams The criterionParams that contain the min and max values.
     * @returns An array of OperatorFilter[].
     */
    private static buildDateIntExpression(filterKey: string, criterionParams: CriterionParam[]): OperatorFilter[] {
        const rangedFilters = criterionParams.map((criterionParam) => {
            const min = criterionParam.additionalData?.min;
            const max = criterionParam.additionalData?.max;

            if (min && max) {
                const between = [max, min];
                return { [filterKey]: { between } };
            }

            return {
                ...(min ? { [filterKey]: { lt: min } } : {}),
                ...(max ? { [filterKey]: { gt: max } } : {}),
            };
        });

        return rangedFilters;
    }

    /**
     * This function builds a IntExpression filter based on the min and max values of the criterionParams.
     * If both min and max are present, the filter will be a between expression.
     * If only min is present, the filter will be a less than expression.
     * If only max is present, the filter will be a greater than expression.
     *
     * @param filterKey The key of the filter to be built.
     * @param criterionParams The criterionParams that contain the min and max values.
     * @returns An array of OperatorFilter[].
     */
    private static buildIntExpression(filterKey: string, criterionParams: CriterionParam[]): OperatorFilter[] {
        const rangedFilters = criterionParams.map((criterionParam) => {
            const min = criterionParam.additionalData?.min;
            const max = criterionParam.additionalData?.max;

            if (min && max) {
                const between = [min, max];
                return { [filterKey]: { between } };
            }

            return {
                ...(min ? { [filterKey]: { gt: min } } : {}),
                ...(max ? { [filterKey]: { lt: max } } : {}),
            };
        });

        return rangedFilters;
    }

    /**
     * This function builds a IntInExpression filter based on the size of the criterionParams.
     * If size is 1, the filter will be a eq expression.
     * If size > 1, the filter will be a in expression.
     *
     * @param filterKey The key of the filter to be built.
     * @param criterionParams The criterionParams that contain the min and max values.
     * @returns An array of OperatorFilter[].
     */
    private static buildIntInExpression(filterKey: string, criterionParams: CriterionParam[]): OperatorFilter[] {
        if (criterionParams.length === 1) {
            const singleParam = criterionParams[0];
            return [{ [filterKey]: { eq: singleParam.value } }];
        } else if (criterionParams.length > 1) {
            const values = criterionParams.map((param) => param.value);
            return [{ [filterKey]: { in: values } }];
        }
        return [];
    }

    private static buildGeoDistanceMatchExpression(
        filterKey: string,
        criterionParams: CriterionParam[],
    ): OperatorFilter[] {
        return criterionParams.map((criterionParam) => {
            const parsedValue = JSON.parse(criterionParam.value as string);

            const geoFilter: GeoDistanceMatchExpression = {
                match_geo_distance: {
                    distance: parsedValue.distance,
                    lat: parsedValue.lat,
                    lon: parsedValue.lon,
                },
            };
            return { [filterKey]: geoFilter };
        });
    }

    /**
     *  This function builds a string expression based on the filterValue.
     *  If the filterValue has more than one element, the expression will be an 'in' expression.
     *  Otherwise, the expression will be an 'equals' expression.
     * @param filterValue
     * @returns
     */
    static buildStringExpression(filterValue: string[]): StringExpression {
        return filterValue.length > 1 ? { in: filterValue } : { equals: filterValue[0] };
    }

    /**
     * Wraps the given filter with a logical NOT operator.
     *
     * @param filter - The filter to be negated.
     * @returns A new `OperatorFilter` object with the `not` property set to the given filter.
     */
    static wrapWithNot(filter: OperatorFilter): OperatorFilter {
        return { not: filter };
    }

    /**
     * Combines an array of OperatorFilter objects using a logical OR operation.
     *
     * @param filter - An array of OperatorFilter objects to be combined.
     * @returns A single OperatorFilter object that represents the logical OR of the input filters.
     *          If the input array contains only one filter, that filter is returned as is.
     */
    static combineFiltersWithOr(filter: OperatorFilter[]): OperatorFilter {
        if (filter.length > 1) {
            return { or: filter };
        }

        return filter[0];
    }

    /**
     * This function maps a structure to the OperatorFilters by recursively traversing the structure and Populating the OperatorFilters when a match is found.
     * A match is found when the rule is a string that matches a key of the OperatorFilter.
     * @param data The OperatorFilter[] that will be used
     * @param structure
     * @returns
     */
    private static mapStructureToOperatorFilters(
        data: OperatorFilter[],
        structure: FilterRuleValue[],
    ): OperatorFilter[] {
        const mapStructureToFilters = (rule: FilterRuleValue | FilterRuleValue[]): OperatorFilter[] => {
            if (typeof rule === 'string') {
                return data.filter((filter) => Object.keys(filter).includes(rule));
            }

            if (Array.isArray(rule)) {
                return rule.flatMap((currRule) => mapStructureToFilters(currRule));
            }

            const [key, value] = Object.entries(rule)[0];

            const operatorFilters = mapStructureToFilters(value);

            if (operatorFilters.length > 0) {
                return [{ [key]: operatorFilters }];
            }

            return [];
        };

        return mapStructureToFilters(structure) as OperatorFilter[];
    }
}
