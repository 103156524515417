import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { ID } from '@core/models';
import { useLocale } from '@core/utils/locale';
import { IconMenuSettings } from '@assets/icons';
import { useAdvancedSearch } from '@core/contexts';
import { RoutesEnum } from '@core/enums/RoutesEnum';
import { TerritoryDTO } from '@core/api/useTerritories';
import useAnchorElement from '@core/hooks/useAnchorElement';
import useSortedTableData from '@core/hooks/useSortedTableData';
import { TableConfig } from '@components/utility/Table/Table.helpers';
import Toggle, { ToggleType } from '@components/utility/Toggle/Toggle';
import DropdownMenu from '@components/utility/DropdownMenu/DropdownMenu';
import { OperatorTargetingPhrasesEnum, TerritoriesPhrasesEnum } from '@core/enums/localeEnum';

import {
    getTerritoryCriteria,
    mapTerritoriesToCriterionParam,
    TerritorySelection,
} from '../../hooks/useTerritoriesPage';

const TERRITORIES_DEFAULT_SORT = 'name';

export function useTerritoriesTableConfig(
    data: TerritoryDTO[],
    territorySelection: TerritorySelection,
): TableConfig & { sortedData: TerritoryDTO[] } {
    const [sortedData, sortConfig] = useSortedTableData(data, TERRITORIES_DEFAULT_SORT);

    const {
        t,
        locale
    } = useLocale();
    const navigate = useNavigate();

    const {
        activeCountry,
        onMergeCriteria
    } = useAdvancedSearch();

    const onTargetTerritoryAndNavigate = useCallback(
        (dto: TerritoryDTO, route: RoutesEnum) => {
            if (!activeCountry) return;

            const territoryCriteria = getTerritoryCriteria([
                activeCountry,
                ...mapTerritoriesToCriterionParam([dto], locale),
            ]);

            onMergeCriteria(territoryCriteria);
            navigate(route, { state: { from: RoutesEnum.Territories } });
        },
        [activeCountry, navigate, locale, onMergeCriteria],
    );

    const getDropdownMenuOptions = useCallback(
        (dto: TerritoryDTO) => [
            {
                label: t(OperatorTargetingPhrasesEnum.SeeResults),
                onClick: () => onTargetTerritoryAndNavigate(dto, RoutesEnum.OperatorTargetingResults),
            },
            {
                label: t(TerritoriesPhrasesEnum.StartTargeting),
                onClick: () => onTargetTerritoryAndNavigate(dto, RoutesEnum.OperatorTargetingCriteria),
            },
        ],
        [onTargetTerritoryAndNavigate, t],
    );

    return useMemo(
        () => ({
            config: sortedData?.length
                ? [
                    {
                        title: '',
                        renderTitle: (
                            <Toggle
                                type={ToggleType.Checkbox}
                                isChecked={territorySelection.isAllSelected}
                                setIsChecked={territorySelection.toggleAll}
                                style={{ height: 'auto' }}
                            />
                        ),
                        field: '',
                        Cell: ({ index }) => {
                            return (
                                <Toggle
                                    type={ToggleType.Checkbox}
                                    isChecked={territorySelection.isSelected(sortedData?.[index]?.name)}
                                    setIsChecked={() => territorySelection.toggleById(sortedData?.[index]?.name)}
                                    style={{ height: 'auto' }}
                                />
                            );
                        },
                        isSortable: false,
                        cellStyles: { width: '10px', paddingRight: '0' },
                    },
                    {
                        title: TerritoriesPhrasesEnum.TerritoryName,
                        field: TERRITORIES_DEFAULT_SORT,
                        Cell: ({ index }) => {
                            return <strong style={{ color: '#00866F' }}>{sortedData?.[index]?.name}</strong>;
                        },
                        isSortable: true,
                        onClick: (index: ID) => {
                            onTargetTerritoryAndNavigate(
                                sortedData?.[index as number],
                                RoutesEnum.OperatorTargetingResults,
                            );
                        },
                        cellStyles: { paddingLeft: '6px' },
                    },
                    {
                        title: '',
                        field: '',
                        Cell: ({ index }) => {
                            const {
                                onAnchorClick,
                                ...rest
                            } = useAnchorElement();

                            return (
                                <>
                                    <div onClick={onAnchorClick} className="KebabMenuIcon">
                                        <IconMenuSettings />
                                    </div>
                                    <DropdownMenu
                                        options={getDropdownMenuOptions(sortedData?.[index])}
                                        anchorOrigin={{
                                            vertical: 'center',
                                            horizontal: 'right',
                                        }}
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}
                                        {...rest}
                                    />
                                </>
                            );
                        },
                        isSortable: false,
                        cellStyles: { width: '50px' },
                    },
                ]
                : [],
            sortConfig,
            sortedData,
        }),
        [getDropdownMenuOptions, onTargetTerritoryAndNavigate, sortConfig, sortedData, territorySelection],
    );
}
