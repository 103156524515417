import { useCallback } from 'react';
import { paths } from '@core/api/api';
import useFetch from '@core/hooks/useFetch';
import { MethodsEnum, OperationsEnum, Content, ContentType } from '@core/enums/api';

const POST_TERRITORY_IMPORT = '/territories/import';

type GetTerritoryImportEndpoint = paths[typeof POST_TERRITORY_IMPORT][MethodsEnum.POST];
type TerritoryImportResponse = GetTerritoryImportEndpoint[OperationsEnum.RESPONSES]['400'][Content][ContentType];

export default function useTerritoryImport() {
    const {
        loading,
        error,
        doFetch
    } = useFetch<TerritoryImportResponse>({
        lazy: true,
        url: POST_TERRITORY_IMPORT,
        method: MethodsEnum.POST,
        initialConfig: {
            maxBodyLength: Infinity,
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        },
    });

    const doUploadFile = useCallback(
        (file: File, onSuccess: () => void) => {
            const formData = new FormData();
            formData.append('territoryFile', file);

            doFetch({
                data: formData,
            }).then(async (res) => {
                if (res?.status === 201) {
                    onSuccess();
                }
            });
        },
        [doFetch],
    );

    return {
        loading,
        error,
        doUploadFile,
    } as const;
}
