import cn from 'classnames';

import { FCX } from '@core/models';

import './Toggle.styles.scss';

export enum ToggleType {
    Checkbox = 'checkbox',
    Radio = 'radio',
    Switch = 'switch',
}

const Toggle: FCX<{
    type: ToggleType;
    isDisabled?: boolean;
    isChecked: boolean;
    setIsChecked: (value: boolean) => void;
}> = ({
    type,
    isDisabled = false,
    isChecked,
    setIsChecked,
    className,
    testId,
    children,
    style
}) => {
    return (
        <label
            role="toggle"
            className={cn(className, 'Toggle', isDisabled && 'is-disabled', isChecked && 'is-checked')}
            data-testid={testId}
            style={style}
        >
            <input
                type="checkbox"
                checked={isChecked}
                disabled={isDisabled}
                className="Toggle__input"
                onChange={() => setIsChecked(!isChecked)}
            />
            <div role="icon" className={cn('Toggle__icon', `is-${type}`)} data-testid={`${testId}Icon`} />
            <div role="label" className="Toggle__label" data-testid={`${testId}Label`}>
                {children}
            </div>
        </label>
    );
};

export default Toggle;
