import { FC } from 'react';
import { Link } from 'react-router-dom';
import { InfoBoxF } from '@react-google-maps/api';

import { useLocale } from '@core/utils/locale';
import { RoutesEnum } from '@core/enums/RoutesEnum';
import { replaceRouteParam } from '@core/utils/string';
import { GradientColorsEnum } from '@core/enums/gradientEnum';
import { IconFilterGeneral, IconLocation } from '@assets/icons';
import { OperatorTargetingResultsPhrasesEnum } from '@core/enums/localeEnum';
import CloseButton from '@components/utility/Button/CloseButton/CloseButton';
import BadgeIcon, { BadgeIconSizeEnum } from '@components/utility/BadgeIcon/BadgeIcon';
import { OperatorMarker } from '@pages/OperatorTargetingResults/hooks/useDrawOperatorMarkers';
import { buildQuickSearchAddress } from '@pages/Home/QuickSearch/QuickSearch.helpers';

const MarkerInfoBox: FC<{
    operatorMarker: OperatorMarker | null;
    clearActiveMarker: () => void;
}> = ({
    operatorMarker,
    clearActiveMarker
}) => {
    const { t } = useLocale();

    if (!operatorMarker || !operatorMarker.marker) {
        return null;
    }

    return (
        <InfoBoxF
            options={{
                closeBoxURL: '',
                enableEventPropagation: true,
                pixelOffset: new google.maps.Size(-151, -47),
                boxStyle: { overflow: 'visible' },
                maxWidth: 300,
                alignBottom: true,
            }}
            anchor={operatorMarker.marker}
        >
            <div className="OperatorResultsMap__info-box-body" data-testid="containerInfoBoxTooltip">
                <div className="OperatorResultsMap__info-box-header">
                    <div className="flex">
                        <BadgeIcon
                            icon={<IconFilterGeneral />}
                            baseColor={GradientColorsEnum.PURPLE}
                            size={BadgeIconSizeEnum.Large}
                        />
                        <div className="OperatorResultsMap__info-box-header-title" data-testid="textInfoBoxName">
                            {operatorMarker?.data?.business_name ?? ''}
                            <div className="OperatorResultsMap__info-box-header-subtitle" data-testid="textInfoId">
                                ID: {operatorMarker?.data?.operator_identifier ?? ''}
                            </div>
                        </div>
                    </div>
                    <CloseButton onClick={clearActiveMarker} isDark />
                </div>
                <div className="OperatorResultsMap__info-box-content" data-testid="textInfoBoxDetails">
                    {operatorMarker?.data?.segment}{' '}
                    {operatorMarker?.data?.sub_cuisine ? '• ' + operatorMarker?.data?.sub_cuisine : ''}
                </div>
                <div className="OperatorResultsMap__info-box-address" data-testid="textInfoBoxAddress">
                    <IconLocation />
                    {buildQuickSearchAddress(operatorMarker?.data?.location)}
                </div>
                <div className="OperatorResultsMap__info-box-footer">
                    <Link
                        to={replaceRouteParam(
                            RoutesEnum.OperatorProfile,
                            ':id',
                            operatorMarker?.data?.operator_identifier ?? '',
                        )}
                        target="_blank"
                        className="OperatorResultsMap__info-box-link"
                        data-testid="linkInfoBoxOperatorProfile"
                    >
                        {t(OperatorTargetingResultsPhrasesEnum.ViewFullProfile)}
                    </Link>
                </div>
            </div>
        </InfoBoxF>
    );
};

export default MarkerInfoBox;
