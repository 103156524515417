import { IconSavedSearch } from '@assets/icons';
import Text, { TextVariant } from '@components/ui/Typography/Text';
import { OperatorTargetingPhrasesEnum } from '@core/enums/localeEnum';
import Heading, { HeadingVariant } from '@components/ui/Typography/Heading';
import { FC } from 'react';
import { TranslateFunc } from '@core/utils/locale';

type SuccessComponentProps = {
    value: string | undefined;
    t: TranslateFunc;
};

export const SuccessComponent: FC<SuccessComponentProps> = ({
    value,
    t
}) => (
    <div className="SaveSearchModal__success">
        <div className="SaveSearchModal__success-icon">
            <IconSavedSearch />
        </div>
        <Heading variant={HeadingVariant.H3} testId="textSavedSearchSuccess">
            {t(OperatorTargetingPhrasesEnum.SaveSearchSuccess)}
        </Heading>
        <Text variant={TextVariant.B2} testId="textSavedSearchName">
            {value}
        </Text>
    </div>
);
