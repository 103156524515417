import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Button, { ButtonTypeEnum } from '@components/utility/Button/Button';
import Heading, { HeadingVariant } from '@components/ui/Typography/Heading';
import { useLocale } from '@core/utils/locale';
import { TerritoryImportPhrasesEnum } from '@core/enums/localeEnum';
import { RoutesEnum } from '@core/enums/RoutesEnum';
import useTerritories from '@core/api/useTerritories';
import useTerritoryImport from '@core/api/useTerritoryImport';

import './TerritoryImport.styles.scss';

const TerritoryImport: React.FC = () => {
    const { t } = useLocale();
    const navigate = useNavigate();
    const [file, setFile] = useState<File | null>(null);
    const [uploadErrorMessage, setUploadErrorMessage] = useState<string | null>(null);
    const [successMessage, setSuccessMessage] = useState<string | null>(null);
    const {
        error,
        loading,
        doUploadFile
    } = useTerritoryImport();

    const { doFetch } = useTerritories();

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files.length > 0) {
            setFile(event.target.files[0]);
        }
    };

    const handleImport = () => {
        if (!file) {
            setUploadErrorMessage('Please select a file to upload.');
            return;
        }

        setUploadErrorMessage(null);

        doUploadFile(file, () => {
            setUploadErrorMessage(null);
            setSuccessMessage('File uploaded successfully!');
        });
    };

    useEffect(() => {
        doFetch()
            .then((res) => {
                if (res?.status == 200) {
                    if (res?.data?.territories?.length !== 0) {
                        navigate(RoutesEnum.AccessDenied);
                    }
                }
            })
            .catch(() => {
                navigate(RoutesEnum.AccessDenied);
            });
    }, [doFetch, navigate]);

    return (
        <div className="TerritoryImport">
            <Heading testId="TerritoryImport" variant={HeadingVariant.H1} className="TerritoryImport__header">
                {t(TerritoryImportPhrasesEnum.TerritoryImport)}
            </Heading>
            <input className="TerritoryImport__input" type="file" onChange={handleFileChange} />
            <Button
                type={ButtonTypeEnum.Link}
                modifiers={['primary', 'auto']}
                isDisabled={loading || !!successMessage}
                onClick={handleImport}
                testId="buttonImportTerritories"
            >
                {loading ? t(TerritoryImportPhrasesEnum.Importing) : t(TerritoryImportPhrasesEnum.Import)}
            </Button>
            {successMessage && <p className="TerritoryImport__success-message">{successMessage}</p>}
            {uploadErrorMessage && <p className="TerritoryImport__error-message">{uploadErrorMessage}</p>}
            {error && (
                <p className="TerritoryImport__error-message">
                    {error?.response?.data?.messages?.[0] ?? uploadErrorMessage}
                </p>
            )}
        </div>
    );
};

export default TerritoryImport;
