import { FC, useEffect, useMemo } from 'react';

import { useLocale } from '@core/utils/locale';
import { IconFilterSegments } from '@assets/icons';
import { useSearchInput } from '@core/hooks/useInput';
import { CriterionEnum } from '@core/models/Criterion';
import Heading from '@components/ui/Typography/Heading';
import { GradientColorsEnum } from '@enums/gradientEnum';
import BadgeIcon from '@components/utility/BadgeIcon/BadgeIcon';
import {
    filterAndParseSegmentsChannels,
    mapChannelListToSelectOptions,
} from '@pages/OperatorTargetingCriteria/helpers/Classification.helpers';
import { useAdvancedSearch } from '@core/contexts/AdvancedSearch.context';
import Button, { ButtonTypeEnum } from '@components/utility/Button/Button';
import SearchInput from '@components/utility/Inputs/SearchInput/SearchInput';
import { FilterTypeEnum, OperatorTargetingPhrasesEnum } from '@enums/localeEnum';
import { getSafeBottomMargin } from '@pages/OperatorTargetingCriteria/helpers/Criteria.helpers';
import useListenToActiveMenuItem from '@pages/OperatorTargetingCriteria/hooks/useListenToActiveMenuItem';

import SegmentsChannel from './SegmentsChannel';
import { SegmentationsResponse } from '@core/api/useSegmentation';

export const SEGMENT_CRITERION = CriterionEnum.Segments;

const SegmentsContent: FC<{
    segmentations: SegmentationsResponse | null;
    onUpdateActiveNavigationSubItem: (id: string) => void;
}> = ({
    onUpdateActiveNavigationSubItem,
    segmentations
}) => {
    const { t } = useLocale();
    const { onResetCriterionParamsByKey } = useAdvancedSearch();

    const { observe } = useListenToActiveMenuItem(
        onUpdateActiveNavigationSubItem,
        '[data-observable]',
        '#observer-root',
    );

    const {
        value: searchSelectedValue,
        onChange
    } = useSearchInput();

    const channelList = useMemo(() => {
        return filterAndParseSegmentsChannels(segmentations?.children) ?? [];
    }, [segmentations?.children]);

    const safeBottomMargin = useMemo(() => getSafeBottomMargin(), []);
    const searchInputOptions = useMemo(() => mapChannelListToSelectOptions(channelList), [channelList]);

    useEffect(() => {
        if (channelList?.length > 0) {
            observe();
        }
    }, [channelList, observe]);

    return (
        <div className="CriteriaContent">
            <div className="CriteriaContent__header">
                <BadgeIcon icon={<IconFilterSegments />} baseColor={GradientColorsEnum.BLUE} />
                <Heading>{t(FilterTypeEnum.Segments)}</Heading>
                <SearchInput
                    options={searchInputOptions}
                    highlightResults
                    onChangeCallback={onChange}
                    value={searchSelectedValue}
                    testId="SegmentsSearch"
                />
                <Button
                    modifiers={['naked', 'auto']}
                    type={ButtonTypeEnum.Link}
                    onClick={() => onResetCriterionParamsByKey(SEGMENT_CRITERION)}
                    style={{ marginLeft: 'auto' }}
                    testId="ButtonSegmentsResetAll"
                >
                    {t(OperatorTargetingPhrasesEnum.ResetAll)}
                </Button>
            </div>
            <div style={{ marginBottom: safeBottomMargin }}>
                {channelList?.map((channel) => {
                    return (
                        <SegmentsChannel
                            key={channel?.external_id}
                            channel={channel}
                            searchSelectedValue={searchSelectedValue}
                            onClearSearch={() => {
                                onChange(null);
                            }}
                        />
                    );
                })}
            </div>
        </div>
    );
};

export default SegmentsContent;
