import { FC, useCallback, useMemo } from 'react';
import cn from 'classnames';

import { ID } from '@core/models';
import { useLocale } from '@utils/locale';
import { groupBy } from '@core/helpers/helpers';
import { CommonPhrasesEnum } from '@enums/localeEnum';
import Tooltip from '@components/utility/Tooltip/Tooltip';
import BadgeIcon from '@components/utility/BadgeIcon/BadgeIcon';
import HierarchyIndicator from '@components/utility/HierarchyIndicator/HierarchyIndicator';
import { CriteriaData, Criterion, CriterionEnum, CriterionParamFilter, getCriterionEnumIcon } from '@models/Criterion';

import './Criteria.styles.scss';
import { LIST_ONLY_FILTERS } from './Criteria.constants';
import SearchCriterionChipList from './SearchCriterionChipList';
import SearchCriterionDropdown from '../SearchCriterionDropdown/SearchCriterionDropdown';

const Criteria: FC<{
    data: CriteriaData;
    onDelete: (criterionKey: CriterionEnum, criterionParamId: ID) => void;
    onResetCriteriaByFilterKey: (criterionEnum: CriterionEnum, criterionParamKey: CriterionParamFilter) => void;
}> = ({
    data,
    onDelete,
    onResetCriteriaByFilterKey
}) => {
    const { t } = useLocale();

    const renderCriterionParams = useCallback(
        (criterion: Criterion, criterionEnumKey: CriterionEnum) => {
            const groupedCriterionParams = groupBy(criterion.CriterionParams, (c) => c.filterKey);

            return Object.entries(groupedCriterionParams).flatMap(([filterKey, criterionParams]) => {
                const shouldRenderInList = LIST_ONLY_FILTERS.includes(filterKey) || criterionParams.length === 1;

                if (shouldRenderInList) {
                    return (
                        <SearchCriterionChipList
                            key={filterKey}
                            criterionParams={criterionParams}
                            criterionEnum={criterionEnumKey}
                            criterionColor={criterion.baseColor}
                            onDelete={onDelete}
                        />
                    );
                }

                return (
                    <SearchCriterionDropdown
                        key={filterKey}
                        criterionParams={criterionParams}
                        criterionEnum={criterionEnumKey}
                        criterionParamKey={filterKey as CriterionParamFilter}
                        criterionColor={criterion.baseColor}
                        onDeleteCriteria={onDelete}
                        onResetCriteriaByFilterKey={onResetCriteriaByFilterKey}
                    />
                );
            });
        },
        [onDelete, onResetCriteriaByFilterKey],
    );

    const renderAndIndicator = useMemo(() => {
        const IsThereMoreThanOneCriterion = Object.keys(data).length > 1;

        if (IsThereMoreThanOneCriterion) {
            return <div className="Criteria__and-indicator">{t(CommonPhrasesEnum.And)}</div>;
        }

        return null;
    }, [data, t]);

    return (
        <div className="Criteria">
            {renderAndIndicator}
            <div>
                <HierarchyIndicator>
                    {Object.entries(data)?.map(([criterionKey, criterion], i, { length }) => {
                        const isLastCriterion = i + 1 === length;

                        return (
                            <div className="flex" key={criterion.id}>
                                <div data-testid={`badgeIconCriterion${criterion.name}`}>
                                    <Tooltip content={t(criterion.name)} placement="bottom">
                                        <div>
                                            <BadgeIcon
                                                key={criterion.id}
                                                icon={getCriterionEnumIcon(criterionKey as CriterionEnum)}
                                                baseColor={criterion.baseColor}
                                            />
                                        </div>
                                    </Tooltip>
                                </div>
                                <div className={cn('Criteria__chips', { 'Criteria__chips--last': isLastCriterion })}>
                                    {renderCriterionParams(criterion, criterionKey as CriterionEnum)}
                                </div>
                            </div>
                        );
                    })}
                </HierarchyIndicator>
            </div>
        </div>
    );
};

export default Criteria;
