import { useEffect, useState } from 'react';

import { useLocale } from '@core/utils/locale';
import { PhrasesEnum } from '@core/enums/localeEnum';
import { CountryCodesEnum } from '@core/enums/flagsEnum';
import { useAdvancedSearch } from '@core/contexts/AdvancedSearch.context';
import useRanges, { RangeDTO, RangesTypesEnum } from '@core/api/useRanges';

import {
    CONFIDENCE_LEVEL_FILTER_RANGE,
    HOTEL_CLASS_FILTER_RANGE,
} from '../components/SegmentsCriteriaContent/GeneralCriteria/General.constants';
import { RangesData, parseRanges } from '../components/SegmentsCriteriaContent/GeneralCriteria/General.helpers';

function useGeneralCriteria() {
    const [ranges, setRanges] = useState<RangesData | null>(null);

    const {
        t,
        locale
    } = useLocale();

    const { doFetch: fetchRange } = useRanges();
    const { activeCountry } = useAdvancedSearch();

    useEffect(() => {
        if (activeCountry) {
            fetchRange({
                params: {
                    country: activeCountry.value,
                    locale,
                },
                cancelRunningRequest: false,
            }).then((res) => {
                if (res?.data?.ranges) {
                    const newRangesData = Object.values(res.data.ranges).reduce((acc, curr) => {
                        const type = curr?.type as RangesTypesEnum;
                        acc[type] = {
                            ...curr,
                            ranges: parseRanges(
                                curr?.elements as RangeDTO[],
                                type,
                                locale,
                                activeCountry.value as CountryCodesEnum,
                            ),
                        };
                        return acc;
                    }, {} as RangesData);

                    const staticRangeData = {
                        [RangesTypesEnum.ConfidenceLevelRangeType]: {
                            ranges: CONFIDENCE_LEVEL_FILTER_RANGE.ranges.map((range) => ({
                                id: range.id,
                                title: t(range.title as PhrasesEnum),
                            })),
                        },
                        [RangesTypesEnum.HotelClassRangeType]: {
                            ranges: HOTEL_CLASS_FILTER_RANGE.ranges,
                        },
                    };

                    setRanges({ ...newRangesData, ...staticRangeData });
                }
            });
        }
    }, [fetchRange, locale, activeCountry, t]);

    return { ranges };
}

export default useGeneralCriteria;
