import { FC, useCallback } from 'react';
import cn from 'classnames';
import { useNavigate } from 'react-router-dom';

import { IconArrowDown } from '@assets/icons';
import { useLocale } from '@core/utils/locale';
import { RoutesEnum } from '@core/enums/RoutesEnum';
import { CriteriaData } from '@core/models/Criterion';
import { useSelectInput } from '@core/hooks/useInput';
import { formatDateISOtoDMY } from '@core/utils/string';
import { SavedSearchEnum } from '@core/enums/localeEnum';
import { useTableSortReturn } from '@core/hooks/useTableSort';
import { SaveSearchResponse } from '@core/api/SaveSearch/useSaveSearch';
import { useAdvancedSearch } from '@core/contexts/AdvancedSearch.context';

import Select from '@components/utility/Inputs/Select/Select';
import Text, { TextVariant } from '@components/ui/Typography/Text';
import Widget, { WidgetType } from '@components/utility/Widget/Widget';
import { useTableContext } from '@components/utility/Table/TableContext';
import { SelectOption } from '@components/utility/Inputs/Inputs.helpers';
import TableFeedback from '@components/utility/Table/TableFeedback/TableFeedback';

import '../../OperatorTargetingResults/OperatorTargetingResults.styles.scss';

const SavedSearchResultsTableMobile: FC<{
    data: SaveSearchResponse[];
    testId: string;
    loading: boolean;
    sortConfig: useTableSortReturn;
}> = ({
    data,
    testId,
    loading,
    sortConfig
}) => {
    const {
        t,
        locale
    } = useLocale();
    const { config } = useTableContext();
    const {
        savedSearch: { updateActiveSavedSearch },
        onMergeCriteria,
    } = useAdvancedSearch();
    const {
        value,
        onChange
    } = useSelectInput();
    const navigate = useNavigate();

    const selectOptions: SelectOption[] = config
        .filter((column) => column.isSortable)
        .map((column) => ({
            label: column?.title ? t(column.title) : '',
            id: column.field,
        }));

    const sortedColumn = sortConfig.sortedColumns[0];

    const onNavigateToSearchResults = useCallback(
        async (data: SaveSearchResponse) => {
            let parsedQuery: CriteriaData;
            try {
                parsedQuery = JSON.parse(data.query);
            } catch (error) {
                throw new Error('Invalid JSON format');
            }

            onMergeCriteria(parsedQuery);
            updateActiveSavedSearch(data);
            navigate(RoutesEnum.OperatorTargetingResults);
        },
        [navigate, onMergeCriteria, updateActiveSavedSearch],
    );

    return (
        <Widget type={WidgetType.Table} testId={testId}>
            <TableFeedback isLoading={loading} isEmpty={(data?.length ?? 0) === 0} />
            <div className="TableMobile__header">
                <Text variant={TextVariant.B2} testId="sortLabel">
                    SORT:
                </Text>
                <div style={{ minWidth: '120px' }}>
                    <Select
                        value={value ?? sortedColumn.field}
                        height="1px"
                        onChange={(value) => {
                            if (value) {
                                sortConfig.onSort(value);
                            }
                            onChange(value);
                        }}
                        options={selectOptions}
                        testId="selectSortedColumn"
                    />
                </div>
                <div
                    onClick={() => {
                        sortConfig.onSort(sortedColumn.field);
                    }}
                    className={cn('TableMobile__sort', {
                        'TableMobile__sort--desc': sortedColumn.order === 'DESC',
                    })}
                    data-testid="tableMobileSortIcon"
                >
                    <IconArrowDown />
                </div>
            </div>
            {data?.map((row, index) => (
                <a
                    key={index}
                    className="TableMobile"
                    data-testid={`tableRow${index}`}
                    onClick={() => onNavigateToSearchResults(data[index])}
                >
                    <div className="TableMobile__name" data-testid="tableCellName">
                        <strong>{row?.name}</strong>
                    </div>

                    {
                        <div className="TableMobile__row">
                            {row?.created_by_name && <div data-testid="tableCellName">{row?.created_by_name}</div>}
                            {
                                <div data-testid="tableCellType">
                                    {row?.publicly_available ? t(SavedSearchEnum.Public) : t(SavedSearchEnum.Private)}
                                </div>
                            }
                            {row?.last_updated && (
                                <div data-testid="tableCellType">
                                    {formatDateISOtoDMY(data?.[index]?.last_updated || '', locale)}
                                </div>
                            )}
                        </div>
                    }
                </a>
            ))}
        </Widget>
    );
};

export default SavedSearchResultsTableMobile;
