import { useCallback, useEffect, useMemo, useState } from 'react';
import { useMediaQuery } from '@mui/material';

import { FCX, ID } from '@core/models';
import { formatTestIds } from '@core/utils/string';
import { GradientColorsEnum } from '@core/enums/gradientEnum';
import { CustomerMetadataResponse } from '@core/api/useCustomerMetadata';
import { useAdvancedSearch } from '@core/contexts/AdvancedSearch.context';
import Heading, { HeadingVariant } from '@components/ui/Typography/Heading';
import { CriterionEnum, CriterionParamFilter } from '@core/models/Criterion';
import { DropdownOption } from '@components/utility/Inputs/Dropdowns/Dropdowns.helpers';
import SwitchList, { SwitchListOption } from '@components/utility/SwitchList/SwitchList';

const ClientSwitchList: FCX<{
    clientFilter: CustomerMetadataResponse;
    title: string;
    criterionFilterKey: CriterionParamFilter;
}> = ({
    clientFilter,
    title,
    criterionFilterKey,
    testId
}) => {
    const [selectedFilters, setSelectedFilters] = useState<ID[]>([]);

    const parsedClientFilterOptions: SwitchListOption[] = useMemo(
        () =>
            clientFilter?.values?.map((filter) => ({
                id: filter,
                title: filter,
            })) ?? [],
        [clientFilter],
    );

    const isSmallScreen = useMediaQuery('(max-width:1200px)');

    const {
        addCriterionOnOptionSelected,
        getCriterionByFilterKey
    } = useAdvancedSearch();

    useEffect(() => {
        const clientFilterParams = getCriterionByFilterKey(CriterionEnum.ClientFilters, criterionFilterKey);

        const selectedIds =
            clientFilterParams
                ?.map((clientFilter) => {
                    return parsedClientFilterOptions?.filter((opt) => clientFilter?.value === opt?.id);
                })
                .flat()
                .map((opt) => opt?.id) ?? [];

        setSelectedFilters(selectedIds);
    }, [criterionFilterKey, getCriterionByFilterKey, parsedClientFilterOptions]);

    const onAddListItemsCriteria = useCallback(
        (rangesList: SwitchListOption[]) => {
            rangesList?.forEach((clientFilter) => {
                const rangePill: DropdownOption = mapClientFilterToDropdownOption(clientFilter);
                addCriterionOnOptionSelected(CriterionEnum.ClientFilters, criterionFilterKey)(rangePill);
            });
        },
        [addCriterionOnOptionSelected, criterionFilterKey],
    );

    const onUpdateSelectedItems = useCallback(
        (values: ID[]) => {
            const symmetricDiff = selectedFilters
                .filter((x) => !values.includes(x))
                .concat(values.filter((x) => !selectedFilters.includes(x)))[0];

            const optionToToggle = parsedClientFilterOptions.find((clientFilter) => clientFilter?.id === symmetricDiff);

            if (!optionToToggle) {
                return;
            }

            onAddListItemsCriteria([optionToToggle]);
        },
        [onAddListItemsCriteria, parsedClientFilterOptions, selectedFilters],
    );

    return (
        <div className="CriteriaContent__columns no-mt col-1">
            <div className="CriteriaContent__column col-1">
                <Heading
                    className="GeneralCriteria__range-title"
                    variant={HeadingVariant.H5}
                    testId={formatTestIds(testId)}
                >
                    {title}
                </Heading>
                <SwitchList
                    options={parsedClientFilterOptions}
                    value={selectedFilters}
                    onChange={onUpdateSelectedItems}
                    color={GradientColorsEnum.PURPLE}
                    isSmall={isSmallScreen}
                    columns={5}
                />
            </div>
        </div>
    );
};

export default ClientSwitchList;

function mapClientFilterToDropdownOption(clientFilter: SwitchListOption): DropdownOption {
    return {
        id: clientFilter.id,
        value: clientFilter.id as string,
        label: clientFilter.title,
    };
}
