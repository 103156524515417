import { FC } from 'react';

import Table from '@components/utility/Table/Table';
import { TerritoryDTO } from '@core/api/useTerritories';
import { TableProvider } from '@components/utility/Table/TableContext';

import { TerritorySelection } from '../../hooks/useTerritoriesPage';
import { useTerritoriesTableConfig } from './useTerritoriesTableConfig';

const TerritoriesTable: FC<{
    territories: TerritoryDTO[];
    isTerritoriesLoading: boolean;
    territorySelection: TerritorySelection;
}> = ({
    territories,
    isTerritoriesLoading,
    territorySelection
}) => {
    const {
        config,
        sortConfig,
        sortedData
    } = useTerritoriesTableConfig(territories, territorySelection);

    return (
        <TableProvider sortConfig={sortConfig} config={config}>
            <Table rowsCount={sortedData?.length ?? 0} testId={'TerritoriesTable'} loading={isTerritoriesLoading} />
        </TableProvider>
    );
};

export default TerritoriesTable;
